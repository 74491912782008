<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="displist"
      :items-per-page="itemsPerPage"
      :page="page"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100],
      }"
      class="elevation-1 outlined account-list mt-4"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.address">
            <!-- <td>{{ item.address }} - {{ item.description }}</td> -->
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" dark v-bind="attrs" v-on="on">
                    <!-- {{ item.description }} -->

                    <div class="hover_area" v-if="item.address != undefined">
                      {{ item.description }}
                      <div class="hover_inner">
                        <v-btn
                          @click="addrCopy(item.address)"
                          class="copy"
                          ref="${item.address}"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                          @click="
                            networkExplorer(`address/${item.address}`, 'erc20')
                          "
                          class="link"
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </span>
                </template>
                <span>{{ item.address }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" dark v-bind="attrs" v-on="on">
                    <div class="hover_area" v-if="item.address != undefined">
                      {{ item.threshold }} / {{ item.partySize }}
                      <v-icon>mdi-account-multiple</v-icon>
                      <div class="hover_inner">
                        <v-btn
                          @click="addrCopy(item.addressList)"
                          class="copy"
                          ref="${item.addressList}"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </span>
                </template>
                <span>{{ item.addressListDescription }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.skp }} ({{ item.deposit }})</td>
            <td>{{ item.matic }}</td>
            <td>
              <v-btn
                class="mr-4"
                color="primary"
                @click="doClick(item.description, item.address)"
              >
                전송
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <confirm-dlg ref="confirm"></confirm-dlg>
    <v-dialog v-model="transDialog" overlay :max-width="dialogOptions.width">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5">전송 요청</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-row cols="12">
              <v-col cols="12">
                <v-text-field
                  label="지갑주소(MPC)*"
                  v-model="transInfo.from"
                  required
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <!-- <v-text-field
                  label="받는 주소*"
                  v-model="transInfo.to"
                  required
                ></v-text-field> -->

                <v-select
                  :items="userlist"
                  :item-value="'address'"
                  v-model="transInfo.to"
                  label="받는 주소"
                  class="confirm-user-select"
                >
                  <template slot="item" slot-scope="data">
                    <span>
                      {{ data.item.description }} - {{ data.item.address }}
                    </span>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <span>
                      {{ data.item.description }} - {{ data.item.address }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="수량*"
                  v-model="transInfo.amount"
                  type="number"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="closeTransDialog"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="
              request(
                transInfo.desc,
                transInfo.from,
                transInfo.to,
                transInfo.amount
              )
            "
          >
            요청
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'IssueTransactionView',
  components: {
    ConfirmDlg,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: '지갑주소(MPC)', value: 'address', width: '17%' },
        { text: '서명인/소유자', value: 'threshold' },
        { text: 'SKP (전송대기)', value: 'skp' },
        { text: 'matic', value: 'matic' },
        { text: '', value: '' },
      ],
      displist: [],
      address: null,
      amount: '',
      walletAddress: '',
      dialog: false,
      transDialog: false,
      dialogOptions: {
        width: '500px',
      },
      transInfo: {
        desc: '',
        from: '',
        to: '',
        amount: '',
      },
      totalCount: 0,
      list: [],
      ulist: [],
    }
  },
  computed: {
    userlist() {
      return this.list.concat(this.ulist)
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/mpcwallet/list', {
          offset,
          limit,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.count
            this.list = r.data.list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async request(desc, fromAddress, toAddress, amount) {
      if (toAddress == '') {
        alert('보낼 주소를 입력해주세요.')
        return false
      } else if (amount == '') {
        alert('전송갯수를 입력해주세요')
        return false
      }

      const _amountF = parseFloat(amount) * 10000
      const _amount =
        BigInt(String(_amountF.toFixed(0))) * BigInt('100000000000000')

      const result = await this.$refs.confirm.open(
        'Confirm',
        '전송하시겠습니까?'
      )
      if (result) {
        axiosInstance
          .post('/mpcwallet/request/transfer', {
            fromAddress,
            toAddress,
            amount: _amount.toString(),
          })
          .then((r) => {
            if (r.status == 200) {
              this.transDialog = false
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
      this.transInfo.desc = ''
      this.transInfo.from = ''
      this.transInfo.to = ''
      this.transInfo.amount = ''
    },
    doClick(desc, address) {
      if (address === '') {
        alert('지갑주소가 생성되기 전입니다.')
        return false
      }
      this.transDialog = true
      this.transInfo.from = address
      this.transInfo.desc = desc
      this.address = address
    },
    closeDialog() {
      this.dialog = false
    },
    closeTransDialog() {
      this.transInfo.desc = ''
      this.transInfo.from = ''
      this.transInfo.to = ''
      this.transInfo.amount = ''
      this.transDialog = false
    },
    convertValue(value) {
      if (value) {
        const result = BigInt(value) / BigInt('100000000000000')
        const val = Number(result) / 10000
        return val.toFixed(4)
      }
    },
    converList(list) {
      return list.replaceAll(',', ', ')
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    getBalance(address) {
      axiosInstance
        .get(`/wallet/balanceOf/${address}`)
        .then(({ data, status }) => {
          if (status == 200) {
            this.displist = this.displist.map((item) => {
              if (item.address == address) {
                item.skp = this.convertValue(data.skp)
                item.matic = this.convertValue(data.matic)
              }
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  mounted() {
    axiosInstance
      .post('/mpcuser/list', {})
      .then((r) => {
        if (r.status == 200) {
          this.ulist = r.data.list
        }
      })
      .catch((err) => {
        console.error(err)
      })

    this.displist = this.list.map((item) => {
      this.getBalance(item.address)
      item.deposit = this.convertValue(item.deposit)
      return item
    })
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
