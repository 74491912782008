<template>
  <div>
    <v-btn text color="green" @click="showDialog(true)">
      ERC1155 - {{ tokenSymbol }}
    </v-btn>
    <v-dialog
      v-model="dialog"
      @keydown.esc="showDialog(false)"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5">ERC1155 Address</span>
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="address">
            <v-radio
              v-for="(item, index) in list"
              :key="index"
              :label="`${item.tokenSymbol} - ${item.address}`"
              :value="item.address"
              v-on:click="showDialog(false)"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ButtonERC1155',
  data() {
    return {
      dialog: false,
      list: [],
      tokenSymbol: '',
      address: '',
    }
  },
  computed: {
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
    contractList() {
      return this.$store.getters['auth/contractList']
    },
  },
  watch: {
    erc1155Address() {
      const found = this.contractList.find(
        (el) => el.address == this.erc1155Address
      )
      if (found) this.tokenSymbol = found.tokenSymbol
    },
  },
  methods: {
    showDialog(show) {
      if (this.tokenSymbol.length == 0) return

      if (show) {
        this.list = this.contractList.filter(
          (item) => item.tokenType == 'erc1155'
        )
        this.address = this.erc721Address
      } else {
        if (this.list.length > 0) {
          const foundItem = this.list.filter(
            (item) => item.address == this.address
          )
          this.$store.dispatch('auth/setErc1155Address', this.address)
          this.$store.dispatch('auth/setChainID', foundItem[0].chainID)
          this.$store.dispatch('auth/setContractAddress', this.address)
        }
      }
      this.dialog = show
    },
  },
}
</script>
