<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <div class="btn-row">
          <v-select :items="searchOptions" v-model="type"></v-select>
          <v-text-field clearable v-model="search" label="Search" />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <div class="btn-row">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="fromDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" no-title scrollable :max="toDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
              <v-btn text color="primary" @click="fromDate_search(fromDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="toDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              no-title
              scrollable
              :min="fromDate"
              :max="toDay"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
              <v-btn text color="primary" @click="toDate_search(toDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.hash`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.hash }}</div>
            <div class="hover_inner">
              <v-btn class="copy" @click="copy(item.hash)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn class="link" @click="move(item.hash)">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          {{
            new Date(Number(item.time))
              .toISOString()
              .replace('T', ' ')
              .slice(0, -5)
          }}
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>
<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanURL } from '@/utils/network'

export default {
  data() {
    return {
      options: {},
      headers: [
        {
          text: 'Block Number',
          value: 'number',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Transaction Hash',
          value: 'hash',
          sortable: false,
          width: '40%',
        },
        { text: 'Time', value: 'time', sortable: false, width: '15%' },
        { text: 'Type', value: 'type', sortable: false, width: '10%' },
      ],
      searchOptions: [
        { text: 'All', value: 'all' },
        { text: 'Hash', value: 'hash' },
        { text: 'Type', value: 'type' },
      ],
      list: [],
      type: 'all',
      search: '',
      totalCount: 0,
      menu1: null,
      menu2: null,
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    type() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    search() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    fromDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    toDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { page, itemsPerPage } = this.options

        this.$store.dispatch('pagination/setPagination', {
          page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc1155Address() {
      this.getList()
    },
  },
  methods: {
    fromDate_search(v) {
      this.fromDate = v
      this.menu1 = false
      this.$refs.menu1.save(v)
    },
    toDate_search(v) {
      this.toDate = v
      this.menu2 = false
      this.$refs.menu2.save(v)
    },
    getList(offset, limit) {
      const { page, itemsPerPage } = this.options

      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }

      axiosInstance
        .post('/tokentracker/getTx', {
          contractAddress: this.erc1155Address,
          tokenType: 'erc1155',
          offset,
          limit,
          type: this.type,
          search: this.search,
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        .then((res) => {
          this.list = res.data.txlist
          this.totalCount = res.data.count
        })
        .catch(console.error)
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData = '\ufeff' + 'hash,time,type\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/tokentracker/getTx',
            {
              contractAddress: this.erc1155Address,
              tokenType: 'erc1155',
              offset: skip,
              limit: 1000,
              type: this.type,
              search: this.search,
              fromDate: this.fromDate,
              toDate: this.toDate,
            }
          )

          if (status == 200) {
            totalCount = data.count

            for (let item of data.txlist) {
              processData += `${item.hash},${item.time},${item.type}\n`
            }
          } else {
            return
          }
          skip += data.txlist.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'deposit_history.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    copy(v) {
      let textArea = document.createElement('textarea')
      textArea.value = v
      textArea.style.height = '1px'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)

      textArea.select()

      try {
        document.execCommand('copy')
        // this.$toast.success('copy!')
      } catch (err) {
        // this.$toast.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    move(v) {
      let url = `${scanURL(this.chainID)}/tx/${v}`
      window.open(url, '_blank')
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
<style scoped></style>
