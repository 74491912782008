<template>
  <div>
    <v-row class="price justify-space-around mt-2">
      <div>
        <a
          href="https://www.probit.com/app/exchange/METAQ-USDT"
          target="_blank"
        >
          Probit
        </a>
        :
        {{ probit_metaq_price.toFixed(4).toLocaleString() }}
        ({{ probit_24h_change.toFixed(2).toLocaleString() }}%)
      </div>
      <div>METAQ {{ remainMetaq.toLocaleString() }}</div>
      <div>$ {{ (probit_metaq_price * remainMetaq).toLocaleString() }}</div>
      <div>available $ {{ availableUSDT }}</div>
      <div class="btn-row">
        <v-btn :disabled="progressValue != 100" @click="download">
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <progress-bar
          v-show="progressValue != 100"
          :options="progressOptions"
          :value="progressValue"
        />
      </div>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="current"
        :footer-props="{
          'items-per-page-options': [-1],
        }"
        hide-header
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.metaq`]="{ item }">
          <div class="fontColor">{{ item.metaq }}</div>
        </template>
        <template v-slot:[`item.usdt`]="{ item }">
          <div class="fontColor">{{ item.usdt }}</div>
        </template>
        <template v-slot:[`item.sum`]="{ item }">
          <div class="fontColor">{{ item.sum }}</div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [12, 24, 48, 72, 96, 120, 144, 168],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.metaq`]="{ item }">
          <div class="fontColor">{{ item.metaq }}</div>
        </template>
        <template v-slot:[`item.usdt`]="{ item }">
          <div class="fontColor">{{ item.usdt }}</div>
        </template>
        <template v-slot:[`item.sum`]="{ item }">
          <div class="fontColor">{{ item.sum }}</div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'BalanceHistoryProbitView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'time', value: 'time', width: 200 },
        { text: 'lp1 usdt', value: 'probit_lp1_usdt' },
        { text: 'lp2 usdt', value: 'probit_lp2_usdt' },
        { text: 'lp3 usdt', value: 'probit_lp3_usdt' },
        { text: 'usdt', value: 'usdt' },
        { text: 'lp1 metaq', value: 'probit_lp1_metaq' },
        { text: 'lp2 metaq', value: 'probit_lp2_metaq' },
        { text: 'lp3 metaq', value: 'probit_lp3_metaq' },
        { text: 'metaq', value: 'metaq' },
        { text: 'sum(usdt)', value: 'sum' },
      ],
      totalCount: 0,
      list: [],
      current: [],
      probit_metaq_price: 0,
      probit_24h_change: 0,
      remainMetaq: 0,
      availableUSDT: 0,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/metaplanet_token/get_balance_history', {
          exchange: 'probit',
          offset,
          limit,
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            if (
              this.probit_metaq_price == 0 &&
              result.data.probit_metaq_price.length > 0
            ) {
              this.probit_metaq_price = Number(result.data.probit_metaq_price)
              this.probit_24h_change = Number(result.data.probit_24h_change)
            }

            this.list = result.data.balance_list
            this.list = this.list.map((el) => {
              const epochTime = Number(el.time) // in seconds
              const milliseconds = epochTime * 1000 // convert to milliseconds
              const dateObject = new Date(milliseconds)
              el.time = dateObject.toISOString()

              el.probit_lp1_metaq = Number(el.probit_lp1_metaq).toFixed(2)
              el.probit_lp2_metaq = Number(el.probit_lp2_metaq).toFixed(2)
              el.probit_lp3_metaq = Number(el.probit_lp3_metaq).toFixed(2)
              el.probit_lp1_usdt = Number(el.probit_lp1_usdt).toFixed(2)
              el.probit_lp2_usdt = Number(el.probit_lp2_usdt).toFixed(2)
              el.probit_lp3_usdt = Number(el.probit_lp3_usdt).toFixed(2)
              el.metaq = (
                Number(el.probit_lp1_metaq) +
                Number(el.probit_lp2_metaq) +
                Number(el.probit_lp3_metaq)
              ).toFixed(2)
              el.usdt = (
                Number(el.probit_lp1_usdt) +
                Number(el.probit_lp2_usdt) +
                Number(el.probit_lp3_usdt)
              ).toFixed(2)
              el.sum = (
                Number(el.metaq) * this.probit_metaq_price +
                Number(el.usdt)
              ).toFixed(2)
              return el
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getProbitBalance() {
      axiosInstance
        .post('/metaplanet_token/probit_get_balance', {})
        .then((res) => {
          const balance_list_lp1 = res.data.balance_list_lp1
          const balance_list_lp2 = res.data.balance_list_lp2
          const balance_list_lp3 = res.data.balance_list_lp3

          this.current = []

          let el = {}
          el.time = new Date().toISOString()
          el.probit_lp1_metaq = Number(
            balance_list_lp1.filter((el) => el.currency_id === 'METAQ')[0].total
          ).toFixed(2)
          el.probit_lp2_metaq = Number(
            balance_list_lp2.filter((el) => el.currency_id === 'METAQ')[0].total
          ).toFixed(2)
          el.probit_lp3_metaq = Number(
            balance_list_lp3.filter((el) => el.currency_id === 'METAQ')[0].total
          ).toFixed(2)
          el.probit_lp1_usdt = Number(
            balance_list_lp1.filter((el) => el.currency_id === 'USDT')[0].total
          ).toFixed(2)
          this.availableUSDT = Number(
            balance_list_lp1.filter((el) => el.currency_id === 'USDT')[0]
              .available
          ).toFixed(2)
          el.probit_lp2_usdt = Number(
            balance_list_lp2.filter((el) => el.currency_id === 'USDT')[0].total
          ).toFixed(2)
          el.probit_lp3_usdt = Number(
            balance_list_lp3.filter((el) => el.currency_id === 'USDT')[0].total
          ).toFixed(2)
          el.metaq = (
            Number(el.probit_lp1_metaq) +
            Number(el.probit_lp2_metaq) +
            Number(el.probit_lp3_metaq)
          ).toFixed(2)
          el.usdt = (
            Number(el.probit_lp1_usdt) +
            Number(el.probit_lp2_usdt) +
            Number(el.probit_lp3_usdt)
          ).toFixed(2)
          el.sum = (
            Number(el.metaq) * this.probit_metaq_price +
            Number(el.usdt)
          ).toFixed(2)

          this.current.push(el)

          const exchange_list = res.data.exchange_list.filter((el) =>
            el.name.startsWith('Probit')
          )
          this.remainMetaq =
            exchange_list.reduce((accumulator, el) => {
              return accumulator + Number(el.value)
            }, 0) - el.metaq

          for (let i = 0; i < this.list.length - 1; i++) {
            let el2 = {}
            el2.time = this.list[i].time

            if (i === 0) {
              el2.probit_lp1_metaq = (
                Number(el.probit_lp1_metaq) -
                Number(this.list[i].probit_lp1_metaq)
              ).toFixed(2)
              el2.probit_lp2_metaq = (
                Number(el.probit_lp2_metaq) -
                Number(this.list[i].probit_lp2_metaq)
              ).toFixed(2)
              el2.probit_lp3_metaq = (
                Number(el.probit_lp3_metaq) -
                Number(this.list[i].probit_lp3_metaq)
              ).toFixed(2)
              el2.probit_lp1_usdt = (
                Number(el.probit_lp1_usdt) -
                Number(this.list[i].probit_lp1_usdt)
              ).toFixed(2)
              el2.probit_lp2_usdt = (
                Number(el.probit_lp2_usdt) -
                Number(this.list[i].probit_lp2_usdt)
              ).toFixed(2)
              el2.probit_lp3_usdt = (
                Number(el.probit_lp3_usdt) -
                Number(this.list[i].probit_lp3_usdt)
              ).toFixed(2)
              el2.metaq = Number(el.metaq) - Number(this.list[i].metaq)
              el2.usdt = Number(el.usdt) - Number(this.list[i].usdt)
              el2.sum = Number(el.sum) - Number(this.list[i].sum)
            } else {
              el2.probit_lp1_metaq = (
                Number(this.list[i - 1].probit_lp1_metaq) -
                Number(this.list[i].probit_lp1_metaq)
              ).toFixed(2)
              el2.probit_lp2_metaq = (
                Number(this.list[i - 1].probit_lp2_metaq) -
                Number(this.list[i].probit_lp2_metaq)
              ).toFixed(2)
              el2.probit_lp3_metaq = (
                Number(this.list[i - 1].probit_lp3_metaq) -
                Number(this.list[i].probit_lp3_metaq)
              ).toFixed(2)
              el2.probit_lp1_usdt = (
                Number(this.list[i - 1].probit_lp1_usdt) -
                Number(this.list[i].probit_lp1_usdt)
              ).toFixed(2)
              el2.probit_lp2_usdt = (
                Number(this.list[i - 1].probit_lp2_usdt) -
                Number(this.list[i].probit_lp2_usdt)
              ).toFixed(2)
              el2.probit_lp3_usdt = (
                Number(this.list[i - 1].probit_lp3_usdt) -
                Number(this.list[i].probit_lp3_usdt)
              ).toFixed(2)
              el2.metaq =
                Number(this.list[i - 1].metaq) - Number(this.list[i].metaq)
              el2.usdt =
                Number(this.list[i - 1].usdt) - Number(this.list[i].usdt)
              el2.sum = Number(this.list[i - 1].sum) - Number(this.list[i].sum)
            }

            if (el2.metaq != 0 || el2.usdt != 0 || el2.sum != 0) {
              el2.metaq = el2.metaq.toFixed(2)
              el2.usdt = el2.usdt.toFixed(2)
              el2.sum = el2.sum.toFixed(2)
              this.current.push(el2)
            }
          }
        })
        .catch(console.error)
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' +
        'time,probit_lp1_usdt,probit_lp2_usdt,probit_lp3_usdt,usdt,probit_lp1_metaq,probit_lp2_metaq,probit_lp3_metaq,metaq,sum\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/metaplanet_token/get_balance_history',
            {
              exchange: 'probit',
              offset: skip,
              limit: 1000,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let el of data.balance_list) {
              const epochTime = Number(el.time) // in seconds
              const milliseconds = epochTime * 1000 // convert to milliseconds
              const dateObject = new Date(milliseconds)
              el.time = dateObject.toISOString()

              el.probit_lp1_metaq = Number(el.probit_lp1_metaq).toFixed(2)
              el.probit_lp2_metaq = Number(el.probit_lp2_metaq).toFixed(2)
              el.probit_lp3_metaq = Number(el.probit_lp3_metaq).toFixed(2)
              el.probit_lp1_usdt = Number(el.probit_lp1_usdt).toFixed(2)
              el.probit_lp2_usdt = Number(el.probit_lp2_usdt).toFixed(2)
              el.probit_lp3_usdt = Number(el.probit_lp3_usdt).toFixed(2)
              el.metaq = (
                Number(el.probit_lp1_metaq) +
                Number(el.probit_lp2_metaq) +
                Number(el.probit_lp3_metaq)
              ).toFixed(2)
              el.usdt = (
                Number(el.probit_lp1_usdt) +
                Number(el.probit_lp2_usdt) +
                Number(el.probit_lp3_usdt)
              ).toFixed(2)
              el.sum = (
                Number(el.metaq) * this.probit_metaq_price +
                Number(el.usdt)
              ).toFixed(2)

              processData += `${el.time},${el.probit_lp1_usdt},${el.probit_lp2_usdt},${el.probit_lp3_usdt},${el.usdt},${el.probit_lp1_metaq},${el.probit_lp2_metaq},${el.probit_lp3_metaq},${el.metaq},${el.sum}\n`
            }
          } else {
            return
          }
          skip += data.balance_list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'balance_history_probit.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
    this.interval = setInterval(() => {
      this.getProbitBalance()
    }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style scoped>
.fontColor {
  color: rgb(39, 211, 139);
}
.price {
  font-size: 24px;
  font-weight: bold;
}
.two-lines {
  /* font-size: medium; */
  color: rgb(39, 211, 139);
}
.delta-value {
  color: blueviolet;
}
.v-application a {
  color: rgb(39, 211, 139);
}
</style>
