<template>
  <div>
    <div v-if="stage == 1">
      <v-row class="search_row">
        <v-col cols="12" lg="6">
          <v-text-field
            clearable
            v-model="keyword"
            label="Search"
            class="mr-4"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <div class="btn-row">
            <v-btn @click="createKey">Create Key</v-btn>
            <v-btn :disabled="progressValue != 100" @click="download">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <progress-bar
              v-show="progressValue != 100"
              :options="progressOptions"
              :value="progressValue"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="displist"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
          item-key="address"
          class="elevation-1 mt-4 outlined"
        >
          <template v-slot:[`item.apikey`]="{ item }">
            <div class="hover_area">
              <div class="hover_address">
                {{ item.apikey }}
              </div>
              <div class="hover_inner">
                <v-btn
                  @click="addrCopy(item.apikey)"
                  class="copy"
                  ref="${item.address}"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.erc20_address`]="{ item }">
            <div class="hover_area">
              <div class="hover_address">
                {{ item.erc20_address }}
              </div>
              <div class="hover_inner" v-if="item.erc20_address.length > 0">
                <v-btn
                  @click="addrCopy(item.erc20_address)"
                  class="copy"
                  ref="${item.address}"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  @click="
                    networkExplorer(`address/${item.erc20_address}`, 'erc20')
                  "
                  class="link"
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.erc721_address`]="{ item }">
            <div class="hover_area">
              <div class="hover_address">
                {{ item.erc721_address }}
              </div>
              <div class="hover_inner" v-if="item.erc721_address.length > 0">
                <v-btn
                  @click="addrCopy(item.erc721_address)"
                  class="copy"
                  ref="${item.address}"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  @click="
                    networkExplorer(`address/${item.erc721_address}`, 'erc721')
                  "
                  class="link"
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.erc1155_address`]="{ item }">
            <div class="hover_area">
              <div class="hover_address">
                {{ item.erc1155_address }}
              </div>
              <div class="hover_inner" v-if="item.erc1155_address.length > 0">
                <v-btn
                  @click="addrCopy(item.erc1155_address)"
                  class="copy"
                  ref="${item.address}"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  @click="
                    networkExplorer(
                      `address/${item.erc1155_address}`,
                      'erc1155'
                    )
                  "
                  class="link"
                >
                  <v-icon>mdi-link</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.editItem`]="{ item }">
            <button v-on:click="editItem(item)">
              <v-icon>mdi-square-edit-outline</v-icon>
            </button>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <div v-if="stage == 2 || stage == 3">
      <v-row>
        <v-btn class="mr-2" color="secondary" @click="close">
          <v-icon>mdi-arrow-left-thin</v-icon>
        </v-btn>
        <v-btn
          v-if="stage == 3"
          class="mr-2"
          color="primary"
          @click="generateKey"
        >
          Save
        </v-btn>
        <v-btn
          v-if="stage == 2"
          class="mr-2"
          color="primary"
          @click="modifyKey"
        >
          Modify
        </v-btn>
        <v-btn
          v-if="stage == 2"
          class="mr-2"
          color="secondary"
          @click="deleteKey"
        >
          Delete
        </v-btn>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <v-text-field
            v-if="stage == 3"
            label="Issuer"
            v-model="issuer"
          ></v-text-field>
          <v-text-field
            v-else
            label="Issuer"
            v-model="issuer"
            disabled
          ></v-text-field>
          <v-text-field
            label="ERC20 Address"
            v-model="erc20_address"
          ></v-text-field>
          <v-text-field
            label="ERC721 Address"
            v-model="erc721_address"
          ></v-text-field>
          <v-text-field
            label="ERC1155 Address"
            v-model="erc1155_address"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'APIKeysView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'ISSUER', value: 'issuer', width: '12%' },
        { text: 'API Key', value: 'apikey' },
        { text: 'ERC20 Address', value: 'erc20_address', width: '17%' },
        { text: 'ERC721 Address', value: 'erc721_address', width: '17%' },
        { text: 'ERC1155 Address', value: 'erc1155_address', width: '17%' },
        { text: 'Modified Date', value: 'date_updated', width: '13%' },
        { text: 'Created Date', value: 'date_created', width: '13%' },
        { text: '', value: 'editItem', width: '34px' },
      ],
      totalCount: 0,
      displist: [],
      keyword: '',
      sort: '',
      issuer: '',
      erc20_address: '',
      erc721_address: '',
      erc1155_address: '',
      apikey: '',
      totalAmount: 0,
      stage: 1, // 1: list, 2: modify/delete, 3: new
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    keyword() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList(page, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(page, per_page) {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (page == undefined) {
        page = this.options.page
      }
      if (per_page == undefined) {
        per_page = itemsPerPage
      }
      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }

      axiosInstance
        .get(`/apikeys?${parameters}`)
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.meta.total
            this.displist = r.data.data.map((item) => ({
              ...item,
              erc20_address: item.erc20_address || '',
              erc721_address: item.erc721_address || '',
              erc1155_address: item.erc1155_address || '',
            }))
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    close() {
      this.stage = 1
    },
    createKey() {
      this.issuer = ''
      this.apikey = ''
      this.erc20_address = ''
      this.erc721_address = ''
      this.erc1155_address = ''

      this.stage = 3
    },
    editItem(item) {
      this.issuer = item.issuer
      this.apikey = item.apikey
      this.erc20_address = item.erc20_address
      this.erc721_address = item.erc721_address
      this.erc1155_address = item.erc1155_address

      this.stage = 2
    },
    generateKey() {
      axiosInstance
        .post('/apikeys', {
          issuer: this.issuer,
          erc20_address: this.erc20_address,
          erc721_address: this.erc721_address,
          erc1155_address: this.erc1155_address,
        })
        .then((result) => {
          if (result.status == 201) {
            this.stage = 1
            this.getList()
          }
        })
        .catch((err) => {
          console.error(err)
          alert(err.response?.data?.message)
        })
    },
    modifyKey() {
      axiosInstance
        .put(`/apikeys/${this.issuer}`, {
          issuer: this.issuer,
          erc20_address: this.erc20_address,
          erc721_address: this.erc721_address,
          erc1155_address: this.erc1155_address,
        })
        .then((result) => {
          if (result.status == 200) {
            this.stage = 1
            this.getList()
          }
        })
        .catch((err) => {
          console.error(err)
          alert(err.response?.data?.message)
        })
    },
    deleteKey() {
      axiosInstance
        .delete(`/apikeys/${this.issuer}`)
        .then((r) => {
          if (r.status == 200) {
            this.stage = 1
            this.getList()
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async download() {
      let processData =
        '\ufeff' +
        'issuer,apikey,erc20_address,erc721_address,erc1155_address,dateUpdated,dateCreated\n'

      try {
        let page = 1
        let loop_exit = false
        this.progressValue = 0

        do {
          let parameters = `page=${page}&per_page=10000`
          if (this?.keyword.length > 0) {
            parameters = parameters + `&keyword=${this.keyword}`
          }
          if (this?.sort.length > 0) {
            parameters = parameters + `&sort=${this.sort}`
          }

          const { data, status } = await axiosInstance.get(
            `/apikeys?${parameters}`
          )
          if (status != 200) {
            return
          }

          for (let item of data.data) {
            processData += `"${item.issuer}","${item.apikey}",${item.erc20_address},${item.erc721_address},${item.erc1155_address},${item.dateUpdated},${item.dateCreated}\n`
          }

          if (data.meta.current_page == data.meta.last_page) {
            loop_exit = true
          }

          page = page + 1
          this.progressValue = Math.round(
            data.meta.current_page / data.meta.last_page
          )
        } while (loop_exit == false)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'apikey_list.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
