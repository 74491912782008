const scanURL = (chainID) => {
  switch (chainID) {
    case 1:
      return 'https://etherscan.io'
    case 56:
      return 'https://bscscan.com'
    case 80001:
      return 'https://mumbai.polygonscan.com'
    case 137:
      return 'https://polygonscan.com'
  }
  return alert(`scanURL ${chainID} not found`)
}

const scanExplorer = (chainID, path, tokenType) => {
  let explorerUrl = `${scanURL(chainID)}/${path}`
  switch (tokenType) {
    case 'erc20':
      if (path.includes('polygon')) {
        explorerUrl = `${explorerUrl}/#tokentxns`
      } else if (path.includes('klaytn')) {
        explorerUrl = `${explorerUrl}/?tabId=tokenTransfer`
      }
      break
    case 'erc721':
      if (path.includes('polygon')) {
        explorerUrl = `${explorerUrl}/#tokentxnsErc721`
      } else if (path.includes('klaytn')) {
        explorerUrl = `${explorerUrl}/?tabId=tokenTransfer`
      }
      explorerUrl = `${explorerUrl}/#tokentxnsErc721`
      break
    case 'erc1155':
      if (path.includes('polygon')) {
        explorerUrl = `${explorerUrl}/#tokentxnsErc1155`
      } else if (path.includes('klaytn')) {
        explorerUrl = `${explorerUrl}/?tabId=tokenTransfer`
      }
      break
  }
  window.open(explorerUrl, '_blank')
}

const endpointURL = (chainID) => {
  switch (chainID) {
    case 1:
      return 'https://eth.llamarpc.com'
    case 56:
      return 'https://binance.llamarpc.com'
    case 80001:
      return 'https://binance.llamarpc.com'
    case 137:
      return 'https://polygon.llamarpc.com'
  }
  return alert(`endpointURL ${chainID} not found`)
}

module.exports = {
  endpointURL,
  scanExplorer,
  scanURL,
}
