<template>
  <div>
    <div class="btn-box">
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-4" color="primary" v-bind="attrs" v-on="on">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary">
            <span class="text-h5">사용자 주소 등록</span>
          </v-card-title>
          <v-card-text>
            <div>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="사용자명*"
                    v-model="addDescription"
                    required
                    hint="example of helper text only on focus"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="사용자 주소*"
                    v-model="addAddress"
                    required
                    hint="example of helper text only on focus"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="py-4 pt-0">
            <v-spacer></v-spacer>
            <v-btn
              text
              outlined
              w100
              class="font-weight-bold"
              @click="closeDialog"
            >
              Close
            </v-btn>
            <v-btn color="primary" @click="registerUser">Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-btn class="mr-4" color="secondary" @click="deleteUser">
        <v-icon dark>mdi-minus</v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="itemsPerPage"
      :page="page"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100],
      }"
      class="elevation-1 outlined account-list mt-4"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            v-for="item in items"
            :key="item.address"
            @click="doClick(item.address)"
            :class="item.address === address ? 'select-tr' : ''"
          >
            <td>{{ item.description }}</td>
            <td>
              <div class="hover_area" v-if="item.address != undefined">
                <div class="hover_address">{{ item.address }}</div>
                <div class="hover_inner">
                  <v-btn
                    @click="addrCopy(item.address)"
                    class="copy"
                    ref="${item.address}"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                  <v-btn
                    @click="networkExplorer(`address/${item.address}`, 'erc20')"
                    class="link"
                  >
                    <v-icon>mdi-link</v-icon>
                  </v-btn>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'RegisterAddressView',

  components: {
    ConfirmDlg,
  },

  data() {
    return {
      options: {},
      headers: [
        { text: '사용자명', value: 'name', sortable: false },
        { text: '지갑주소', value: 'address', sortable: false, width: '17%' },
      ],
      addAddress: '',
      addDescription: '',
      address: '',
      dialog: false,
      totalCount: 0,
      list: [],
    }
  },
  computed: {
    // totalCount() {
    //   return this.$store.getters['mpcuser/totalCount']
    // },
    // list() {
    //   return this.$store.getters['mpcuser/list']
    // },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/mpcuser/list', {
          offset,
          limit,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.totalCount
            this.list = r.data.list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async registerUser() {
      this.dialog = false
      const result = await this.$refs.confirm.open(
        'Confirm',
        '사용자 등록을 하겠습니까?'
      )
      if (result) {
        axiosInstance
          .post('/mpcuser/register', {
            address: this.addAddress,
            description: this.addDescription,
          })
          .then((r) => {
            if (r.status == 200) {
              this.addAddress = ''
              this.addDescription = ''
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async deleteUser() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '삭제하겠습니까?'
      )
      if (result) {
        axiosInstance
          .delete(`/mpcuser/${this.address}`)
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
        this.dialog = false
        this.address = ''
      }
    },

    closeDialog() {
      this.addAddress = ''
      this.addDescription = ''
      this.dialog = false
    },
    doClick(address) {
      this.address = address
      this.$emit('update-address', address)
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
