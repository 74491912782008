<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-file-input
            id="file-input"
            show-size
            outlined
            label="Choose File"
          ></v-file-input>
        </div>
      </v-col>
      <v-col cols="12" lg="3">
        <v-text-field clearable v-model="query" label="Search" class="mr-4" />
      </v-col>
      <v-col cols="12" lg="4">
        <div class="btn-row">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="fromDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  menu1 = false
                  fromDate_search('')
                "
              >
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="fromDate_search(fromDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="toDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="toDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  menu2 = false
                  toDate_search('')
                "
              >
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="toDate_search(toDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="1">
        <div class="btn-row">
          <v-btn @click="registerDialog = true">등록</v-btn>
          <v-btn @click="delAllPresale">전체 삭제</v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="2">
        <h3 style="text-align: right">
          Sum: {{ Number(totalAmount).toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="address"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.wallet`]="{ item }">
          <div class="hover_area">
            <p class="hover_address">
              {{ item.wallet }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.wallet)"
                class="copy"
                ref="${item.wallet}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.wallet}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="query = item.wallet" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.description }}
            </div>
            <div class="hover_inner">
              <v-btn @click="editDescription(item)" class="copy">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn @click="query = item.description" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <pre>
# address,description,type,metaq,unlockDate

0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877,홍길동,presale,10000,2024-12-25
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877,홍길동,advisor,10000,2024-12-25</pre
      >
    </v-row>
    <v-dialog v-model="registerDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Presale 등록</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Address"
                v-model="address"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Description"
                v-model="description"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                :items="presaleTypeList"
                v-model="selectedPresaleType"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="METAQ"
                v-model="amountMETAQ"
                single-line
                type="number"
                step="1000"
                :locale="'ko-KR'"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :return-value.sync="allowDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="allowDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="allowDate"
                  no-title
                  scrollable
                  :min="nowDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu3 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="allowDate_search(allowDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="registerDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="addPresale">
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Modify</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="description" required></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-select
            :items="presaleTypeList"
            v-model="selectedPresaleType"
          ></v-select>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="editDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="modify">
            Modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Web3 from 'web3'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'PresaleView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Seq', value: 'seq', width: '7%' },
        { text: 'Address', value: 'wallet', width: '25%' },
        { text: 'METAQ', value: 'metaq', width: '10%' },
        { text: 'Description', value: 'description' },
        { text: 'Type', value: 'type', width: '10%' },
        { text: 'Register Date', value: 'regDate', width: '11%' },
        { text: 'Allow Date', value: 'allowDate', width: '8%' },
        { text: 'Receipt Date', value: 'receiptDate', width: '11%' },
      ],
      totalCount: 0,
      displist: [],
      query: '',
      sort: '',
      address: '',
      amountMETAQ: 0,
      description: '',
      selectedPresaleType: 'presale',
      presaleTypeList: ['presale', 'advisor', 'private'],
      menu1: null,
      menu2: null,
      menu3: null,
      fromDate: '',
      toDate: '',
      allowDate: '',
      nowDate: new Date().toISOString().substr(0, 10),
      editDialog: false,
      registerDialog: false,
      totalAmount: 0,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    fromDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    toDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    registerDialog() {
      this.address = ''
      this.amountMETAQ = 0
      this.description = ''
      this.selectedPresaleType = 'presale'
    },
  },
  methods: {
    fromDate_search(v) {
      this.fromDate = v
      this.menu1 = false
      this.$refs.menu1.save(v)
    },
    toDate_search(v) {
      this.toDate = v
      this.menu2 = false
      this.$refs.menu2.save(v)
    },
    allowDate_search(v) {
      this.allowDate = v
      this.menu3 = false
      this.$refs.menu3.save(v)
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/metaplanet_presale/list', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.totalAmount = result.data.totalAmount
            this.displist = result.data.list.map((item) => {
              item.regDate = item.regDate.substring(0, 19)
              item.allowDate = item.allowDate.substring(0, 10)
              item.receiptDate = item.receiptDate.substring(0, 19)
              item.metaq = Number(item.metaq).toLocaleString()
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async addPresale() {
      if (Web3.utils.isAddress(this.address.trim()) == false) {
        alert(`invalid address : ${this.address}`)
        return
      }

      const date = new Date(this.allowDate)
      date.setFullYear(date.getFullYear())
      for (let idx = 0; idx < 10; idx++) {
        try {
          await axiosInstance.post('/metaplanet_presale/add', {
            wallet: this.address,
            metaq: (this.amountMETAQ / 10).toString(),
            description: this.description,
            type: this.selectedPresaleType,
            allowDate: date.toISOString().slice(0, 10),
          })
          date.setMonth(date.getMonth() + 1)
        } catch (error) {
          alert(error)
        }
      }
      this.getList()
      this.registerDialog = false
    },
    async delAllPresale() {
      if (confirm(`Do you want to delete all?`)) {
        let totalCount = 0
        let skip = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/metaplanet_presale/list',
            {
              offset: skip,
              limit: 1000,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              if (item.receiptDate.length == 0) {
                axiosInstance
                  .delete(`/metaplanet_presale/${item.seq}`)
                  .then((r) => {
                    if (r.status == 200) {
                      this.getList()
                    }
                  })
              }
            }
          }
          skip += data.list.length
        } while (skip < totalCount)
      }
    },
    readSingleFile(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      let _index = 1

      const reader = new FileReader()
      reader.onload = async (e) => {
        const contents = e.target.result
        const lineArr = contents.split('\n')
        for (let line of lineArr) {
          if (line.length == 0) {
            // last line
            continue
          }

          // 0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877,홍길동,presale,10000,2024-12-25
          const itemArr = line.split(',')
          if (itemArr.length < 5) {
            alert(`invalid line : ${_index} - ${line}`)
            _index++
            continue
          }

          if (Web3.utils.isAddress(itemArr[0].trim()) == false) {
            alert(`invalid address : line ${_index} - ${line}`)
            _index++
            continue
          }
          if (isNaN(itemArr[3].trim())) {
            alert(`invalid amount : line ${_index} - ${line}`)
            _index++
            continue
          }

          const date = new Date(itemArr[4].trim())
          date.setFullYear(date.getFullYear())
          for (let idx = 0; idx < 10; idx++) {
            try {
              await axiosInstance.post('/metaplanet_presale/add', {
                wallet: itemArr[0].trim(),
                description: itemArr[1].trim(),
                type: itemArr[2].trim(),
                metaq: (Number(itemArr[3].trim()) / 10).toString(),
                allowDate: date.toISOString().slice(0, 10),
              })
              date.setMonth(date.getMonth() + 1)
            } catch (error) {
              alert(error)
            }
          }
        }

        this.getList()
      }

      reader.readAsText(file)
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    editDescription(item) {
      this.address = item.wallet
      this.description = item.description
      this.selectedPresaleType = item.type
      this.editDialog = true
    },
    modify() {
      axiosInstance
        .post('/metaplanet_presale/modify', {
          wallet: this.address,
          description: this.description,
          type: this.selectedPresaleType,
        })
        .then((r) => {
          if (r.status == 200) {
            this.displist.forEach((item) => {
              if (item.wallet === this.address) {
                item.description = this.description
                item.type = this.selectedPresaleType
              }
            })
          } else {
            alert('Failed to modify.')
          }
        })
        .catch(() => {
          alert('Failed to modify.')
        })
      this.editDialog = false
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' +
        'address,metaq,description,type,allowdate,regdate,receiptdate\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/metaplanet_presale/list',
            {
              offset: skip,
              limit: 1000,
              query: this.query,
              sort: this.sort,
              fromDate: this.fromDate,
              toDate: this.toDate,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.wallet},${item.metaq},"${
                item.description
              }",${item.type},${item.allowDate.substring(
                0,
                19
              )},${item.regDate.substring(0, 19)},${item.receiptDate.substring(
                0,
                19
              )}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'presale.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  mounted() {
    document
      .getElementById('file-input')
      .addEventListener('change', this.readSingleFile, false)
  },
  async beforeMount() {
    const date = new Date()
    date.setDate(date.getDate() + 365)
    this.allowDate = date.toISOString().substr(0, 10)

    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}

.enabled-check div.v-messages.theme--light {
  display: none;
}

.enabled-check div.v-messages.theme--dark {
  display: none;
}

.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 12px;
}
</style>
