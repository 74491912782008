import { render, staticRenderFns } from "./BoardView.vue?vue&type=template&id=4b78465c&scoped=true"
import script from "./BoardView.vue?vue&type=script&lang=js"
export * from "./BoardView.vue?vue&type=script&lang=js"
import style0 from "@uppy/core/dist/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "@uppy/dashboard/dist/style.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "@uppy/drag-drop/dist/style.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "@uppy/progress-bar/dist/style.css?vue&type=style&index=3&prod&lang=css&external"
import style4 from "./BoardView.vue?vue&type=style&index=4&id=4b78465c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b78465c",
  null
  
)

export default component.exports