var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"search_row"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"btn-row"},[_c('v-btn',{attrs:{"disabled":_vm.progressValue != 100},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('progress-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressValue != 100),expression:"progressValue != 100"}],attrs:{"options":_vm.progressOptions,"value":_vm.progressValue}})],1)])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-4 outlined",attrs:{"headers":_vm.headers,"items":_vm.tusList,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.tusCount,"options":_vm.options,"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.image`,fn:function({ item }){return [_c('div',{staticClass:"image",on:{"click":function($event){return _vm.detailLink(`${_vm.fileServer}/${item.id}`, '_blank')}}},[_c('v-img',{staticClass:"ml-0",attrs:{"src":item.image,"alt":item.fileName,"width":"45px"}})],1)]}},{key:`item.id`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('div',{staticClass:"hover_address"},[_vm._v(_vm._s(item.id))]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.id}",staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(`${item.id}`)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)])]}},{key:`item.link`,fn:function({ item }){return [_c('button',{on:{"click":function($event){return _vm.detailLink(`${_vm.fileServer}/${item.id}`, '_blank')}}},[_c('v-icon',[_vm._v("mdi-link")])],1)]}}],null,true)})],1),_c('drag-drop',{staticClass:"drag-drop",attrs:{"uppy":_vm.uppy,"props":{
      locale: {
        strings: {
          chooseFile: 'Boop a file',
          orDragDrop: 'or yoink it here',
        },
      },
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }