import axiosInstance from '@/utils/axiosInstance'

export default {
  namespaced: true,
  state: {
    loggined: false,
    address: '',
    erc20Address: '',
    erc721Address: '',
    erc1155Address: '',
    chainID: 1,
    contractList: [],
    contractAddress: '',
  },
  getters: {
    isLoggined: (state) => {
      return state.loggined
    },
    address: () => {
      return localStorage.getItem('address')
    },
    erc20Address: (state) => {
      return state.erc20Address
    },
    erc721Address: (state) => {
      return state.erc721Address
    },
    erc1155Address: (state) => {
      return state.erc1155Address
    },
    chainID: (state) => {
      return state.chainID
    },
    contractList: (state) => {
      return state.contractList
    },
    contractAddress: (state) => {
      return state.contractAddress
    },
  },
  mutations: {
    setLoginStatus(state, loggined) {
      state.loggined = loggined
    },
    setContractList(state, contractList) {
      state.contractList = JSON.parse(JSON.stringify(contractList))
    },
    setErc20Address(state, erc20Address) {
      state.erc20Address = erc20Address
    },
    setErc721Address(state, erc721Address) {
      state.erc721Address = erc721Address
    },
    setErc1155Address(state, erc1155Address) {
      state.erc1155Address = erc1155Address
    },
    setChainID(state, chainID) {
      state.chainID = chainID
    },
    setContractAddress(state, contractAddress) {
      state.contractAddress = contractAddress
    },
  },
  actions: {
    login(context, { address, signature, callback }) {
      axiosInstance
        .post('/auth/login', {
          address: address,
          signature: signature,
        })
        .then(async (result) => {
          try {
            if (result.status === 200) {
              localStorage.setItem('authTokens', result.data.token)
              localStorage.setItem('address', address)
              context.commit('setLoginStatus', true)

              if (callback) callback(true)
            }
          } catch (error) {
            console.error(error)
          }
        })
        .catch(async () => {
          if (callback) callback(false)
          alert(
            'You are unable to log in. Please verify your account credentials.'
          )
        })
    },
    async logout(context) {
      localStorage.removeItem('authTokens')
      localStorage.removeItem('address')

      context.commit('setContractList', [])
      context.commit('setErc20Address', '')
      context.commit('setErc721Address', '')
      context.commit('setErc1155Address', '')
      context.commit('setChainID', 0)
      context.commit('setContractAddress', '')
    },
    async checkLoginStatus(context) {
      const authTokens = localStorage.getItem('authTokens')
      const loggined = authTokens == null ? false : true
      context.commit('setLoginStatus', loggined)
      if (loggined) {
        const r = await axiosInstance.get('/contract/list?page=1&per_page=1000')

        if (r.status == 200) {
          const r2 = await axiosInstance.get('/contract/mylist')
          const list = r2.data.mylist

          const contractList = r.data.data.filter((item) =>
            list.includes(item.address)
          )
          context.commit('setContractList', contractList)
          const erc20List = contractList.filter((item) =>
            item.tokenType == 'erc20' ? true : false
          )
          const erc721List = contractList.filter((item) =>
            item.tokenType == 'erc721' ? true : false
          )
          const erc1155List = contractList.filter((item) =>
            item.tokenType == 'erc1155' ? true : false
          )
          if (erc20List.length > 0) {
            context.commit('setErc20Address', erc20List[0].address)
            context.commit('setChainID', erc20List[0].chainID)
            context.commit('setContractAddress', erc20List[0].address)
          }
          if (erc721List.length > 0) {
            context.commit('setErc721Address', erc721List[0].address)
          }
          if (erc1155List.length > 0) {
            context.commit('setErc1155Address', erc1155List[0].address)
          }
        }
      } else {
        context.commit('setContractList', [])
        context.commit('setErc20Address', '')
        context.commit('setErc721Address', '')
        context.commit('setErc1155Address', '')
        context.commit('setChainID', 0)
        context.commit('setContractAddress', '')
      }
    },
    setContractList(context, contractList) {
      context.commit('setContractList', contractList)
    },
    setErc20Address(context, erc20address) {
      context.commit('setErc20Address', erc20address)
    },
    setErc721Address(context, erc721address) {
      context.commit('setErc721Address', erc721address)
    },
    setErc1155Address(context, erc1155address) {
      context.commit('setErc1155Address', erc1155address)
    },
    setChainID(context, chainID) {
      context.commit('setChainID', chainID)
    },
    setContractAddress(context, contractAddress) {
      context.commit('setContractAddress', contractAddress)
    },
  },
}
