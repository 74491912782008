<template>
  <div>
    <v-row>
      <v-col cols="12" lg="6">
        <v-file-input
          id="file-input"
          show-size
          outlined
          label="Choose File"
          @click:clear="clearList"
        ></v-file-input>
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn @click="postList">
            <v-icon>mdi-upload</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="totalList"
        item-key="idx"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div class="hover_area">
            <v-img
              style="vertical-align: middle"
              :src="item.image"
              :alt="item.image"
              width="45px"
              class="ml-0"
            ></v-img>
            <div class="hover_inner">
              <v-btn @click="openLink(item.image)" class="link">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <div style="overflow: auto">
        <pre>
# example
# owner | name | description | image | attributes

0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 1 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/e4606e4f42f73b832ba8b530f8a4e8d1 | {" English Name": "MARRON" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 2 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/a80bb676e130f46184d7b4554eeaf364 | {" English Name": "PENNY" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 3 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/5156f961dfde24a3ea790de037549cd5 | {" English Name": "ZIZI" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 4 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/31b800dd64c04e3c5b6a281790959f64 | {" English Name": "CHICO" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 5 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/6ca801756c8f1b67689b8bada2108ddd | {" English Name": "ZIZI" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 6 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/2981495a1828633776abb761bee98b0c | {" English Name": "PENNY" }
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877 | Collection 7 | METAPLANET NFT | https://appsdev.metaplanet.tech/files/26e4efba01207c0533899f9f10481fcb | {" English Name": "MARRON" } </pre
        >
      </div>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'NFT721AddView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Sequential', value: 'idx', width: '6%' },
        { text: 'Owner', value: 'owner', width: '25%' },
        { text: 'Name', value: 'name', width: '10%' },
        { text: 'Description', value: 'description', width: '15%' },
        { text: 'Image', value: 'image', width: '5%' },
        { text: 'Attributes', value: 'attributes' },
      ],
      totalList: [],
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })
      },
      deep: true,
    },
  },
  methods: {
    readSingleFile(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      let _list = []
      let _listCount = 0

      const reader = new FileReader()
      reader.onload = (e) => {
        const contents = e.target.result
        const lineArr = contents.split('\n')
        for (let line of lineArr) {
          if (line.length == 0) {
            // last line
            continue
          }
          _listCount++

          const itemArr = line.split('|')
          if (itemArr.length < 2) {
            alert(`invalid line : ${_listCount} - ${line}`)
            _listCount--
            continue
          }
          const attributes = itemArr[4].trim().replace('\r', '')
          try {
            JSON.parse(attributes)
          } catch (error) {
            alert(`invalid attributes : ${_listCount} - ${attributes}`)
            _listCount--
            continue
          }

          _list.push({
            idx: _listCount,
            owner: itemArr[0].trim().replace('\r', ''),
            name: itemArr[1].trim().replace('\r', ''),
            description: itemArr[2].trim().replace('\r', ''),
            image: itemArr[3].trim().replace('\r', ''),
            attributes: attributes,
          })
        }
        this.totalList = _list
        this.count = _listCount
      }

      reader.readAsText(file)
    },
    clearList() {
      this.totalList = []
    },
    postList() {
      if (confirm('Do you want to send?')) {
        axiosInstance
          .post('/nft721/postList', {
            erc721Address: this.erc721Address,
            list: this.totalList,
          })
          .then((result) => {
            if (result.status != 200) {
              return alert('업로드 실패했습니다.')
            }
            this.clearList()
          })
      }
    },
    openLink(imageurl) {
      window.open(imageurl, '_blank')
    },
  },
  mounted() {
    document
      .getElementById('file-input')
      .addEventListener('change', this.readSingleFile, false)
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
}
</style>
