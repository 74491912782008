<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="5">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="3">
        <div class="btn-row">
          <v-btn :disabled="disableMinting" @click="startMinting">Start</v-btn>
          <v-btn :disabled="!disableMinting" @click="stopMinting">Stop</v-btn>
          <v-btn @click="setEnabledAll">Select All</v-btn>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        item-key="fileHash"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-img
            :src="item.image"
            :alt="item.name"
            width="45px"
            class="ml-0"
          ></v-img>
        </template>
        <template v-slot:[`item.fileHash`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.fileHash }}</div>
            <div class="hover_inner">
              <v-btn
                @click="detailLink(`${fileServer}/${item.fileHash}`, '_blank')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.owner`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.owner }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.owner)"
                class="copy"
                ref="${item.owner}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.owner}`, 'erc721')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
          <v-checkbox
            class="enabled-check"
            :input-value="item.enabled"
            color="icon"
            @change="check(item, $event)"
            :disabled="item.transactionHash != ''"
          ></v-checkbox>
        </template>
        <template v-slot:[`item.deleteItem`]="{ item }">
          <button v-on:click="deleteItem(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </button>
        </template>
      </v-data-table>
    </v-row>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import config from '@/config'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'NFT721MintView',
  components: {
    ConfirmDlg,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Image', value: 'image', width: '5%', align: 'center' },
        { text: 'Name', value: 'name', width: '15%' },
        { text: 'Description', value: 'description', width: '15%' },
        { text: 'File Hash', value: 'fileHash', width: '10%' },
        { text: 'Owner', value: 'owner', width: '15%' },
        { text: 'Attributes', value: 'attributes', width: '25%' },
        { text: 'Transaction Hash', value: 'transactionHash' },
        { text: '', value: 'enabled', width: '34px' },
        { text: '', value: 'deleteItem', width: '34px' },
      ],
      totalCount: 0,
      list: [],
      query: '',
      sort: '',
      status: '0',
      disableMinting: false,
      interval: null,
      enabled: false,
      fileServer: config.fileServer,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc721Address() {
      this.getList()
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/nft721/nftlist', {
          erc721Address: this.erc721Address,
          offset,
          limit,
          query: this.query,
          sort: this.sort,
          status: '0',
        })
        .then((result) => {
          if (result.status == 200) {
            const list = result.data.list.map((item) => {
              const attributes = item.attributes.reduce((acc, cur) => {
                if (acc.length) return acc + `, ${cur.trait_type}:${cur.value}`
                else return `${cur.trait_type}:${cur.value}`
              }, '')
              return {
                ...item,
                attributes: attributes,
              }
            })
            this.totalCount = result.data.totalCount
            this.list = list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'image,name,description,fileHash,owner,attributes,enabled\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post('/nft721/nftlist', {
            erc721Address: this.erc721Address,
            offset: skip,
            limit: 1000,
            query: this.query,
            sort: this.sort,
            status: '0',
          })
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              const attributes = item.attributes.reduce((acc, cur) => {
                if (acc.length) return acc + `, ${cur.trait_type}:${cur.value}`
                else return `${cur.trait_type}:${cur.value}`
              }, '')
              processData += `"${item.image}","${item.name}","${item.description}",${item.fileHash},"${item.owner}","${attributes}",${item.enabled}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'erc721_ready.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    check(item, checked) {
      axiosInstance
        .post('/nft721/setEnabled', {
          erc721Address: this.erc721Address,
          id: item.id,
          enabled: checked,
        })
        .then(() => {
          this.getList()
        })
    },
    setEnabledAll() {
      this.enabled = !this.enabled
      axiosInstance
        .post('/nft721/setEnabledAll', {
          erc721Address: this.erc721Address,
          enabled: this.enabled,
        })
        .then(() => {
          this.getList()
        })
    },
    deleteItem(item) {
      axiosInstance
        .delete(`/nft721/${item.id}?erc721Address=${this.erc721Address}`)
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async startMinting() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to proceed with minting?'
      )
      if (result) {
        axiosInstance
          .post('/nft721/setProcessStatus', {
            erc721Address: this.erc721Address,
            minting: 'start',
          })
          .then(() => {})
      }
    },
    stopMinting() {
      axiosInstance
        .post('/nft721/setProcessStatus', {
          erc721Address: this.erc721Address,
          minting: 'stop',
        })
        .then(() => {})
    },
    detailLink(link) {
      window.open(link, '_blank')
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })

    this.interval = setInterval(() => {
      axiosInstance
        .get(`/nft721/getProcessStatus?erc721Address=${this.erc721Address}`)
        .then(({ data, status }) => {
          if (status == 200) {
            if (data.minting == 'start') {
              this.disableMinting = true
            } else if (data.minting == 'stop') {
              this.disableMinting = false
            }
          }
        })
      this.getList()
    }, 3000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
  mounted() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
.v-responsive {
  margin: 0 auto;
}
.enabled-check div.v-messages.theme--light {
  display: none;
}
.enabled-check div.v-messages.theme--dark {
  display: none;
}
.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 10px;
}
</style>
