<template>
  <div class="wrapper">
    <div class="content_body">
      <div class="dashboard">
        <div class="card">
          <div class="name">Last 24 hour withdraw</div>
          <div class="flex">
            <div class="value">
              {{ Number(withdrawAmount).toLocaleString() }}
            </div>
            <v-spacer></v-spacer>
          </div>
        </div>
        <div class="card">
          <div class="name">Last 1 hour transfers</div>
          <div class="flex">
            <div class="value">{{ Number(top.ohc).toLocaleString() }}</div>
            <v-spacer></v-spacer>
            <!-- <div
              class="per"
              :class="{
                'd-none': top.ohcp.dNone,
                plus: top.ohcp.type === 'p',
                minus: top.ohcp.type === 'm'
              }"
            >{{ top.ohcp.type === 'p' ? '+' : top.ohcp.type === 'm' ? '-' : '' }} {{ top.ohcp.percent }}%</div> -->
          </div>
        </div>
        <div class="card">
          <div class="name">Last 1 hour transfers amount</div>
          <div class="flex">
            <div class="value">{{ Number(top.oht).toLocaleString() }}</div>
            <v-spacer></v-spacer>
            <!-- <div
              class="per"
              :class="{
                'd-none': top.ohtP.dNone,
                plus: top.ohtP.type === 'p',
                minus: top.ohtP.type === 'm'
              }"
            >{{ top.ohtP.type === 'p' ? '+' : top.ohtP.type === 'm' ? '-' : '' }} {{ top.ohtP.percent }}%</div> -->
          </div>
        </div>
        <div class="card">
          <div class="name">Last 24 hour transfers</div>
          <div class="flex">
            <div class="value">{{ Number(top.odc).toLocaleString() }}</div>
            <v-spacer></v-spacer>
            <!-- <div
              class="per"
              :class="{
                'd-none': top.odcp.dNone,
                plus: top.odcp.type === 'p',
                minus: top.odcp.type === 'm'
              }"
            >{{ top.odcp.type === 'p' ? '+' : top.odcp.type === 'm' ? '-' : '' }} {{ top.odcp.percent }}%</div> -->
          </div>
        </div>
        <div class="card">
          <div class="name">Last 24 hour transfers amount</div>
          <div class="flex">
            <div class="value">{{ Number(top.odt).toLocaleString() }}</div>
            <v-spacer></v-spacer>
            <!-- <div
              class="per"
              :class="{
                'd-none': top.odtP.dNone,
                plus: top.odtP.type === 'p',
                minus: top.odtP.type === 'm'
              }"
            >{{ top.odtP.type === 'p' ? '+' : top.odtP.type === 'm' ? '-' : '' }} {{ top.odtP.percent }}%</div> -->
          </div>
        </div>
      </div>
      <div class="main_chart">
        <div class="tabs">
          <v-btn
            @click="status = 'data'"
            :class="{ active: status === 'data' }"
          >
            TYPE
          </v-btn>
          <v-btn
            @click="status = 'exchange'"
            :class="{ active: status === 'exchange' }"
          >
            EXCHANGE
          </v-btn>
        </div>
        <div class="flex_start" :class="{ none: status === 'exchange' }">
          <div class="main_list_box">
            <div class="list">
              <div class="list_header">
                <div class="cell">Name</div>
                <div class="cell center">Percent</div>
                <div class="cell right">Amount</div>
              </div>
              <div class="list_body">
                <div v-for="(item, i) in list1" :key="i" class="list_row">
                  <div class="cell">{{ item.t }}</div>
                  <div class="cell center">{{ item.p }}%</div>
                  <div class="cell right">{{ item.v }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="main_chart_box">
            <div>
              <doughnut-chart
                ref="chart1"
                :chart-data="chartdata1"
                :chart-options="chartOptions1"
              ></doughnut-chart>
            </div>
          </div>
        </div>
        <div class="flex_start" :class="{ none: status === 'data' }">
          <div class="main_list_box">
            <div class="list">
              <div class="list_header">
                <div class="cell">Name</div>
                <div class="cell center">Percent</div>
                <div class="cell right">Amount</div>
              </div>
              <div class="list_body">
                <div v-for="(item, i) in list2" :key="i" class="list_row">
                  <div class="cell">{{ item.t }}</div>
                  <div class="cell center">{{ item.p }}%</div>
                  <div class="cell right">{{ item.vl }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="main_chart_box">
            <doughnut-chart
              ref="chart2"
              :chart-data="chartdata2"
              :chart-options="chartOptions2"
            ></doughnut-chart>
          </div>
        </div>
      </div>
      <div class="main_chart2">
        <div class="tabs mb-5">
          <v-btn
            @click="status2 = 'hour'"
            :class="{ active: status2 === 'hour' }"
          >
            Hours
          </v-btn>
          <v-btn
            @click="status2 = 'day'"
            :class="{ active: status2 === 'day' }"
          >
            Days
          </v-btn>
          <v-btn
            @click="status2 = 'month'"
            :class="{ active: status2 === 'month' }"
          >
            Months
          </v-btn>
        </div>
        <bar-chart
          ref="chart3"
          :chart-data="chartdata3"
          :chart-options="chartOptions3"
        ></bar-chart>
      </div>
    </div>
  </div>
</template>
<script>
import Bignumber from 'bignumber.js'
import axiosInstance from '@/utils/axiosInstance'
import BarChart from '@/components/chart/Bar'
import DoughnutChart from '@/components/chart/Doughnut'

export default {
  name: 'DashboardView',
  components: {
    BarChart,
    DoughnutChart,
  },
  watch: {
    status2(v) {
      this.chartdata3.labels = this.chart3[v].labels
      this.chartdata3.datasets = this.chart3[v].datasets

      this.$refs.chart3.update()
    },
    erc20Address() {
      this.getData()
    },
  },
  data() {
    return {
      top: {
        ohc: 0,
        oht: 0,
        odc: 0,
        odt: 0,
        ohcp: {},
        ohtP: {},
        odcp: {},
        odtP: {},
      },
      status: 'data',
      status2: 'hour',
      // tab: null,
      list1: [],
      list2: [],
      chart3: {
        hour: {
          labels: [],
          datasets: [
            {
              label: '값',
              backgroundColor: 'rgba(48, 79, 254, 0.1)',
              borderColor: 'rgba(48, 79, 254, 0.4)',
              borderWidth: 1,
              data: [],
            },
            {
              label: '횟수',
              backgroundColor: 'rgba(229, 57, 53, 0.1)',
              borderColor: 'rgba(229, 57, 53, 0.4)',
              borderWidth: 1,
              data: [],
            },
          ],
        },
        day: {
          labels: [],
          datasets: [
            {
              label: '값',
              backgroundColor: 'rgba(48, 79, 254, 0.1)',
              borderColor: 'rgba(48, 79, 254, 0.4)',
              borderWidth: 1,
              data: [],
            },
            {
              label: '횟수',
              backgroundColor: 'rgba(229, 57, 53, 0.1)',
              borderColor: 'rgba(229, 57, 53, 0.4)',
              borderWidth: 1,
              data: [],
            },
          ],
        },
        month: {
          labels: [],
          datasets: [
            {
              label: '값',
              backgroundColor: 'rgba(48, 79, 254, 0.1)',
              borderColor: 'rgba(48, 79, 254, 0.4)',
              borderWidth: 1,
              data: [],
            },
            {
              label: '횟수',
              backgroundColor: 'rgba(229, 57, 53, 0.1)',
              borderColor: 'rgba(229, 57, 53, 0.4)',
              borderWidth: 1,
              data: [],
            },
          ],
        },
      },
      Bignumber,
      chartdata1: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#5899DA',
              '#E8743B',
              '#19A979',
              '#ED4A7B',
              '#945ECF',
              '#13A4B4',
              '#525DF4',
              '#BF399E',
              '#6C8893',
              '#EE6868',
              '#2F6497',
              // '#ffffff',
            ],
          },
        ],
      },
      chartdata2: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              '#5899DA',
              '#E8743B',
              '#19A979',
              '#ED4A7B',
              '#945ECF',
              '#13A4B4',
              '#525DF4',
              '#BF399E',
              '#6C8893',
              '#EE6868',
              '#2F6497',
            ],
          },
        ],
      },
      chartOptions1: {
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#A9A9A9',
            fontSize: 16,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      chartOptions2: {
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0,
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#A9A9A9',
            fontSize: 16,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
      },
      chartdata3: {
        position: 'bottom',
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: 'rgba(48, 79, 254, 0.1)',
            borderColor: 'rgba(48, 79, 254, 0.4)',
            borderWidth: 1,
            data: [],
          },
          {
            label: '',
            backgroundColor: 'rgba(229, 57, 53, 0.1)',
            borderColor: 'rgba(229, 57, 53, 0.4)',
            borderWidth: 1,
            data: [],
          },
        ],
      },
      chartOptions3: {
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          labels: {
            fontColor: '#A9A9A9',
          },
        },
      },
      withdrawAmount: 0,
    }
  },
  methods: {
    getData() {
      if (this.erc20Address.length == 0) return

      axiosInstance
        .get(`/getData?contractAddress=${this.erc20Address}`)
        .then((res) => {
          this.withdrawAmount = res.data.withdraw_amount
          let top = res.data.top
          this.top = {
            ohc: top.n.ohc.toLocaleString(),
            oht: top.n.oht.toLocaleString(),
            odc: top.n.odc.toLocaleString(),
            odt: top.n.odt.toLocaleString(),
            ohcp: {
              dNone: top.p.ohc === 0,
              type:
                top.n.ohc > top.p.ohc ? 'p' : top.n.ohc < top.p.ohc ? 'm' : '',
              percent: new Bignumber(Math.abs(top.n.ohc - top.p.ohc))
                .dividedBy(top.p.ohc)
                .multipliedBy(100)
                .toNumber()
                .toLocaleString(),
            },
            ohtP: {
              dNone: top.p.oht === 0,
              type:
                top.n.oht > top.p.oht ? 'p' : top.n.oht < top.p.oht ? 'm' : '',
              percent: new Bignumber(Math.abs(top.n.oht - top.p.oht))
                .dividedBy(top.p.oht)
                .multipliedBy(100)
                .toNumber()
                .toLocaleString(),
            },
            odcp: {
              dNone: top.p.odc === 0,
              type:
                top.n.odc > top.p.odc ? 'p' : top.n.odc < top.p.odc ? 'm' : '',
              percent: new Bignumber(Math.abs(top.n.odc - top.p.odc))
                .dividedBy(top.p.odc)
                .multipliedBy(100)
                .toNumber()
                .toLocaleString(),
            },
            odtP: {
              dNone: top.p.odt === 0,
              type:
                top.n.odt > top.p.odt ? 'p' : top.n.odt < top.p.odt ? 'm' : '',
              percent: new Bignumber(Math.abs(top.n.odt - top.p.odt))
                .dividedBy(top.p.odt)
                .multipliedBy(100)
                .toNumber()
                .toLocaleString(),
            },
          }

          let total = new Bignumber(0)

          // 타입 별
          let tMap = {
            0: { t: 'Default', v: 0 },
            1: { t: 'Company', v: 0 },
            2: { t: 'Exchange', v: 0 },
            3: { t: 'Marketing', v: 0 },
            4: { t: 'Sale', v: 0 },
            5: { t: 'Staking', v: 0 },
            6: { t: 'Team', v: 0 },
            7: { t: 'CM liquidity', v: 0 },
            8: { t: 'Advisor', v: 0 },
            9: { t: 'Unlock', v: 0 },
            10: { t: 'Portal', v: 0 },
            11: { t: 'System', v: 0 },
          }

          res.data.total.forEach((item) => {
            let obj = tMap[item.type || '0']
            obj.v = new Bignumber(obj.v).plus(item.value, 10).toNumber()
            total = total.plus(item.value, 10)
          })

          this.list1 = [
            {
              t: 'All',
              p: '100',
              v: total.toNumber().toLocaleString(),
            },
          ]

          this.chartdata1.labels = []
          this.chartdata1.datasets[0].data = []

          Object.keys(tMap).forEach((key) => {
            let item = tMap[key]
            let percent = new Bignumber(item.v)
              .dividedBy(total)
              .multipliedBy(100)
              .toNumber()

            this.list1.push({
              t: item.t,
              v:
                percent == 100
                  ? total.toNumber().toLocaleString()
                  : item.v.toLocaleString(),
              p: percent.toLocaleString(),
            })

            this.chartdata1.labels.push(item.t)
            this.chartdata1.datasets[0].data.push(percent)
          })

          total = new Bignumber(0)

          // Exchange 별
          res.data.exchange.forEach((item) => {
            this.list2.push({
              t: item.name,
              v: item._value,
              vl: Number(item._value).toLocaleString(),
            })
            total = total.plus(item._value, 10)
          })

          this.chartdata2.labels = []
          this.chartdata2.datasets[0].data = []

          this.list2.forEach((item) => {
            let percent = new Bignumber(item.v, 10)
              .multipliedBy(100)
              .dividedBy(total)
              .toNumber()

            item.p = percent.toLocaleString()
            item.v = item.v.toLocaleString()

            this.chartdata2.labels.push(item.t)
            this.chartdata2.datasets[0].data.push(percent)
          })

          res.data.hour
            .sort((a, b) => parseInt(b.time) - parseInt(a.time))
            .forEach((item) => {
              this.chart3.hour.labels.push(
                new Date(parseInt(item.time)).toISOString().slice(11, 16)
              )
              this.chart3.hour.datasets[0].data.push(parseInt(item.amount))
              this.chart3.hour.datasets[1].data.push(parseInt(item.count))
            })

          res.data.day
            .sort((a, b) => b.time.localeCompare(a.time))
            .forEach((item) => {
              this.chart3.day.labels.push(item.time)
              this.chart3.day.datasets[0].data.push(parseInt(item.amount))
              this.chart3.day.datasets[1].data.push(parseInt(item.count))
            })

          res.data.month
            .sort((a, b) => b.time.localeCompare(a.time))
            .forEach((item) => {
              this.chart3.month.labels.push(item.time)
              this.chart3.month.datasets[0].data.push(parseInt(item.amount))
              this.chart3.month.datasets[1].data.push(parseInt(item.count))
            })

          this.chartdata3.labels = this.chart3.hour.labels
          this.chartdata3.datasets = this.chart3.hour.datasets

          this.$refs.chart1.update()
          this.$refs.chart2.update()
          this.$refs.chart3.update()
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  computed: {
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
  },
  mounted() {
    this.getData()
  },
}
</script>
<style src="../../../src/assets/css/style.css"></style>
<style>
.main_chart_box {
  display: flex;
  justify-content: center;
}

.main_chart_box > div {
  max-width: 700px !important;
  flex: 1 1 700px;
}
</style>
