var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.stage == 1)?_c('div',[_c('v-row',{staticClass:"search_row"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Search"},on:{"click:clear":_vm.clearItem},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"btn-row"},[_c('v-btn',{on:{"click":_vm.addNetwork}},[_vm._v("Add")]),_c('v-btn',{attrs:{"disabled":_vm.progressValue != 100},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('progress-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressValue != 100),expression:"progressValue != 100"}],attrs:{"options":_vm.progressOptions,"value":_vm.progressValue}})],1)])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-4 outlined",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
            'items-per-page-options': [5, 10, 20, 50, 100],
          },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item._id},[_c('td',[_c('div',{staticClass:"hover_area"},[_c('div',{staticClass:"hover_address"},[_vm._v(_vm._s(item.address))]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.address}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.address)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(
                            item.chainID,
                            `/token/${item.address}`,
                            item.tokenType
                          )}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)])]),_c('td',[_vm._v(_vm._s(item.chainID))]),_c('td',[_vm._v(_vm._s(item.tokenType))]),_c('td',[_vm._v(_vm._s(item.tokenName))]),_c('td',[_vm._v(_vm._s(item.tokenSymbol))]),_c('td',[_vm._v(_vm._s(item.lastBlockNumber))]),_c('td',[_vm._v(_vm._s(item.initBlockNum))]),_c('td',[_vm._v(_vm._s(_vm.getProcessStatus(item)))]),_c('td',[_c('v-checkbox',{staticClass:"enabled-check",attrs:{"input-value":JSON.parse(item.enabled),"color":"icon","disabled":""}})],1),_c('td',[_c('button',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-database-edit-outline")])],1)])])}),0)]}}],null,false,1469511696)})],1),_c('v-row',[_c('pre',[_vm._v("ChainID :             1(Ethereum), 56(BSC), Polygon(137), 80001(Polygon Testnet)\nAddress :             smart contract address\nTokenType:            contract type (erc20, erc721, erc1155)\nBlockNumber:          last block number\nInitial Block Number: first block number\nProcess:\n                       1 progress_transaction\n                       2 progress_volume\n                       3 progress_deposit\n                       4 progress_skyplay_wallet\n                       5 progress_holder_balance_check\n                       6 progress_flush_token\n\n                       7 progress_withdraw\n                       8 progress_withdraw_status\n                       9 progress_airdrop\n                      10 progress_airdrop_status\n                      11 progress_minting\n                      12 progress_minting_status\n\n                      13 initialized")])])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.stage != 1),expression:"stage != 1"}]},[_c('NetworkEdit',{attrs:{"item":_vm.currentItem,"stage":_vm.stage},on:{"close":_vm.clearItem}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }