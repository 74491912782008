var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 outlined account-list mt-4",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      'items-per-page-options': [5, 10, 20, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.seq},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_c('div',{staticClass:"hover_area"},[_vm._v(" "+_vm._s(item.fromDescription)+" "),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.fromAddress}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.fromAddress)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(
                            `address/${item.fromAddress}`,
                            'erc20'
                          )}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fromAddress))])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_c('div',{staticClass:"hover_area"},[(item.toDescription != '')?_c('div',[_vm._v(" "+_vm._s(item.toDescription)+" ")]):_c('div',{staticClass:"hover_address"},[_vm._v(" "+_vm._s(item.toAddress)+" ")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.address}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.toAddress)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(
                            `address/${item.toAddress}`,
                            'erc20'
                          )}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.toAddress))])])],1),_c('td',[_vm._v(_vm._s(_vm.convertValue(item.amount)))]),(item.state == '0')?_c('td',[_vm._v("승인 요청")]):(item.state == '1')?_c('td',[_vm._v("승인 완료")]):(item.state == '2')?_c('td',[_vm._v("MPC 요청")]):(item.state == '3' && item.transactionHash.length == 0)?_c('td',[_vm._v(" MPC 완료 ")]):(item.state == '3' && item.transactionHash.length > 0)?_c('td',[_c('div',{staticClass:"hover_area"},[_vm._v(" 전송 요청 "),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(`tx/${item.transactionHash}`, 'erc20')}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)])]):(item.state == '4')?_c('td',[_vm._v("전송 확인")]):(item.state == '5')?_c('td',[_vm._v("전송 에러")]):(item.state == '6')?_c('td',[_vm._v("서명 에러")]):_c('td',[_vm._v(_vm._s(item.state))]),_c('td',[(item.state == '1')?_c('v-btn',{on:{"click":function($event){return _vm.transfer(item.seq)}}},[_vm._v(" SKP 전송 ")]):_vm._e(),(item.state == '6')?_c('v-btn',{on:{"click":function($event){return _vm.transfer(item.seq)}}},[_vm._v(" SKP 재전송 ")]):_vm._e()],1)])}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }