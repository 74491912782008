<template>
  <div>
    <v-row>
      <v-btn class="mr-2" color="secondary" @click="close">
        <v-icon>mdi-arrow-left-thin</v-icon>
      </v-btn>
      <v-btn v-if="stage == 3" class="mr-2" color="primary" @click="addUser">
        Save
      </v-btn>
      <v-btn v-if="stage == 2" class="mr-2" color="primary" @click="modifyUser">
        Modify
      </v-btn>
      <v-btn
        v-if="stage == 2"
        class="mr-2"
        color="secondary"
        @click="deleteUser"
      >
        Delete
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-text-field
          label="Address*"
          v-model="address"
          required
        ></v-text-field>
        <v-text-field label="Name*" v-model="name" required></v-text-field>
        <v-checkbox
          @change="check('enabled', $event)"
          color="icon"
          label="Enabled"
          v-model="enabled"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <h4>Contract</h4>
        <ContractEdit
          v-if="stage == 2"
          :address="address"
          :codeList="codeList"
        ></ContractEdit>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <h4>Menu permissions</h4>
        <PermissionMenu
          v-if="stage == 2"
          :address="address"
          @setCodeList="setCodeList"
        ></PermissionMenu>
      </v-col>
      <v-col cols="12" lg="6">
        <h4>API permissions</h4>
        <PermissionEdit
          v-if="stage == 2"
          :address="address"
          :codeList="codeList"
        ></PermissionEdit>
      </v-col>
    </v-row>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import ContractEdit from '@/components/Contract/ContractEdit.vue'
import PermissionEdit from '@/components/Permission/PermissionEdit.vue'
import PermissionMenu from '@/components/Permission/PermissionMenu.vue'

export default {
  name: 'NetworkEdit',
  components: {
    ConfirmDlg,
    ContractEdit,
    PermissionEdit,
    PermissionMenu,
  },
  props: {
    item: Object,
    stage: Number,
  },
  data() {
    return {
      id: '',
      address: '',
      name: '',
      enabled: true,
      codeList: '',
    }
  },
  computed: {
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    item() {
      if (this.item) {
        this.id = this.item._id
        this.address = this.item.address
        this.name = this.item.name
        this.enabled = JSON.parse(this.item.enabled)
      }
    },
  },
  methods: {
    async addUser() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '추가하겠습니까?'
      )
      if (result) {
        axiosInstance
          .post('/account/add', {
            address: this.address,
            name: this.name,
            enabled: this.enabled.toString(),
          })
          .then((r) => {
            if (r.status == 200) {
              this.close()
            }
          })
          .catch((err) => {
            alert(err?.response?.data?.message)
            console.error(err)
          })
      }
    },
    async modifyUser() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '수정하겠습니까?'
      )
      if (result) {
        axiosInstance
          .post('/account/modify', {
            address: this.address,
            name: this.name,
            enabled: this.enabled.toString(),
          })
          .then((r) => {
            if (r.status == 200) {
              this.close()
            }
          })
          .catch((err) => {
            alert(err?.response?.data?.message)
            console.error(err)
          })
      }
    },
    check: function (command, checked) {
      this[`${command}`] = checked
    },
    async deleteUser() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.name + '</strong> ' + '삭제하겠습니까?'
      )
      if (result) {
        axiosInstance
          .delete(`/account/${this.address}`)
          .then((r) => {
            if (r.status == 200) {
              this.close()
            }
          })
          .catch((err) => {
            alert(err?.response?.data?.message)
            console.error(err)
          })
      }
    },
    close() {
      this.$emit('close')
    },
    setCodeList(value) {
      this.codeList = value
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
.permission {
  width: 100%;
  margin: 10px;
  padding: 20px;
  color: #ffff;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}
.permission h3 {
  margin-bottom: 10px;
}

h4 {
  margin-top: 20px;
}
</style>
