var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 outlined account-list mt-4",attrs:{"headers":_vm.headers,"items":_vm.list,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
      'items-per-page-options': [5, 10, 20, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.seq},[_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[(item.fromAddress != undefined)?_c('div',{staticClass:"hover_area"},[_vm._v(" "+_vm._s(item.fromDescription)+" "),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.fromAddress}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.fromAddress)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(
                            `address/${item.fromAddress}`,
                            'erc20'
                          )}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fromAddress))])])],1),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_c('div',{staticClass:"hover_area"},[(item.toDescription != '')?_c('div',[_vm._v(" "+_vm._s(item.toDescription)+" ")]):_c('div',{staticClass:"hover_address"},[_vm._v(" "+_vm._s(item.toAddress)+" ")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.toAddress}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.toAddress)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(
                            `address/${item.toAddress}`,
                            'erc20'
                          )}}},[_c('v-icon',[_vm._v("mdi-link")])],1)],1)])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.toAddress))])])],1),_c('td',[_vm._v(_vm._s(_vm.convertValue(item.amount)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'span',attrs,false),on),[_c('div',{staticClass:"hover_area"},[_vm._v(" "+_vm._s(item.signCount)+" / "+_vm._s(item.threshold)+" "),_c('v-icon',[_vm._v("mdi-account-multiple")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.signatureList}",refInFor:true,staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.signatureList)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.converList(item.signatureListDescription)))])])],1),_c('td',[_c('v-btn',{on:{"click":function($event){return _vm.signature(item.seq)}}},[_vm._v("승인")])],1)])}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }