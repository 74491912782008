<template>
  <div>
    <v-text-field clearable v-model="keyword" label="Search" class="mr-4" />
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="itemsPerPage"
      :server-items-length="totalCount"
      :options.sync="options"
      class="elevation-1 outlined permit-list"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.seq">
            <td>
              <v-checkbox
                :input-value="item.selected"
                @change="check(item.seq, item.code, $event)"
                color="icon"
              ></v-checkbox>
            </td>
            <td>{{ item.code }}</td>
            <td>{{ item.route }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'PermissionEdit',
  props: {
    address: String,
  },
  data() {
    return {
      headers: [
        { text: 'enabled', value: 'seq', sortable: false, width: '15%' },
        { text: 'code', value: 'code', sortable: false, width: '25%' },
        { text: 'route', value: 'route', sortable: false, width: '35%' },
      ],
      options: {},
      keyword: '',
      sort: '',
      list: [],
      totalCount: 0,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    keyword() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)
    },
    address() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page,
          firstItem: (page - 1) * itemsPerPage,
        })
        this.getList(page, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(page, per_page) {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (page == undefined) {
        page = this.options.page
      }
      if (per_page == undefined) {
        per_page = itemsPerPage
      }
      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }
      parameters = parameters + '&type=0'

      axiosInstance
        .get(`/permissions?${parameters}`)
        .then((r) => {
          if (r.status == 200) {
            let _list = r.data.data.map((item) => {
              return { ...item, selected: item.seq == '0' ? false : true }
            })
            this.list = _list
            this.totalCount = r.data.meta.total
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    check: function (seq, code, checked) {
      if (checked) {
        axiosInstance
          .post('/permissions', {
            address: this.address,
            code,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        axiosInstance
          .delete(`/permissions`, {
            params: {
              address: this.address,
              code,
            },
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
