<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="search" label="Search" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          Total: {{ totalQuantity.toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="cell cell_addr hover_area">
            <div class="text_cut">
              <span>{{ item.address }}</span>
            </div>
            <div class="hover_inner">
              <v-btn class="copy" @click="copy(item.address)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn class="link" @click="move(item.address)">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.quantity`]="{ item }">
          <div class="cell cell_amount">
            {{ item.quantity.toLocaleString() }}
          </div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>
<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanURL } from '@/utils/network'

export default {
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', width: '50%' },
        { text: 'Quantity', value: 'quantity', width: '50%' },
      ],
      list: [],
      totalCount: 0,
      totalQuantity: 0,
      search: '',
      sort: '',
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    search() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc721Address() {
      this.getList()
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }

      if (limit == undefined) {
        limit = itemsPerPage
      }

      let { search, type } = this

      axiosInstance
        .post('/tokentracker/getHolders', {
          contractAddress: this.erc721Address,
          tokenType: 'erc721',
          offset,
          limit,
          type,
          search,
          sort: this.sort,
        })
        .then((res) => {
          this.list = res.data.holderlist
          this.totalCount = res.data.count
          this.totalQuantity = res.data.totalQuantity
        })
        .catch(console.error)
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData = '\ufeff' + 'address,quantity\n'

      try {
        this.progressValue = 0
        do {
          const { data, status } = await axiosInstance.post(
            '/tokentracker/getHolders',
            {
              contractAddress: this.erc721Address,
              tokenType: 'erc721',
              sort: this.sort,
              offset: skip,
              limit: 10000,
              type: this.type,
              search: this.search,
            }
          )
          if (status == 200) {
            totalCount = data.count

            for (let item of data.holderlist) {
              processData += `${item.address},${item.quantity}\n`
            }
            skip += data.holderlist.length
          } else {
            return
          }

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'holders_erc721.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    copy(v) {
      let textArea = document.createElement('textarea')
      textArea.value = v
      textArea.style.height = '1px'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)

      textArea.select()

      try {
        document.execCommand('copy')
        // this.$toast.success('copy!')
      } catch (err) {
        // this.$toast.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    move(v) {
      window.open(
        `${scanURL(this.chainID)}/token/${this.erc721Address}?a=${v}`,
        '_blank'
      )
    },
  },
  mounted() {
    this.getList()
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
<style scoped></style>
