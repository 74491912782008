<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      :items-per-page="itemsPerPage"
      :page="page"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100],
      }"
      class="elevation-1 outlined account-list mt-4"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.seq">
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" dark v-bind="attrs" v-on="on">
                    <div
                      class="hover_area"
                      v-if="item.fromAddress != undefined"
                    >
                      {{ item.fromDescription }}
                      <div class="hover_inner">
                        <v-btn
                          @click="addrCopy(item.fromAddress)"
                          class="copy"
                          ref="${item.fromAddress}"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                          @click="
                            networkExplorer(
                              `address/${item.fromAddress}`,
                              'erc20'
                            )
                          "
                          class="link"
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </span>
                </template>
                <span>{{ item.fromAddress }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" dark v-bind="attrs" v-on="on">
                    <div class="hover_area">
                      <div v-if="item.toDescription != ''">
                        {{ item.toDescription }}
                      </div>
                      <div class="hover_address" v-else>
                        {{ item.toAddress }}
                      </div>
                      <div class="hover_inner">
                        <v-btn
                          @click="addrCopy(item.toAddress)"
                          class="copy"
                          ref="${item.toAddress}"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                          @click="
                            networkExplorer(
                              `address/${item.toAddress}`,
                              'erc20'
                            )
                          "
                          class="link"
                        >
                          <v-icon>mdi-link</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </span>
                </template>
                <span>{{ item.toAddress }}</span>
              </v-tooltip>
            </td>
            <td>{{ convertValue(item.amount) }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span color="primary" dark v-bind="attrs" v-on="on">
                    <div class="hover_area">
                      {{ item.signCount }} / {{ item.threshold }}
                      <v-icon>mdi-account-multiple</v-icon>
                      <div class="hover_inner">
                        <v-btn
                          @click="addrCopy(item.signatureList)"
                          class="copy"
                          ref="${item.signatureList}"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </span>
                </template>
                <span>{{ converList(item.signatureListDescription) }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-btn @click="signature(item.seq)">승인</v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'WaitingApprovalView',
  data() {
    return {
      options: {},
      headers: [
        { text: '지갑주소(MPC)', value: 'fromAddress' },
        { text: '받는주소', value: 'toAddress' },
        { text: 'Amount', value: 'amount', width: '10%' },
        { text: '서명수 ', value: '', width: '10%' },
        { text: '', value: '', width: '10%' },
      ],
      walletAddress: '0x8cea442068f31627c21a0e304f8083a10a7a4db9',
      dialog: false,
      totalCount: 0,
      list: [],
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }

      axiosInstance
        .post('/mpcwallet/request/list', {
          offset,
          limit,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.count
            this.list = r.data.list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    signature(seq) {
      if (confirm('Do you want to approve the transaction?')) {
        axiosInstance
          .post('/mpcwallet/signature', {
            seq,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    convertValue(value) {
      const result = BigInt(value) / BigInt('100000000000000')
      const val = Number(result) / 10000
      return val.toFixed(4)
    },
    converList(list) {
      return list.replaceAll(',', ', ')
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
