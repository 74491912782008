<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="8">
        <v-text-field clearable v-model="query" label="Search" class="mr-4" />
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          METAQ: {{ Number(totalMETAQ).toLocaleString() }} &nbsp;/&nbsp; AQUA:
          {{ Number(totalAQUA).toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="address"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.wallet`]="{ item }">
          <div class="hover_area">
            <p class="hover_address">
              {{ item.wallet }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.wallet)"
                class="copy"
                ref="${item.wallet}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn @click="query = item.wallet" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'OldTokenView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'wallet', value: 'wallet', width: '25%' },
        { text: 'metaq', value: 'metaq', width: '10%' },
        { text: 'aqua', value: 'aqua', width: '10%' },
      ],
      displist: [],
      query: '',
      sort: '',
      totalCount: 0,
      totalAmount: 0,
      totalAQUA: 0,
      totalMETAQ: 0,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/metaplanet_token/list_oldtoken', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.totalMETAQ = result.data.totalMETAQ
            this.totalAQUA = result.data.totalAQUA
            this.displist = result.data.list.map((item) => {
              item.metaq = Number(item.metaq).toLocaleString()
              item.aqua = Number(item.aqua).toLocaleString()
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData = '\ufeff' + 'wallet,metaq,value\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/metaplanet_token/list',
            {
              offset: skip,
              limit: 1000,
              query: this.query,
              sort: this.sort,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.wallet},${item.metaq},${item.value}`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'token.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  async beforeMount() {
    const date = new Date()
    date.setDate(date.getDate() + 365)
    this.allowDate = date.toISOString().substr(0, 10)

    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}

.enabled-check div.v-messages.theme--light {
  display: none;
}

.enabled-check div.v-messages.theme--dark {
  display: none;
}

.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 12px;
}
</style>
