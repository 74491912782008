var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"search_row"},[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"clearable":"","label":"Search"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('div',{staticClass:"btn-row"},[_c('v-btn',{attrs:{"disabled":_vm.progressValue != 100},on:{"click":_vm.download}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('progress-bar',{directives:[{name:"show",rawName:"v-show",value:(_vm.progressValue != 100),expression:"progressValue != 100"}],attrs:{"options":_vm.progressOptions,"value":_vm.progressValue}})],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('h3',{staticStyle:{"text-align":"right"}},[_vm._v(" Total: "+_vm._s(Number(_vm.totalCount).toLocaleString())+" / "+_vm._s(Number(_vm.totalAmount).toLocaleString())+" ")])])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-4 outlined",attrs:{"headers":_vm.headers,"items":_vm.displist,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.address`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('p',{staticClass:"hover_address",style:(_vm.isBlacklisted(item.address)
                ? 'color:#d32f2f'
                : _vm.isDuplicated(item.address)
                ? 'color:#f9a825'
                : '')},[_vm._v(" "+_vm._s(item.name.length > 0 ? item.name : item.address)+" ")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.address}",staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.address)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.networkExplorer(`address/${item.address}`, 'erc20')}}},[_c('v-icon',[_vm._v("mdi-link")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.setAddress(item.address)}}},[_c('v-icon',[_vm._v("mdi-magnify-expand")])],1),_c('v-btn',{staticClass:"copy",on:{"click":function($event){_vm.query = item.address}}},[_c('v-icon',[_vm._v("mdi-filter-outline")])],1)],1)])]}},{key:`item.amountf`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('p',{style:(item.amountf > 3000
                ? item.amountf > 5000
                  ? item.amountf > 10000
                    ? 'color:#d32f2f'
                    : 'color:#f9a825'
                  : 'color:#4caf50'
                : '')},[_vm._v(" "+_vm._s(item.amountf.toLocaleString())+" ")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{staticClass:"link",on:{"click":function($event){item.amountf > 10000
                  ? _vm.setQuery('10000')
                  : item.amountf > 5000
                  ? _vm.setQuery('5000')
                  : _vm.setQuery('3000')}}},[_c('v-icon',[_vm._v("mdi-magnify-expand")])],1)],1)])]}}],null,true)})],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.address.length > 0),expression:"address.length > 0"}],staticClass:"mt-12 ml-1 mr-1"},[_c('ERC20WithdrawAddress',{attrs:{"address":_vm.address,"withdrawStatus":_vm.withdrawStatus}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }