<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <div class="hover_address" style="color: red">
              {{ item.name.length > 0 ? item.name : item.address }}
            </div>
            <div v-if="item.address.length > 0" class="hover_inner">
              <v-btn class="edit" @click="openModal(item.address)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="deleteBlacklist(item.address)" class="link">
                <v-icon>mdi-restore</v-icon>
              </v-btn>
              <v-btn @click="setAddress(item.address)" class="link">
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
              <v-btn @click="query = item.address" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.reason`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.reason }}
            </div>
            <div class="hover_inner">
              <v-btn @click="addrCopy(item.reason)" class="copy">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn @click="editReason(item)" class="copy">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn @click="query = item.reason" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <div v-show="address.length > 0">
        <!-- 같은 주소를 사용한 사용자 목록 -->
        <ERC20WithdrawAddress
          :address="address"
          :withdrawStatus="withdrawStatus"
        ></ERC20WithdrawAddress>
      </div>
    </v-row>
    <v-dialog v-model="dialogReason" persistent max-width="1000px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">blacklist 등록 사유</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="reason" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="closeDialog"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="registerReason"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="375px">
      <v-card class="modal deal">
        <div class="modal_title">{{ selected.address }}</div>
        <div class="pa-3">
          <div class="box">
            <div class="label">Type</div>
            <div class="modal_input">
              <v-select
                hide-details
                :items="types"
                v-model="selected.type"
              ></v-select>
            </div>
          </div>
          <div class="box">
            <div class="label">Name</div>
            <div class="modal_input">
              <v-text-field
                v-model="selected.name"
                hide-details
                @keydown="modalKeydown"
                placeholder="Name"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-6">
            <v-btn
              depressed
              block
              color="bk"
              dark
              height="40px"
              @click="updateInfo"
              :loading="selected.load"
            >
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ERC20WithdrawAddress from '@/components/ERC20WithdrawAddress/ERC20WithdrawAddress.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'ERC20WithdrawBlacklistView',
  components: {
    ERC20WithdrawAddress,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Issuer', value: 'issuer', width: '7%' },
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Withdrawal Suspension Reason', value: 'reason' },
        { text: 'Registered Date', value: 'createdAt', width: '15%' },
      ],
      types: [
        { text: 'Default', value: '0' },
        { text: 'Company', value: '1' },
        { text: 'Exchange', value: '2' },
        { text: 'Marketing', value: '3' },
        { text: 'Sale', value: '4' },
        { text: 'Staking', value: '5' },
        { text: 'Team', value: '6' },
        { text: 'CM liquidity', value: '7' },
        { text: 'Advisor', value: '8' },
        { text: 'Unlock', value: '9' },
        { text: 'Portal', value: '10' },
        { text: 'System', value: '11' },
      ],
      displist: [],
      totalCount: 0,
      address: '',
      reason: '',
      query: '',
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      withdrawStatus: '1',
      selected: {},
      dialog: false,
      dialogReason: false,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.address = ''
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    modalKeydown(event) {
      if (event.keyCode === 13) {
        this.updateInfo()
      }
    },
    updateInfo() {
      let { _id, name, type, address } = this.selected

      this.selected.load = true

      axiosInstance
        .post('/tokentracker/updateAddressInfo', {
          contractAddress: this.erc20Address,
          _id,
          name,
          type,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            this.dialog = false
            // this.$toast.success('success')

            this.list.forEach((item) => {
              item.name = item.address === address ? name : item.name
            })

            this.$forceUpdate()
          }
        })
        .catch(console.error)
        .finally(() => {
          this.selected.load = false
        })
    },
    openModal(address) {
      axiosInstance
        .post('/tokentracker/getAddressInfo', {
          contractAddress: this.erc20Address,
          address,
        })
        .then((res) => {
          this.selected.type = res.data.type || '0'
          this.selected.name = res.data.name || ''
          this.selected._id = res.data._id
          this.selected.address = address
        })
        .catch(console.error)
        .finally(() => {
          this.dialog = true
        })
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/withdraw/listBlacklist', {
          erc20Address: this.erc20Address,
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((r) => {
          this.totalCount = r.data.totalCount
          this.displist = r.data.list.map((item) => {
            item.createdAt = item.createdAt.substring(0, 19)
            item.updatedAt = item.updatedAt.substring(0, 19)
            item.registerDatetime = item.registerDatetime.substring(0, 16)
            return item
          })
        })
    },
    setQuery(query) {
      this.address = ''
      this.query = query
    },
    setAddress(address) {
      this.address = address
    },
    deleteBlacklist(address) {
      if (confirm('Do you want to remove from the blacklist?')) {
        axiosInstance
          .post('/withdraw/deleteBlacklist', {
            erc20Address: this.erc20Address,
            address,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            } else {
              alert('Blacklist에서 삭제를 실패했습니다.')
            }
          })
          .catch(() => {
            alert('Blacklist에서 삭제를 실패했습니다.')
          })
      }
    },
    editReason(item) {
      this.item = item
      this.reason = item.reason
      this.dialogReason = true
    },
    registerReason() {
      axiosInstance
        .post('/withdraw/setBlackReason', {
          erc20Address: this.erc20Address,
          id: this.item.id,
          reason: this.reason,
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to add to the blacklist.')
          }
        })
        .catch(() => {
          alert('Failed to add to the blacklist.')
        })
      this.dialogReason = false
    },
    closeDialog() {
      this.dialogReason = false
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData = '\ufeff' + 'address,reason,registerDatetime\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/withdraw/listBlacklist',
            {
              erc20Address: this.erc20Address,
              offset: skip,
              limit: 1000,
              query: this.query,
              sort: this.sort,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.address},"${item.reason}",${item.registerDatetime}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'withdraw_blacklist.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
