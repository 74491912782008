<template>
  <div v-show="displist && displist.length > 0">
    <v-row>
      <v-col cols="12" lg="6">
        <div class="address" style="color: #f9a825">
          출금주소 중복사용 - {{ totalCount }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.memberId`]="{ item }">
          <div class="hover_area">
            <p
              class="hover_address"
              v-bind:style="isBlacklisted(item.memberId) ? 'color:#d32f2f' : ''"
            >
              {{ item.memberId }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.memberId)"
                class="copy"
                ref="${item.memberId}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn @click="addBlacklistReason(item.memberId)" class="link">
                <v-icon>mdi-block-helper</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.channelEmail`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.channelEmail }}</div>
            <div
              v-if="item.channelEmail && item.channelEmail.length > 0"
              class="hover_inner"
            >
              <v-btn
                @click="addrCopy(item.channelEmail)"
                class="copy"
                ref="${item.channelEmail}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.amountf`]="{ item }">
          <div class="hover_area">
            <div
              v-bind:style="
                item.amountf > 3000
                  ? item.amountf > 5000
                    ? item.amountf > 10000
                      ? 'color:#d32f2f'
                      : 'color:#f9a825'
                    : 'color:#4caf50'
                  : ''
              "
            >
              {{ item.amountf.toLocaleString() }}
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">blacklist 등록 사유</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="reason" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="dialog = false"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="registerBlacklist"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'ERC20WithdrawAddress',
  props: {
    address: {
      type: String,
      required: true,
    },
    withdrawStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: {},
      firstItem: 0,
      page: 1,
      headers: [
        { text: 'Member ID', value: 'memberId', width: '12%' },
        { text: 'Channel Email', value: 'channelEmail', width: '12%' },
        { text: 'Amount', value: 'amountf', width: '8%' },
        { text: 'Wallet Address', value: 'address', width: '17%' },
        {
          text: 'Request Date',
          value: 'registerDatetime',
          width: '12%',
        },
        { text: 'Withdrawal Date', value: 'updatedAt', width: '15%' },
      ],
      displist: [],
      totalCount: 0,
      blacklist: [],
      sort: '',
      memberId: '',
      reason: '',
      dialog: false,
    }
  },
  watch: {
    address() {
      this.page = 1
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }

        this.page = page
        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  computed: {
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      if (this.address.length > 0) {
        axiosInstance
          .post('/withdraw/listBlacklist', {
            erc20Address: this.erc20Address,
            offset: 0,
            limit: -1,
          })
          .then((r) => {
            this.blacklist = r.data.list.map((item) => {
              if (item.address.length > 0) return item.address
              if (item.memberId.length > 0) return item.memberId
            })
          })

        axiosInstance
          .post('/withdraw/list', {
            erc20Address: this.erc20Address,
            offset,
            limit,
            address: this.address,
            sort: this.sort,
            status: this.withdrawStatus,
          })
          .then((r) => {
            if (r.status == 200) {
              this.totalCount = r.data.totalCount
              this.displist = r.data.list.map((element) => {
                element.createdAt = element.createdAt.substring(0, 19)
                element.updatedAt =
                  element.status == '1'
                    ? element.updatedAt.substring(0, 19)
                    : ''
                element.registerDatetime = element.registerDatetime.substring(
                  0,
                  16
                )
                const amountf = parseFloat(element.amountf)
                if (amountf > 0)
                  element.amountf = Math.round(amountf * 10000) / 10000

                return element
              })
            }
          })
      }
    },
    isBlacklisted(address) {
      return this.blacklist.find((item) => item == address) == undefined
        ? false
        : true
    },
    addBlacklistReason(memberId) {
      this.memberId = memberId
      this.reason = ''
      this.dialog = true
    },
    registerBlacklist() {
      this.dialog = false
      axiosInstance
        .post('/withdraw/addBlacklist', {
          erc20Address: this.erc20Address,
          memberId: this.memberId,
          reason: this.reason,
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to add to the blacklist.')
          }
        })
        .catch(() => {
          alert('Failed to add to the blacklist.')
        })
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
