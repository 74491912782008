import Vue from 'vue'
import Vuex from 'vuex'

import auth from '@/store/auth'
import error from '@/store/error'
import pagination from '@/store/pagination'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {},
  getters: {
    loading(_state, _getters, rootState) {
      return Object.keys(rootState).some((stateName) => {
        return rootState[stateName].loading
      })
    },
  },
  mutations: {},
  actions: {},
  modules: {
    auth,
    error,
    pagination,
  },
})
