<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="query" label="Search" class="mr-4" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn @click="registerDialog = true">Coin Sale 등록</v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="2">
        <h3 style="text-align: right">
          Sum: {{ Number(totalAmount).toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="address"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.wallet`]="{ item }">
          <div class="hover_area">
            <p class="hover_address">
              {{ item.wallet }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.wallet)"
                class="copy"
                ref="${item.wallet}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.wallet}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="query = item.wallet" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.editItem`]="{ item }">
          <button v-on:click="editItem(item)">
            <v-icon>mdi-square-edit-outline</v-icon>
          </button>
        </template>
        <template v-slot:[`item.deleteItem`]="{ item }">
          <button v-on:click="deleteItem(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </button>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="registerDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Coin Sale 등록</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-text-field
                label="Nickname*"
                v-model="currentItem.nickname"
                required
              ></v-text-field>
              <v-text-field
                label="Phone"
                v-model="currentItem.phone"
              ></v-text-field>
              <v-text-field
                label="Deposit"
                v-model="currentItem.deposit"
                type="number"
              ></v-text-field>
              <v-text-field
                label="metaq"
                v-model="currentItem.metaq"
                type="number"
              ></v-text-field>
              <v-checkbox
                v-model="currentItem.lockup"
                :label="`Lockup: ${currentItem.lockup.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                label="Wallet*"
                v-model="currentItem.wallet"
                required
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="currentItem.email"
              ></v-text-field>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="currentItem.depositDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Deposit Date"
                    v-model="currentItem.depositDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentItem.depositDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="setDepositeDate(currentItem.depositDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                label="salePrice"
                v-model="currentItem.salePrice"
                type="number"
              ></v-text-field>
              <v-checkbox
                v-model="currentItem.completed"
                :label="`Completed: ${currentItem.completed.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="12">
              <v-text-field
                label="Description"
                v-model="currentItem.description"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="registerDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="addCoinsale">
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Modify</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6">
              <v-text-field
                label="Nickname*"
                v-model="currentItem.nickname"
                required
              ></v-text-field>
              <v-text-field
                label="Phone"
                v-model="currentItem.phone"
              ></v-text-field>
              <v-text-field
                label="Deposit"
                v-model="currentItem.deposit"
                type="number"
              ></v-text-field>
              <v-text-field
                label="metaq"
                v-model="currentItem.metaq"
                type="number"
              ></v-text-field>
              <v-checkbox
                v-model="currentItem.lockup"
                :label="`Lockup: ${currentItem.lockup.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="6">
              <v-text-field
                label="Wallet*"
                v-model="currentItem.wallet"
                required
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="currentItem.email"
              ></v-text-field>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="currentItem.depositDate"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Deposit Date"
                    v-model="currentItem.depositDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="currentItem.depositDate"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="setDepositeDate(currentItem.depositDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-text-field
                label="salePrice"
                v-model="currentItem.salePrice"
                type="number"
              ></v-text-field>
              <v-checkbox
                v-model="currentItem.completed"
                :label="`Completed: ${currentItem.completed.toString()}`"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" lg="12">
              <v-text-field
                label="Description"
                v-model="currentItem.description"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="editDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="modify">
            Modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Web3 from 'web3'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'CoinsaleView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'seq', value: 'seq', width: '7%' },
        { text: 'nickname', value: 'nickname', width: '10%' },
        { text: 'description', value: 'description', width: '10%' },
        { text: 'deposit', value: 'deposit', width: '10%' },
        { text: 'depositDate', value: 'depositDate', width: '10%' },
        { text: 'salePrice', value: 'salePrice', width: '10%' },
        { text: 'metaq', value: 'metaq', width: '10%' },
        { text: 'completed', value: 'completed', width: '10%' },
        { text: 'lockup', value: 'lockup', width: '10%' },
        { text: '', value: 'editItem', width: '34px' },
        { text: '', value: 'deleteItem', width: '34px' },
      ],
      totalCount: 0,
      displist: [],
      query: '',
      sort: '',
      seq: 0,
      menu1: null,
      currentItem: {
        nickname: '',
        email: '',
        phone: '',
        wallet: '',
        description: '',
        deposit: '',
        depositDate: new Date().toISOString().substr(0, 10),
        salePrice: '',
        metaq: '',
        completed: false,
        lockup: false,
      },
      editDialog: false,
      registerDialog: false,
      totalAmount: '',
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    fromDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    toDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    registerDialog() {
      this.currentItem.nickname = ''
      this.currentItem.email = ''
      this.currentItem.phone = ''
      this.currentItem.wallet = ''
      this.currentItem.description = ''
      this.currentItem.deposit = ''
      this.currentItem.depositDate = new Date().toISOString().substr(0, 10)
      this.currentItem.salePrice = ''
      this.currentItem.metaq = ''
      this.currentItem.completed = false
      this.currentItem.lockup = false
    },
  },
  methods: {
    setDepositeDate(v) {
      this.currentItem.depositDate = v
      this.menu1 = false
      this.$refs.menu1.save(v)
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/metaplanet_coinsale/list', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((result) => {
          if (result.status == 200) {
            this.displist = result.data.list.map((item) => {
              item.depositDate = item.depositDate.substr(0, 10)
              return item
            })
            this.totalCount = result.data.totalCount
            this.totalAmount = result.data.totalAmount
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    deleteItem(item) {
      if (confirm(`Do you want to delete ${item.seq}?`)) {
        axiosInstance
          .delete(`/metaplanet_coinsale/${item.seq}`)
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async addCoinsale() {
      if (Web3.utils.isAddress(this.currentItem.wallet.trim()) == false) {
        alert(`invalid address : ${this.currentItem.wallet}`)
        return
      }

      await axiosInstance.post('/metaplanet_coinsale/add', {
        nickname: this.currentItem.nickname,
        email: this.currentItem.email,
        phone: this.currentItem.phone,
        wallet: this.currentItem.wallet,
        description: this.currentItem.description,
        deposit: this.currentItem.deposit,
        depositDate: this.currentItem.depositDate,
        salePrice: this.currentItem.salePrice,
        metaq: this.currentItem.metaq,
        completed: this.currentItem.completed.toString(),
        lockup: this.currentItem.lockup.toString(),
      })
      this.getList()
      this.registerDialog = false
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    editItem(item) {
      this.seq = item.seq
      this.currentItem.nickname = item.nickname
      this.currentItem.email = item.email
      this.currentItem.phone = item.phone
      this.currentItem.wallet = item.wallet
      this.currentItem.description = item.description
      this.currentItem.deposit = item.deposit
      this.currentItem.depositDate = item.depositDate
      this.currentItem.salePrice = item.salePrice
      this.currentItem.metaq = item.metaq
      this.currentItem.completed = item.completed == 'true' ? true : false
      this.currentItem.lockup = item.lockup == 'true' ? true : false
      this.editDialog = true
    },
    modify() {
      axiosInstance
        .post('/metaplanet_coinsale/modify', {
          seq: this.seq,
          nickname: this.currentItem.nickname,
          email: this.currentItem.email,
          phone: this.currentItem.phone,
          wallet: this.currentItem.wallet,
          description: this.currentItem.description,
          deposit: this.currentItem.deposit,
          depositDate: this.currentItem.depositDate,
          salePrice: this.currentItem.salePrice,
          metaq: this.currentItem.metaq,
          completed: this.currentItem.completed.toString(),
          lockup: this.currentItem.lockup.toString(),
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to modify.')
          }
        })
        .catch(() => {
          alert('Failed to modify.')
        })
      this.editDialog = false
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' +
        'address,metaq,description,type,allowdate,regdate,receiptdate\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/metaplanet_coinsale/list',
            {
              offset: skip,
              limit: 1000,
              query: this.query,
              sort: this.sort,
              fromDate: this.fromDate,
              toDate: this.toDate,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.wallet},${item.metaq},"${
                item.description
              }",${item.type},${item.allowDate.substring(
                0,
                19
              )},${item.regDate.substring(0, 19)},${item.receiptDate.substring(
                0,
                19
              )}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'presale.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  async beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}

.enabled-check div.v-messages.theme--light {
  display: none;
}

.enabled-check div.v-messages.theme--dark {
  display: none;
}

.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 12px;
}
</style>
