<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          Total: {{ Number(totalCount).toLocaleString() }}&nbsp;/&nbsp;{{
            Number(totalAmount).toLocaleString()
          }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <p
              class="hover_address"
              v-bind:style="
                isBlacklisted(item.address)
                  ? 'color:#d32f2f'
                  : isDuplicated(item.address)
                  ? 'color:#f9a825'
                  : ''
              "
            >
              {{ item.name.length > 0 ? item.name : item.address }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="setAddress(item.address)" class="link">
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
              <v-btn @click="query = item.address" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.amountf`]="{ item }">
          <div class="hover_area">
            <p
              v-bind:style="
                item.amountf > 3000
                  ? item.amountf > 5000
                    ? item.amountf > 10000
                      ? 'color:#d32f2f'
                      : 'color:#f9a825'
                    : 'color:#4caf50'
                  : ''
              "
            >
              {{ item.amountf.toLocaleString() }}
            </p>
            <div class="hover_inner">
              <v-btn
                @click="
                  item.amountf > 10000
                    ? setQuery('10000')
                    : item.amountf > 5000
                    ? setQuery('5000')
                    : setQuery('3000')
                "
                class="link"
              >
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-show="address.length > 0" class="mt-12 ml-1 mr-1">
      <!-- 같은 주소를 사용한 사용자 목록 -->
      <ERC20WithdrawAddress
        :address="address"
        :withdrawStatus="withdrawStatus"
      ></ERC20WithdrawAddress>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ERC20WithdrawAddress from '@/components/ERC20WithdrawAddress/ERC20WithdrawAddress.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'ERC20WithdrawRefusalView',
  components: {
    ERC20WithdrawAddress,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Issuer', value: 'issuer', width: '7%' },
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Amount', value: 'amountf', width: '8%' },
        {
          text: 'Rejection Reason',
          value: 'withdrawFailMessage',
          sortable: false,
        },
        {
          text: 'Request Date',
          value: 'registerDatetime',
          width: '12%',
        },
      ],
      totalCount: 0,
      totalAmount: 0,
      listInfo: [],
      displist: [],
      blacklist: [],
      query: '',
      sort: '',
      address: '',
      status: '3',
      withdrawStatus: '1',
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.address = ''
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)

      if (this.query.length == 0) {
        this.address = ''
      }
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/withdraw/list', {
          erc20Address: this.erc20Address,
          offset,
          limit,
          query: this.query,
          sort: this.sort,
          status: '3',
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.totalAmount = result.data.totalAmount
            this.listInfo = result.data.listInfo

            const list = result.data.list
            axiosInstance
              .post('/withdraw/listBlacklist', {
                erc20Address: this.erc20Address,
                offset: 0,
                limit: -1,
              })
              .then((r) => {
                this.blacklist = r.data.list.map((item) => item.address)
              })

            this.displist = list.map((item) => {
              item.createdAt = item.createdAt.substring(0, 19)
              item.updatedAt = item.updatedAt.substring(0, 19)
              item.registerDatetime = item.registerDatetime.substring(0, 16)
              const amountf = parseFloat(item.amountf)
              if (amountf > 0)
                item.amountf = Math.round(amountf * 10000) / 10000
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    isDuplicated(address) {
      const result = this.listInfo.find((item) => item.address == address)
      return result ? true : false
    },
    isBlacklisted(address) {
      return this.blacklist.find((item) => item == address) == undefined
        ? false
        : true
    },
    check(item, checked) {
      axiosInstance
        .post('/withdraw/setEnabled', {
          erc20Address: this.erc20Address,
          id: item.id,
          enabled: checked,
        })
        .then(() => {})
    },
    setQuery(query) {
      this.address = ''
      this.query = query
    },
    setAddress(address) {
      this.address = address
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData = '\ufeff' + 'address,amountf,registerDatetime\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post('/withdraw/list', {
            erc20Address: this.erc20Address,
            offset: skip,
            limit: 1000,
            query: this.query,
            amount: 0,
            status: '3',
          })
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              const amountf = parseFloat(item.amountf)
              if (amountf > 0)
                item.amountf = Math.round(amountf * 10000) / 10000

              processData += `${item.address},${item.amountf},${item.registerDatetime}\n`

              this.progressValue = Math.round((100 * skip) / totalCount)
            }
          } else {
            return
          }
          skip += data.list.length
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'withdraw_refusal.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
