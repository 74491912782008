import { render, staticRenderFns } from "./ERC1155HolderView.vue?vue&type=template&id=545791ac&scoped=true"
import script from "./ERC1155HolderView.vue?vue&type=script&lang=js"
export * from "./ERC1155HolderView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545791ac",
  null
  
)

export default component.exports