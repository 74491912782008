<template>
  <div>
    <v-row>
      <v-btn class="mr-2" color="secondary" @click="close">
        <v-icon>mdi-arrow-left-thin</v-icon>
      </v-btn>
      <v-btn v-if="stage == 3" class="mr-2" color="primary" @click="addNetwork">
        Save
      </v-btn>
      <v-btn
        v-if="stage == 2"
        class="mr-2"
        color="primary"
        @click="modifyNetwork"
      >
        Modify
      </v-btn>
      <v-btn
        v-if="stage == 2"
        class="mr-2"
        color="secondary"
        @click="deleteNetwork"
      >
        Delete
      </v-btn>
    </v-row>
    <v-row cols="12" class="mt-10">
      <v-col>
        <v-text-field
          label="ChainID*"
          v-model="chainID"
          type="number"
          required
        ></v-text-field>
        <v-text-field
          label="Address*"
          v-model="address"
          required
        ></v-text-field>
        <v-select :items="this.tokenTypeList" v-model="tokenType"></v-select>
        <v-text-field
          label="InitBlockNumber*"
          v-model="initBlockNum"
          type="number"
          required
        ></v-text-field>
        <v-row cols="6">
          <v-text-field
            label="Msg Telegram Token"
            v-model="msg_telegram_token"
            class="ma-2"
          ></v-text-field>
          <v-text-field
            label="Msg Telegram ChatId"
            v-model="msg_telegram_chatId"
            type="number"
            class="ma-2"
          ></v-text-field>
        </v-row>
        <v-row cols="6">
          <v-text-field
            label="ErrMsg Telegram Token"
            v-model="errmsg_telegram_token"
            class="ma-2"
          ></v-text-field>
          <v-text-field
            label="ErrMsg Telegram ChatId"
            v-model="errmsg_telegram_chatId"
            type="number"
            class="ma-2"
          ></v-text-field>
        </v-row>
      </v-col>
      <v-col>
        <v-text-field
          label="Collecting Wallet"
          v-model="flushAddress"
        ></v-text-field>
        <v-text-field label="SKYPlay DB" v-model="skyplayDB"></v-text-field>
        <v-checkbox
          @change="check('deposit', $event)"
          color="icon"
          label="Deposit"
          v-model="deposit"
        />
        <v-checkbox
          @change="check('enabled', $event)"
          color="icon"
          label="Enabled"
          v-model="enabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <pre>
ChainID :               1(Ethereum), 56(BSC), Polygon(137), 80001(Polygon Testnet)
Address :               smart contract address
Block Explorer URL:
InitBlockNumber:        first block number
TokenType:              contract type (erc20, erc721, erc1155)

Msg Telegram Token:     telegram Bot Token (transaction message)
Msg Telegram ChatId:    telegram Chat Id
ErrMsg Telegram Token:  telegram Bot Token (error message)
ErrMsg Telegram ChatId: telegram Chat Id

SKYPlay DB:             account DB
Deposit:                enable Deposit
Enabled:                enable Scanning

</pre
      >
    </v-row>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import config from '@/config'

export default {
  name: 'NetworkEdit',
  components: {
    ConfirmDlg,
  },
  props: {
    item: Object,
    stage: Number,
  },
  data() {
    return {
      chainID: 56,
      address: '',
      tokenType: 'erc20',
      initBlockNum: '',
      flushAddress: '',
      errmsg_telegram_chatId: null,
      errmsg_telegram_token: '',
      msg_telegram_chatId: null,
      msg_telegram_token: '',
      tusServer: config.defaultUploadServer,
      skyplayDB: '',
      deposit: false,
      enabled: false,
      tokenTypeList: ['erc20', 'erc721', 'erc1155'],
      uploadList: [],
    }
  },
  computed: {
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    item() {
      if (this.item) {
        this.chainID = this.item.chainID
        this.address = this.item.address
        this.tokenType = this.item.tokenType
        this.initBlockNum = this.item.initBlockNum
        this.flushAddress = this.item.flushAddress
        this.errmsg_telegram_chatId = this.item.errmsg_telegram_chatId
        this.errmsg_telegram_token = this.item.errmsg_telegram_token
        this.msg_telegram_chatId = this.item.msg_telegram_chatId
        this.msg_telegram_token = this.item.msg_telegram_token
        this.tusServer = this.item.tusServer
        this.skyplayDB = this.item.skyplayDB
        this.deposit = JSON.parse(this.item.deposit)
        this.enabled = JSON.parse(this.item.enabled)
      }
    },
  },
  methods: {
    async addNetwork() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '추가하겠습니까?'
      )
      if (result) {
        axiosInstance
          .post('/settings', {
            chainID: this.chainID,
            address: this.address,
            tokenType: this.tokenType,
            initBlockNum: this.initBlockNum,
            flushAddress: this.flushAddress,
            errmsg_telegram_chatId: this.errmsg_telegram_chatId,
            errmsg_telegram_token: this.errmsg_telegram_token,
            msg_telegram_chatId: this.msg_telegram_chatId,
            msg_telegram_token: this.msg_telegram_token,
            tusServer: this.tusServer,
            skyplayDB: this.skyplayDB,
            deposit: this.deposit.toString(),
            enabled: this.enabled.toString(),
          })
          .then((r) => {
            if (r.status == 201) {
              this.close()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async modifyNetwork() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '수정하겠습니까?'
      )
      if (result) {
        axiosInstance
          .put(`/settings/${this.address}`, {
            chainID: this.chainID,
            tokenType: this.tokenType,
            initBlockNum: this.initBlockNum,
            flushAddress: this.flushAddress,
            errmsg_telegram_chatId: this.errmsg_telegram_chatId,
            errmsg_telegram_token: this.errmsg_telegram_token,
            msg_telegram_chatId: this.msg_telegram_chatId,
            msg_telegram_token: this.msg_telegram_token,
            tusServer: this.tusServer,
            skyplayDB: this.skyplayDB,
            deposit: this.deposit.toString(),
            enabled: this.enabled.toString(),
          })
          .then((r) => {
            if (r.status == 200) {
              this.close()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    check: function (command, checked) {
      this[`${command}`] = checked
      // console.log(this.deposit, this.enabled)
    },
    async deleteNetwork() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        '<strong>' + this.address + '</strong> ' + '삭제하겠습니까?'
      )
      if (result) {
        axiosInstance
          .delete(`/settings/${this.address}`)
          .then((r) => {
            if (r.status == 200) {
              this.close()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    close() {
      this.$emit('close')
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
</style>
