<template>
  <div>
    <div v-if="stage == 1">
      <v-row class="search_row">
        <v-col cols="12" lg="6">
          <v-text-field
            clearable
            v-model="keyword"
            label="Search"
            @click:clear="clearItem"
          />
        </v-col>
        <v-col cols="12" lg="2">
          <div class="btn-row">
            <v-btn @click="addNetwork">Add</v-btn>
            <v-btn :disabled="progressValue != 100" @click="download">
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <progress-bar
              v-show="progressValue != 100"
              :options="progressOptions"
              :value="progressValue"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
          item-key="id"
          class="elevation-1 mt-4 outlined"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item._id">
                <td>
                  <div class="hover_area">
                    <div class="hover_address">{{ item.address }}</div>
                    <div class="hover_inner">
                      <v-btn
                        @click="addrCopy(item.address)"
                        class="copy"
                        ref="${item.address}"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn
                        @click="
                          networkExplorer(
                            item.chainID,
                            `/token/${item.address}`,
                            item.tokenType
                          )
                        "
                        class="link"
                      >
                        <v-icon>mdi-link</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </td>
                <td>{{ item.chainID }}</td>
                <td>{{ item.tokenType }}</td>
                <td>{{ item.tokenName }}</td>
                <td>{{ item.tokenSymbol }}</td>
                <td>{{ item.lastBlockNumber }}</td>
                <td>{{ item.initBlockNum }}</td>
                <td>{{ getProcessStatus(item) }}</td>
                <td>
                  <v-checkbox
                    class="enabled-check"
                    :input-value="JSON.parse(item.enabled)"
                    color="icon"
                    disabled
                  />
                </td>
                <td>
                  <button v-on:click="editItem(item)">
                    <v-icon>mdi-database-edit-outline</v-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <pre>
ChainID :             1(Ethereum), 56(BSC), Polygon(137), 80001(Polygon Testnet)
Address :             smart contract address
TokenType:            contract type (erc20, erc721, erc1155)
BlockNumber:          last block number
Initial Block Number: first block number
Process:
                       1 progress_transaction
                       2 progress_volume
                       3 progress_deposit
                       4 progress_skyplay_wallet
                       5 progress_holder_balance_check
                       6 progress_flush_token

                       7 progress_withdraw
                       8 progress_withdraw_status
                       9 progress_airdrop
                      10 progress_airdrop_status
                      11 progress_minting
                      12 progress_minting_status

                      13 initialized</pre
        >
      </v-row>
    </div>
    <div v-show="stage != 1">
      <NetworkEdit
        :item="currentItem"
        :stage="stage"
        @close="clearItem"
      ></NetworkEdit>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import NetworkEdit from '@/components/NetworkEdit/NetworkEdit.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'NetworkView',
  components: { NetworkEdit },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'ChainID', value: 'chainID', width: '10%' },
        { text: 'Token Type', value: 'tokenType', width: '10%' },
        { text: 'Name', value: 'tokenName', width: '10%' },
        { text: 'Symbol', value: 'tokenSymbol', width: '10%' },
        {
          text: 'Block Number',
          value: 'lastBlockNumber',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Initial Block Number',
          value: 'initBlockNum',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Process',
          value: 'processStatus',
          sortable: false,
        },
        { text: '', value: 'enabled', width: '34px' },
        { text: '', value: 'editItem', width: '34px' },
      ],
      totalCount: 0,
      list: [],
      keyword: '',
      sort: '',
      currentItem: {
        tokenType: 'erc20',
        deposit: 'false',
        enabled: 'false',
      },
      creating: false,
      stage: 1, // 1: default, 2: modify/delete, 3: new
      interval: null,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    keyword() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)

      if (this.keyword == null || this.keyword.length == 0) {
        this.clearItem()
      }
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList(page, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(page, per_page) {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (page == undefined) {
        page = this.options.page
      }
      if (per_page == undefined) {
        per_page = itemsPerPage
      }
      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }

      axiosInstance
        .get(`/settings?${parameters}`)
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.meta.total
            this.list = r.data.data
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async download() {
      let processData =
        '\ufeff' +
        'address,tokenType,lastBlockNumber,skyplayService,skyplayDB,deposit,flushAddress,msg_telegram_chatId,msg_telegram_token,errmsg_telegram_chatId,errmsg_telegram_token,enabled\n'

      try {
        let page = 1
        let loop_exit = false
        this.progressValue = 0

        do {
          let parameters = `page=${page}&per_page=10000`
          if (this?.keyword.length > 0) {
            parameters = parameters + `&keyword=${this.keyword}`
          }
          if (this?.sort.length > 0) {
            parameters = parameters + `&sort=${this.sort}`
          }

          const { data, status } = await axiosInstance.get(
            `/settings?${parameters}`
          )
          if (status != 200) {
            return
          }

          for (let item of data.data) {
            processData += `${item.address},${item.tokenType},${item.lastBlockNumber},`
            processData += `"${item.skyplayService}","${item.skyplayDB}",${item.deposit},${item.flushAddress},`
            processData += `"${item.msg_telegram_chatId}","${item.msg_telegram_token}","${item.errmsg_telegram_chatId}","${item.errmsg_telegram_token}",${item.enabled}\n`
          }

          if (data.meta.current_page == data.meta.last_page) {
            loop_exit = true
          }

          page = page + 1
          this.progressValue = Math.round(
            data.meta.current_page / data.meta.last_page
          )
        } while (loop_exit == false)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'network.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    clearItem() {
      this.currentItem = {
        tokenType: 'erc20',
        deposit: 'false',
        enabled: 'false',
      }
      this.stage = 1
      this.keyword = ''
    },
    addNetwork() {
      this.stage = 3
    },
    networkExplorer(chainID, path, tokenType) {
      scanExplorer(chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    editItem(value) {
      this.currentItem = value
      this.stage = 2
    },
    getProcessStatus(item) {
      let result = ''
      for (let i of item.processStatus.split(',')) {
        const arr = i.split(':')

        result += arr[1]
        if (result.length == 6 || result.length == 13) {
          result += ' '
        }
      }
      return result
    },
    updateProcessStatus() {
      // this.list.map()
      const { page, itemsPerPage } = this.options
      const per_page = itemsPerPage

      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }

      axiosInstance
        .get(`/settings?${parameters}`)
        .then((r) => {
          if (r.status == 200) {
            r.data.data.map((item) => {
              const r = this.list.find((el) => el.address == item.address)
              if (r) {
                r.processStatus = item.processStatus
              }
            })
            this.$forceUpdate()
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
  },
  beforeMount() {
    this.interval = setInterval(() => {
      this.updateProcessStatus()
    }, 1000)

    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
.enabled-check div.v-messages.theme--light {
  display: none;
}
.enabled-check div.v-messages.theme--dark {
  display: none;
}
.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 10px;
}
</style>
