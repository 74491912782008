<template>
  <v-app>
    <app-bar @toggle-drawer="showDrawer = !showDrawer">
      <template v-slot:walletConnect>
        <div v-if="isLoggined == false">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="bright--text"
                icon
                depressed
                @click="wallect_connect"
                v-bind="attrs"
                v-on="on"
              >
                <img
                  src="@/assets/wallet-connect-logo.svg"
                  alt="Icon"
                  width="32"
                  height="32"
                />
              </v-btn>
            </template>
            <span>wallet-connect</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:loginButton>
        <div v-if="isLoggined">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="bright--text mr-3"
                icon
                depressed
                @click="logout"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </template>
            <span>logout</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="bright--text mr-3"
                icon
                depressed
                @click="login"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-login</v-icon>
              </v-btn>
            </template>
            <span>login</span>
          </v-tooltip>
        </div>
      </template>
    </app-bar>

    <nav-menu
      v-on:drawerWatch="updateChildDrawer"
      :value="showDrawer"
      :menulist="menulist"
    />

    <v-main v-if="isLoggined">
      <div class="pa-5">
        <router-view />
      </div>
    </v-main>

    <v-dialog v-model="loginDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="pa-5">
          연결 중입니다.
          <br />
          로그인 메시지에 서명해주세요.
          <v-progress-linear
            indeterminate
            color="white"
            class="mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import AppBar from '@/components/Application/AppBar.vue'
import NavMenu from '@/components/Application/NavMenu.vue'

import { Buffer } from 'buffer'
import axiosInstance from '@/utils/axiosInstance'

// @ts-ignore
window.Buffer = Buffer

import { configureChains, createConfig } from '@wagmi/core'
import { bsc, mainnet } from '@wagmi/core/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
import { getAccount, signMessage, disconnect } from '@wagmi/core'

const projectId = '6d6a0f6dc07d6de8ae8f5c9b233e2029'

export default {
  name: 'App',

  components: { AppBar, NavMenu },

  data: () => ({
    showDrawer: true,
    chainId: '',
    loginDialog: false,
    jwt: null,
    menulist: [],
  }),
  watch: {
    showDrawer(/* oldValue, newValue */) {},
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    isLoggined() {
      return this.$store.getters['auth/isLoggined']
    },
    address() {
      return this.$store.getters['auth/address']
    },
  },
  methods: {
    updateChildDrawer(value) {
      this.showDrawer = value
    },
    logginCallback(status) {
      if (status) {
        try {
          axiosInstance
            .get(`/menu/list`)
            .then(({ data }) => {
              this.menulist = data.list.map((item) => item.route)
            })
            .catch(async (err) => {
              console.error(err)
              await this.logout()
            })
          this.$store.dispatch('auth/checkLoginStatus')
        } catch (error) {
          console.log(error)
        }
      }
    },
    async login() {
      await window.ethereum.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      })
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      const address = accounts[0]

      let response = await fetch('/api/auth/nonce/login?address=' + address)
      const json = await response.json()
      const message = json.nonce

      // Sign the message using MetaMask
      const signedMessage = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, address],
      })

      this.$store.dispatch('auth/login', {
        address,
        signature: signedMessage,
        callback: this.logginCallback,
      })
    },
    async logout() {
      this.$store.dispatch('auth/logout', {
        connector: this.connector,
      })

      try {
        await disconnect()
      } catch (error) {
        console.log(error)
      }
      window.location.reload()
    },
    async wallect_connect() {
      localStorage.clear()

      // 1. Define constants
      const chains = [bsc, mainnet]

      // 2. Configure wagmi client
      const { publicClient } = configureChains(chains, [
        w3mProvider({ projectId }),
      ])
      const wagmiConfig = createConfig({
        autoConnect: true,
        connectors: w3mConnectors({ chains, version: 1, projectId }),
        publicClient,
      })

      // 3. Create ethereum and modal clients
      const ethereumClient = new EthereumClient(wagmiConfig, chains)
      const web3Modal = new Web3Modal(
        {
          projectId,
          walletConnectVersion: 2,
          // walletImages: {
          //   safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
          // },
        },
        ethereumClient
      )

      if (getAccount().status != 'connected') {
        await web3Modal.openModal()
        let unsubscribe = undefined
        try {
          await new Promise((resolve, reject) => {
            unsubscribe = web3Modal.subscribeModal((newState) => {
              if (!newState.open) {
                resolve()
              } else {
                reject()
              }
            })
          })
          unsubscribe()
        } catch (error) {
          // console.log(error)
          return
        }

        if (getAccount().connector == undefined) {
          await disconnect()
          return
        }
      }

      const address = getAccount().address
      const message = `I am using the ${address} to access the website.`
      try {
        const signedMessage = await signMessage({ message })
        this.$store.dispatch('auth/login', {
          address,
          message,
          signature: signedMessage,
          callback: this.logginCallback,
        })
      } catch (error) {
        await disconnect()
        alert('The connection has been cancelled')
      }
    },
  },
  async created() {
    const vuetifyTheme = localStorage.getItem('vuetifyTheme')
      ? JSON.parse(localStorage.getItem('vuetifyTheme'))
      : null
    if (vuetifyTheme == null) {
      localStorage.setItem(
        'vuetifyTheme',
        JSON.stringify({ dark: this.$vuetify.theme.dark })
      )
    } else {
      this.$vuetify.theme.dark = vuetifyTheme.dark
    }

    this.$store.dispatch('auth/checkLoginStatus')
  },
  async mounted() {
    if (this.isLoggined) this.logginCallback(true)
  },
}
</script>

<style lang="scss">
.bounce-transition {
  &-enter-active,
  &-leave,
  &-leave-to {
    transition: transform 3s cubic-bezier(0.3 0.7, 1);
  }

  &-enter,
  &-leave-to {
    transform: translateY(100px);
  }
}
.walletconnect-modal__base {
  max-width: 300px !important;
}
.walletconnect-modal__mobile__toggle {
  width: 100% !important;
}
.walletconnect-qrcode__text {
  font-size: 14px !important;
}
.walletconnect-modal__base {
  box-shadow: 0 0 50px 5px rgb(0 0 0 / 90%) !important;
}
.v-data-table__wrapper {
  overflow-x: hidden !important;
}
.v-application .v-data-table {
  &.outlined {
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.2);
  }
  table {
    // word-break: break-word;
    table-layout: fixed;
    td,
    th {
      text-align: left !important;
      padding: 0 8px !important;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    th {
      &.sortable .v-data-table-header__icon {
        position: absolute;
      }
    }
    td {
      text-align: left !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .v-input--checkbox .v-input__slot {
      justify-content: space-around;
      .v-input--selection-controls__input {
        margin: 0;
      }
    }
    .left {
      text-align: left !important;
    }
    .right {
      text-align: right !important;
    }
  }
}

.v-input {
  .theme--dark.v-label {
    color: #fff !important;
  }
}
.primary--text .v-input__control > .v-input__slot:after {
  border-color: rgba(255, 255, 255, 0.5) !important;
}
.v-application .primary {
  color: #fff;
}
.v-card {
  .col {
    padding: 0 12px !important;
    &:first-child {
      padding-top: 12px !important;
    }
  }
  .v-card__actions > .v-btn.v-btn {
    min-width: 85px;
  }
}
.theme--dark .v-overlay__scrim {
  background-color: #fff !important;
}
.theme--light .v-overlay__scrim {
  background-color: #000 !important;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card > .v-card__subtitle {
  color: rgba(0, 0, 0, 0.9) !important;
}
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  color: rgba(255, 255, 255, 0.9) !important;
}
@media (max-width: 1263px) {
  h1 {
    font-size: 24px;
  }
}
.v-data-table td .v-btn.v-btn--has-bg.link-btn {
  background: none;
  box-shadow: unset;
}
.hover_area {
  height: inherit;
  position: relative;
  line-height: 48px;
  max-width: 99%;
  .hover_address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
  }
  .hover_inner {
    transition: 0.2s linear;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 48px;
    text-align: center;
    button {
      transition: 0.3s;
      box-shadow: none;
      min-width: auto !important;
      padding: 0 !important;
      width: 34px !important;
      height: 34px !important;
      margin: 0;
      border-radius: 50%;
      i {
        font-size: 15px;
      }
      &.edit {
        border: 1px solid #00bdaa;
        background: #fff !important;
        color: #00bdaa;
        &:hover {
          background: #00bdaa !important;
          color: #fff !important;
        }
      }
      &.copy {
        border: 1px solid #400082;
        background: #fff !important;
        color: #400082;
        &:hover {
          background: #400082 !important;
          color: #fff !important;
        }
      }
      &.link {
        border: 1px solid #fe346e;
        background: #fff !important;
        color: #fe346e;
        &:hover {
          background: #fe346e !important;
          color: #fff !important;
        }
      }
    }
  }
  &:hover .hover_inner {
    opacity: 1;
    button {
      margin: 0 2px;
    }
  }
}
.link-btn {
  border: 1px solid #fe346e;
  background: #fff !important;
  color: #fe346e !important;
  width: 34px !important;
  height: 34px !important;
  margin: 0;
  border-radius: 50%;
  min-width: auto !important;
  transition: 0.3s;
}
.link-btn i {
  font-size: 15px !important;
}
.link-btn:hover {
  background: #fe346e !important;
  color: #fff !important;
}
.v-select__selections input[readonly] {
  line-height: 0;
  font-size: 0;
  padding: 0;
}
.v-data-table tr.select-tr {
  background: #eee !important;
}
.theme--dark.v-data-table tr.select-tr {
  background: #616161 !important;
}
.v-input .theme--dark.v-label {
  color: lightblue !important;
}
.search_row .v-text-field__details {
  display: none !important;
}
.btn-row {
  display: flex;
  gap: 5px;
  // margin: 10px 0;
}
.btn-row > button {
  margin: 10px 0;
}
.theme--light .v-menu__content {
  background-color: white;
}
</style>
