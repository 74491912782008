<template>
  <div>
    <div v-show="stage == 1">
      <v-row>
        <v-col cols="12" lg="2">
          <div class="btn-row">
            <v-btn @click="doAdd()">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
          class="elevation-1 mt-4 outlined"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.seq">
                <td>{{ item.seq }}</td>
                <td class="left">{{ item.subject }}</td>
                <td class="left">{{ item.url }}</td>
                <td>{{ item.language }}</td>
                <td>{{ item.dateCreated }}</td>
                <td>
                  <button v-on:click="doContentGo(item)">
                    <v-icon>mdi-link</v-icon>
                  </button>
                </td>
                <td>
                  <button v-on:click="editItem(item)">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </button>
                </td>
                <td>
                  <button v-on:click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <div :style="{ width: stage == 1 ? '0%' : '100%' }">
      <v-row>
        <v-col cols="12" lg="2">
          <v-btn
            v-if="stage != 1"
            class="mr-2"
            color="secondary"
            @click="stage = 1"
          >
            <v-icon>mdi-arrow-left-thin</v-icon>
          </v-btn>
          <v-btn
            v-if="stage == 2"
            class="mr-2"
            color="primary"
            @click="addItem"
          >
            Save
          </v-btn>
          <v-btn
            v-if="stage == 3"
            class="mr-2"
            color="primary"
            @click="modifyItem"
          >
            Modify
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="stage != 1">
        <v-col cols="12" lg="6">
          <v-text-field
            label="Subject*"
            v-model="subject"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" lg="4">
          <v-text-field label="URL*" v-model="url" required></v-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <v-radio-group v-model="language" row>
            <v-radio label="English" value="en" color="icon"></v-radio>
            <v-radio label="Korean" value="ko" color="icon"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <SmartEditor
            :editorProp="oEditors"
            :isModify="modify"
            :contentsProp="contentEdit"
            elPlaceHolder="elPlaceHolder"
          ></SmartEditor>
        </v-col>
      </v-row>
    </div>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import SmartEditor from '@/components/SmartEditor/SmartEditor.vue'
import axiosInstance from '@/utils/axiosInstance'

const SKYPLAY_HOME = 'https://skyplayinc.com'
export default {
  name: 'PressReleaseView',
  components: {
    ConfirmDlg,
    SmartEditor,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Seq', value: 'seq', sortable: false, width: '7%' },
        { text: 'Subject', value: 'subject', sortable: false },
        { text: 'URL', value: 'url', sortable: false },
        { text: 'Language', value: 'language', sortable: false, width: '5%' },
        {
          text: 'Created Date',
          value: 'dateCreated',
          sortable: false,
          width: '13%',
        },
        { text: '', value: 'link', sortable: false, width: '34px' },
        { text: '', value: 'editItem', width: '34px' },
        { text: '', value: 'deleteItem', width: '34px' },
      ],
      totalCount: 0,
      list: [],
      seq: 0,
      subject: '',
      url: '',
      language: 'ko',
      value: '',
      modify: false,
      oEditors: [],
      contentEdit: '',
      seqList: [],
      stage: 1,
    }
  },
  computed: {
    strSeqList() {
      return String(
        this.list
          .filter((item) => item.language.match('ko'))
          .map((item) => item.seq)
      )
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    contentEdit() {
      return this.contentEdit
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/board/list', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.totalCount
            this.list = r.data.boardList
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    contentInit() {
      this.subject = ''
      this.language = 'ko'
      this.url = ''
      this.modify = false
      this.contentEdit = ''
    },
    doAdd() {
      this.contentInit()

      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])
      this.stage = 2
    },
    doContentGo(item) {
      let url = `${SKYPLAY_HOME}/board/listView.html?num=${item.seq}&list=${this.strSeqList}`
      if (item.url.length > 0) {
        url = item.url
      }
      window.open(url, '_blank')
    },
    async addItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.subject == '') {
        alert('Please enter a title.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to add?'
        )
        if (result) {
          axiosInstance
            .post('/board/add', {
              subject: this.subject,
              url: this.url,
              language: this.language,
              contents: this.language == 'ko' ? content : '',
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
    editItem(item) {
      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])

      this.seq = item.seq
      this.subject = item.subject
      this.language = item.language
      this.url = item.url

      axiosInstance
        .get(`/board/contents/${item.seq}`)
        .then((r) => {
          this.contentEdit = r.data.contents
          this.modify = true
          this.stage = 3
        })
        .catch((err) => console.error(err))
    },
    async deleteItem(item) {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to delete?'
      )
      if (result) {
        axiosInstance
          .delete(`/board/${item.seq}`)
          .then((r) => {
            if (r.status == 200) {
              this.contentInit()
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async modifyItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.subject == '') {
        alert('Please enter a title.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to modify?'
        )
        if (result) {
          axiosInstance
            .post('/board/modify', {
              seq: this.seq,
              subject: this.subject,
              url: this.url,
              contents: this.language == 'ko' ? content : '',
              language: this.language,
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
