var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displist && _vm.displist.length > 0),expression:"displist && displist.length > 0"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"address",staticStyle:{"color":"#f9a825"}},[_vm._v(" 출금주소 중복사용 - "+_vm._s(_vm.totalCount)+" ")])])],1),_c('v-row',[_c('v-data-table',{staticClass:"elevation-1 mt-4 outlined",attrs:{"headers":_vm.headers,"items":_vm.displist,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"server-items-length":_vm.totalCount,"options":_vm.options,"footer-props":{
        'items-per-page-options': [5, 10, 20, 50, 100],
      },"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.memberId`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('p',{staticClass:"hover_address",style:(_vm.isBlacklisted(item.memberId) ? 'color:#d32f2f' : '')},[_vm._v(" "+_vm._s(item.memberId)+" ")]),_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.memberId}",staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.memberId)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{staticClass:"link",on:{"click":function($event){return _vm.addBlacklistReason(item.memberId)}}},[_c('v-icon',[_vm._v("mdi-block-helper")])],1)],1)])]}},{key:`item.channelEmail`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('div',{staticClass:"hover_address"},[_vm._v(_vm._s(item.channelEmail))]),(item.channelEmail && item.channelEmail.length > 0)?_c('div',{staticClass:"hover_inner"},[_c('v-btn',{ref:"${item.channelEmail}",staticClass:"copy",on:{"click":function($event){return _vm.addrCopy(item.channelEmail)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1):_vm._e()])]}},{key:`item.amountf`,fn:function({ item }){return [_c('div',{staticClass:"hover_area"},[_c('div',{style:(item.amountf > 3000
                ? item.amountf > 5000
                  ? item.amountf > 10000
                    ? 'color:#d32f2f'
                    : 'color:#f9a825'
                  : 'color:#4caf50'
                : '')},[_vm._v(" "+_vm._s(item.amountf.toLocaleString())+" ")])])]}}],null,true)})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary"},[_c('span',{staticClass:"text-h7"},[_vm._v("blacklist 등록 사유")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"","required":""},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('v-card-actions',{staticClass:"py-4 pt-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"text":"","outlined":"","w100":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" 취소 ")]),_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"primary"},on:{"click":_vm.registerBlacklist}},[_vm._v(" 등록 ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }