<template>
  <div>
    <div class="util-row">
      <v-text-field clearable v-model="query" label="Search" class="mr-4" />
      <div class="amount">
        <div class="selected">{{ enabledCount.toLocaleString() }} 개</div>
        <div class="ready">
          {{ Number(enabledAmount).toLocaleString() }} SKP
        </div>
        <div class="request">
          전체/검색 : {{ Number(totalAmount).toLocaleString() }} SKP
        </div>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="displist"
      :items-per-page="itemsPerPage"
      :page="page"
      :server-items-length="totalCount"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100],
      }"
      item-key="id"
      class="elevation-1 mt-4 outlined"
    >
      <template v-slot:[`item.address`]="{ item }">
        <div class="hover_area">
          <p
            class="hover_address"
            v-bind:style="isDuplicated(item.address) ? 'color:#f9a825' : ''"
          >
            {{ item.address }}
          </p>
          <div class="hover_inner">
            <v-btn
              @click="addrCopy(item.address)"
              class="copy"
              ref="${item.address}"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <v-btn
              @click="networkExplorer(`address/${item.address}`, 'erc20')"
              class="link"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
            <v-btn @click="addBlacklistReason(item.address)" class="link">
              <v-icon>mdi-block-helper</v-icon>
            </v-btn>
            <v-btn @click="setAddress(item.address)" class="link">
              <v-icon>mdi-magnify-expand</v-icon>
            </v-btn>
            <v-btn @click="query = item.address" class="copy">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.amountf`]="{ item }">
        <div class="hover_area">
          <p
            v-bind:style="
              item.amountf > 3000
                ? item.amountf > 5000
                  ? item.amountf > 10000
                    ? 'color:#d32f2f'
                    : 'color:#f9a825'
                  : 'color:#4caf50'
                : ''
            "
          >
            {{ item.amountf.toLocaleString() }}
          </p>
        </div>
      </template>
      <template v-slot:[`item.transactionHash`]="{ item }">
        <div class="hover_area">
          <div class="hover_address">{{ item.transactionHash }}</div>
          <div
            v-if="item.trasactionHash && item.transactionHash.length > 0"
            class="hover_inner"
          >
            <v-btn
              @click="addrCopy(item.transactionHash)"
              class="copy"
              ref="${item.transactionHash}"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <v-btn
              @click="networkExplorer(`tx/${item.transactionHash}`, 'erc20')"
              class="link"
            >
              <v-icon>mdi-link</v-icon>
            </v-btn>
            <v-btn @click="query = item.transactionHash" class="copy">
              <v-icon>mdi-filter-outline</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:[`item.enabled`]="{ item }">
        <v-checkbox
          :input-value="item.enabled"
          color="icon"
          @change="check(item, $event)"
          :disabled="item.transactionHash != ''"
        ></v-checkbox>
      </template>
    </v-data-table>
    <div class="btn-row">
      <v-btn :disabled="disableDownload" @click="download">
        <v-icon>mdi-download</v-icon>
      </v-btn>
      <v-btn :disabled="disableWithdraw" @click="startWithdraw">Start</v-btn>
      <v-btn :disabled="!disableWithdraw" @click="stopWithdraw">Stop</v-btn>
      <v-btn @click="setEnabledAll">Select All</v-btn>
    </div>
    <div v-show="address.length > 0">
      <!-- 같은 주소를 사용한 사용자 목록 -->
      <ERC20WithdrawAddress
        :address="address"
        :withdrawStatus="withdrawStatus"
      ></ERC20WithdrawAddress>
    </div>
    <confirm-dlg ref="confirm"></confirm-dlg>
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">blacklist 등록 사유</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="reason" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="closeDialog"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="registerBlacklist"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import ERC20WithdrawAddress from '@/components/ERC20WithdrawAddress/ERC20WithdrawAddress.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'TestView',
  components: {
    ConfirmDlg,
    ERC20WithdrawAddress,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Amount', value: 'amountf', width: '8%' },
        { text: 'Transaction Hash', value: 'transactionHash' },
        {
          text: 'Request Date',
          value: 'registerDatetime',
          width: '12%',
        },
        { text: '', value: 'enabled', sortable: false, width: '34px' },
      ],
      displist: [],
      query: '',
      sort: '',
      address: '',
      status: '0',
      disableWithdraw: false,
      interval: null,
      enabled: false,
      transactionId: null,
      reason: '',
      dialog: false,
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      withdrawStatus: '0',
    }
  },
  computed: {
    totalCount() {
      return this.$store.getters['skpWithdraw/totalCount']
    },
    totalAmount() {
      return this.$store.getters['skpWithdraw/totalAmount']
    },
    enabledCount() {
      return this.$store.getters['skpWithdraw/enabledCount']
    },
    enabledAmount() {
      return this.$store.getters['skpWithdraw/enabledAmount']
    },
    listInfo() {
      return this.$store.getters['skpWithdraw/listInfo']
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.address = ''
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/withdraw/list2', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
          status: '0',
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.totalAmount = result.data.totalAmount
            this.listInfo = result.data.listInfo

            const list = result.data.list
            this.displist = list.map((item) => {
              axiosInstance
                .get(`/wallet/getAddress/${item.memberId}`)
                .then((r) => {
                  if (r.status == 200) {
                    axiosInstance
                      .get(
                        `wallet/transaction/${r.data.address}?offset=0&limit=-1`
                      )
                      .then((result) => {
                        if (result.status == 200) {
                          if (result.data.txItems.length > 0) {
                            this.$forceUpdate()
                          }
                        }
                      })
                  }
                })

              axiosInstance
                .post('/withdraw/list', {
                  limit: -1,
                  query: item.memberId,
                  status: '1',
                })
                .then((r2) => {
                  if (r2.status == 200) {
                    this.$forceUpdate()
                  }
                })

              item.createdAt = item.createdAt.substring(0, 19)
              item.updatedAt = item.updatedAt.substring(0, 19)
              item.registerDatetime = item.registerDatetime.substring(0, 16)
              const amountf = parseFloat(item.amountf)
              if (amountf > 0)
                item.amountf = Math.round(amountf * 10000) / 10000
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    isDuplicated(address) {
      const result = this.listInfo.find((item) => item.address == address)
      return result ? true : false
    },
    check(item, checked) {
      axiosInstance
        .post('/withdraw/setEnabled', { id: item.id, enabled: checked })
        .then(() => {
          this.getList()
        })
    },
    setEnabledAll() {
      this.enabled = !this.enabled
      axiosInstance
        .post('/withdraw/setEnabledAll', {
          enabled: this.enabled,
        })
        .then(() => {
          this.getList()
        })
    },
    async startWithdraw() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to proceed with the withdrawal?'
      )
      if (result) {
        axiosInstance
          .post('/withdraw/setProcessStatus', {
            withdraw: 'start',
          })
          .then(() => {})
      }
    },
    stopWithdraw() {
      axiosInstance
        .post('/withdraw/setProcessStatus', {
          withdraw: 'stop',
        })
        .then(() => {})
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' +
        'address,amountf,transactionHash,status,updatedAt,createdAt,enabled\n'

      try {
        do {
          const { data, status } = await axiosInstance.post('/withdraw/list', {
            offset: skip,
            limit: 1000,
            query: this.query,
            amount: 0,
            status: '0',
          })
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.address},${item.amountf},${item.transactionHash},${item.status},${item.updatedAt},${item.createdAt},${item.enabled}\n`
            }
          } else {
            return
          }
          skip += data.list.length
        } while (skip < totalCount)

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'test.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    setQuery(query) {
      this.address = ''
      this.query = query
    },
    setAddress(address) {
      this.address = address
    },

    addBlacklistReason(address) {
      this.address = address
      this.reason = ''
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    registerBlacklist() {
      this.dialog = false
      axiosInstance
        .post('/withdraw/addBlacklist', {
          erc20Address: this.erc20Address,
          address: this.address,
          reason: this.reason,
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to add to the blacklist.')
          }
        })
        .catch(() => {
          alert('Failed to add to the blacklist.')
        })
    },
  },
  beforeMount() {
    this.interval = setInterval(() => {
      axiosInstance
        .get(`/withdraw/getProcessStatus?erc20Address=${this.erc20Address}`)
        .then(({ data, status }) => {
          if (status == 200) {
            if (data.withdraw == 'start') {
              this.disableWithdraw = true
            } else if (data.withdraw == 'stop') {
              this.disableWithdraw = false
            }
          }
        })
      // this.getList()
    }, 3000)
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style scoped>
.util-row {
  display: flex;
}
.util-row .v-banner {
  flex: 1;
}
.v-banner:v-deep .v-banner__wrapper {
  min-height: 36px;
  padding: 5px 8px;
}
.v-input.primary--text {
  color: #fff;
  caret-color: #fff !important;
}
.v-data-table__wrapper td {
  white-space: pre-wrap;
}
.amount {
  display: flex;
  gap: 5px;
  align-items: center;
  max-width: fit-content;
}
.amount .ready {
  background: #fff;
  border-radius: 15px;
  color: #000;
}

.amount .selected {
  background: #fff;
  border-radius: 15px;
  color: #000;
}
.amount p {
  padding: 7px 15px;
  margin-left: 10px;
}
.amount .request {
  background: #00ffda;
  border-radius: 15px;
  color: #000;
}
</style>
