<template>
  <div>
    <div>
      <v-form class="wallet_form">
        <v-sheet>
          <v-text-field v-model="description" label="지갑명*" required />
          <div class="owner-row-wrap">
            <v-row class="no-margin">
              <v-col cols="11">
                <v-text-field
                  value="지갑 생성자(기본 승인유저)"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="1" class="d-flex align-center"></v-col>
            </v-row>
            <v-row
              v-for="(item, index) in ownerList.slice(1)"
              :key="index + 1"
              class="no-margin"
            >
              <v-col cols="11">
                <v-select
                  :items="list"
                  :item-value="'address'"
                  v-model="ownerList[index + 1].address"
                  label="승인유저를 선택해주세요"
                  class="confirm-user-select"
                >
                  <template slot="item" slot-scope="data">
                    <span>
                      {{ data.item.description }} - {{ data.item.address }}
                    </span>
                  </template>
                  <template slot="selection" slot-scope="data">
                    <span>
                      {{ data.item.description }} - {{ data.item.address }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="1" class="d-flex align-center">
                <v-btn class="" @click="rowDelete(index)" v-if="ownerCnt">
                  삭제
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col>
              <v-btn @click="rowAdd">+ Add another owner</v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex">
            <v-col class="d-flex align-center" v-if="confirmCntTotal > 0">
              트랜잭션을 위한 최소 승인자 수: {{ confirmCntTotal }} 명 중
              <v-select
                outlined
                class="confirm_select no-margin"
                :items="confirmCnt"
                v-model="threshold"
              ></v-select>
              명
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn @click="createWallet(threshold, description)" x-large>
                생성
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-form>
    </div>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'

export default {
  name: 'CreateWalletView',
  components: {
    ConfirmDlg,
  },
  data() {
    return {
      description: '',
      threshold: '',
      ownerList: [
        { description: 'myWallet', address: '' },
        { description: '', address: '' },
        { description: '', address: '' },
      ],
      list: [],
    }
  },
  computed: {
    confirmCntTotal() {
      return this.ownerList.length
    },
    confirmCnt() {
      let opts = []
      for (let i = 1; i <= this.ownerList.length; i++) {
        opts.push({ text: i, value: i })
      }
      return opts
    },
    ownerCnt() {
      return this.ownerList.length > 2 ? true : false
    },
    addressList() {
      return String(this.ownerList.map((item) => item.address))
    },
    myAddress() {
      return this.$store.getters['auth/address']
    },
  },
  methods: {
    async createWallet(threshold, description) {
      this.dialog = false
      this.description = ''
      this.threshold = ''

      this.ownerList[0].address = this.myAddress
      if (this.ownerList[0].address == '') {
        alert('내 지갑정보를 가져오기 위해 로그인을 다시 해주세요.')
      }
      if (description == '') {
        alert('지갑명을 입력해주세요.')
        return false
      } else if (this.addressList.length == ',,') {
        alert('승인유저를 선택해주세요')
        return false
      } else if (threshold == '') {
        alert('승인자 수를 선택해주세요.')
        return false
      }
      const result = await this.$refs.confirm.open(
        'Confirm',
        '지갑이 생성하시겠습니까'
      )
      if (result) {
        axiosInstance
          .post('/mpcwallet/createWallet', {
            addressList: this.addressList, // ','로 구분된 주소 목록 ex) 0xf6C93baF9CEd3Da6C210f02044f6d9082b9305e6,0x869F039aAfFCDfFc348fbd3a094564F19df9Ca4E
            partySize: this.confirmCntTotal, // 지갑 전체 소유자 수 (addressList 주소 개수와 같음)
            threshold, // 전송 최소 승인자 수
            description, // 지갑 이름
          })
          .then((r) => {
            if (r.status == 200) {
              alert('생성되었습니다.')
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    rowAdd() {
      this.ownerList.push({
        name: '',
        address: '',
      })
    },
    rowDelete(index) {
      this.ownerList.splice(index + 1, 1)
    },
  },
  mounted() {
    axiosInstance
      .post('/mpcuser/list', {})
      .then((r) => {
        if (r.status == 200) {
          this.list = r.data.list.filter(
            (item) => item.address !== this.myAddress
          )
        }
      })
      .catch((err) => {
        console.error(err)
      })
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
.wallet_form .v-sheet {
  padding: 10px 20px;
}
.confirm_select {
  min-width: 80px;
  flex: 0;
  margin: 0 10px !important;
}
.no-margin:v-deep .v-input__slot {
  margin: 0 !important;
}
.no-margin:v-deep .v-text-field__details {
  display: none;
}
.confirm-user-select:v-deep span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.confirm-user-select:v-deep input[readonly] {
  flex: 0;
  font-size: 0;
  line-height: 0;
}
</style>
