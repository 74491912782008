<template>
  <div>
    <v-row>
      <v-col cols="12" lg="4">
        <v-file-input
          id="file-input"
          show-size
          outlined
          label="Choose File"
          @click:clear="clearList"
        ></v-file-input>
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn @click="postList">
            <v-icon>mdi-upload</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="2">
        <v-text-field
          label="Owner*"
          v-model="owner"
          single-line
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="1">
        <v-text-field
          label="Token ID*"
          v-model="tokenId"
          single-line
          type="number"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="1">
        <v-text-field
          label="Aamount*"
          v-model="amount"
          single-line
          type="number"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn class="btn-row" @click="addItem">Add</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="count"
        item-key="idx"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.preview`]="{ item }">
          <v-img
            style="vertical-align: middle"
            :src="item.image"
            :alt="item.image"
            width="45px"
            class="ml-0"
          ></v-img>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.image }}</div>
            <div class="hover_inner">
              <v-btn @click="openLink(item.image)" class="link">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <pre>
# minting list
# owner | tokenId | amount

0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 1, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 2, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 3, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 4, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 5, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 6, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 7, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 8, 100
0x18817A4d5C25d3B43eE6EB1cD29304A7133fD877, 9, 100</pre
      >
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Web3 from 'web3'

export default {
  name: 'NFT1155AddView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Sequential', value: 'idx', width: '5%' },
        { text: 'Owner', value: 'owner', width: '25%' },
        { text: 'Token ID', value: 'tokenId', width: '5%' },
        { text: 'Amount', value: 'amount', width: '5%' },
      ],
      displist: [],
      totalList: [],
      count: 0,
      owner: null,
      tokenId: null,
      amount: null,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList()
      },
      deep: true,
    },
    erc1155Address() {
      this.getList()
    },
  },
  methods: {
    getList() {
      this.displist = this.totalList.slice(this.firstItem, this.itemsPerPage)
    },
    addItem() {
      if (Web3.utils.isAddress(this.address.trim()) == false) {
        alert(`invalid address : ${this.address}`)
        return
      }

      if (isNaN(parseInt(this.amount.trim(), '10'))) {
        alert(`invalid amount : ${this.amount}`)
        return
      }

      const idx = this.totalList.length + 1
      this.totalList.push({
        idx: idx,
        owner: this.owner,
        tokenId: this.tokenId,
        amount: this.amount,
      })
      this.count++
      this.getList()
    },
    readSingleFile(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      let _list = []
      let _listCount = 0

      const reader = new FileReader()
      reader.onload = (e) => {
        const contents = e.target.result
        const lineArr = contents.split('\n')
        for (let line of lineArr) {
          if (line.length == 0) {
            // last line
            continue
          }
          _listCount++

          const itemArr = line.split(',')
          if (itemArr.length < 2) {
            alert(`invalid line : ${_listCount} - ${line}`)
            _listCount--
            continue
          }
          const attributes = itemArr[4].trim().replace('\r', '')
          try {
            JSON.parse(attributes)
          } catch (error) {
            alert(`invalid attributes : ${_listCount} - ${attributes}`)
            _listCount--
            continue
          }
          _list.push({
            idx: _listCount,
            owner: itemArr[0].trim().replace('\r', ''),
            tokenId: itemArr[1].trim().replace('\r', ''),
            amount: itemArr[2].trim().replace('\r', ''),
          })
        }
        this.totalList = _list
        this.count = _listCount
        this.displist = this.totalList.slice(this.firstItem, this.itemsPerPage)
      }

      reader.readAsText(file)
    },
    clearList() {
      this.displist = []
      this.count = 0
    },
    postList() {
      if (confirm('Do you want to send?')) {
        axiosInstance
          .post('/nft1155/postList', {
            erc1155Address: this.erc1155Address,
            list: this.totalList,
          })
          .then((result) => {
            if (result.status != 200) {
              return alert('업로드 실패했습니다.')
            }
            this.clearList()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    openLink(imageurl) {
      window.open(imageurl, '_blank')
    },
  },
  mounted() {
    document
      .getElementById('file-input')
      .addEventListener('change', this.readSingleFile, false)
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
</style>
