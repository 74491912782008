<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div
            v-if="item.address && item.address.length > 0"
            class="hover_area"
          >
            <div class="hover_address">{{ item.address }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="query = item.address" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.transactionHash`]="{ item }">
          <div v-if="item.transactionHash.length > 0" class="hover_area">
            <div class="hover_address">{{ item.transactionHash }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.transactionHash)"
                class="copy"
                ref="${item.transactionHash}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`tx/${item.transactionHash}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="query = item.transactionHash" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.retryItem`]="{ item }">
          <button v-on:click="retryItem(item)">
            <v-icon>mdi-replay</v-icon>
          </button>
        </template>
        <template v-slot:[`item.deleteItem`]="{ item }">
          <button v-on:click="deleteItem(item)">
            <v-icon>mdi-trash-can-outline</v-icon>
          </button>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'ERC20TransactionStatusView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Transaction Hash', value: 'transactionHash' },
        { text: 'Modified Date', value: 'updatedAt', width: '15%' },
        { text: 'Created Date', value: 'createdAt', width: '15%' },
        { text: '', value: 'retryItem', width: '34px' },
        { text: '', value: 'deleteItem', width: '34px' },
      ],
      totalCount: 0,
      list: [],
      query: '',
      sort: '',
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/withdraw/statusList', {
          erc20Address: this.erc20Address,
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.list = result.data.list.map((item) => {
              item.createdAt = item.createdAt.substring(0, 19)
              item.updatedAt = item.createdAt.substring(0, 19)
              return item
            })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    retryItem(item) {
      if (confirm('Do you want to retry again?')) {
        axiosInstance
          .post('/withdraw/retry', {
            erc20Address: this.erc20Address,
            transactionHash: item.transactionHash,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    deleteItem(item) {
      if (confirm('Do you want to delete?')) {
        axiosInstance
          .post('/withdraw/deleteStatus', {
            erc20Address: this.erc20Address,
            transactionHash: item.transactionHash,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'address,transactionHash,updatedAt,createdAt\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/withdraw/statusList',
            {
              erc20Address: this.erc20Address,
              offset: skip,
              limit: 1000,
              query: this.query,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.address},${item.transactionHash},${item.updatedAt},${item.createdAt}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'transaction_status.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
