<template>
  <div>
    <div v-show="stage == 1">
      <v-row>
        <v-col cols="12" lg="2">
          <div class="btn-row">
            <v-btn @click="doAdd()">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
          class="elevation-1 mt-4 outlined"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.code">
                <td>{{ item.code }}</td>
                <td class="left">{{ item.description }}</td>
                <td>{{ item.postingtime }}</td>
                <td>{{ item.deadline }}</td>
                <td>{{ item.livetime }}</td>
                <td>{{ item.mission_type }}</td>
                <td>{{ item.button_count }}</td>
                <td>{{ item.prize }}</td>
                <td>{{ item.prize_metaq }}</td>
                <td>{{ item.entry_fee }}</td>
                <td>{{ item.total_participants }}</td>
                <td>{{ item.metaq_required }}</td>
                <td>
                  {{ item.current_engagement }}
                  <button
                    v-on:click="copyList(item)"
                    ref="${item.participant_list}"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </button>
                </td>
                <td>{{ item.status }}</td>
                <td>
                  <button v-on:click="editItem(item)">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </button>
                </td>
                <td>
                  <button v-on:click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <div :style="{ width: stage == 1 ? '0%' : '100%' }">
      <v-row>
        <v-col cols="12" lg="2">
          <v-btn
            v-if="stage != 1"
            class="mr-2"
            color="secondary"
            @click="stage = 1"
          >
            <v-icon>mdi-arrow-left-thin</v-icon>
          </v-btn>
          <v-btn
            v-if="stage == 2"
            class="mr-2"
            color="primary"
            @click="addItem"
          >
            Save
          </v-btn>
          <v-btn
            v-if="stage == 3"
            class="mr-2"
            color="primary"
            @click="modifyItem"
          >
            Modify
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="stage != 1">
        <v-col cols="12" lg="3">
          <div class="flex-row">
            <div class="previmg">
              <img
                v-if="previewImage.length > 0"
                :src="previewImage"
                alt="Image"
                style="width: 100%; height: 100%"
              />
            </div>
            <drag-drop
              class="drag-drop"
              :uppy="uppy"
              :props="{
                locale: {
                  strings: {
                    chooseFile: 'Boop a file',
                    orDragDrop: 'or yoink it here',
                  },
                },
              }"
            />
          </div>
        </v-col>
        <v-col cols="12" lg="9">
          <v-row>
            <v-col cols="12" lg="6">
              <v-text-field
                label="Description*"
                v-model="description"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="3"></v-col>
            <v-col cols="12" lg="1">
              <v-text-field
                label="status*"
                v-model="status"
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row>
                    <v-subheader>게시일</v-subheader>
                    <v-text-field
                      v-model="postingtime"
                      prepend-icon="mdi-calendar"
                      prepend-inner="게시일"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-row>
                </template>
                <v-date-picker
                  v-model="selectedDate1"
                  label="Select Date"
                  @input="updateDateTime1"
                ></v-date-picker>
                <v-time-picker
                  v-model="selectedTime1"
                  label="Select Time"
                  @input="updateDateTime1"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row>
                    <v-subheader>Deadline</v-subheader>
                    <v-text-field
                      v-model="deadline"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-row>
                </template>
                <v-date-picker
                  v-model="selectedDate2"
                  label="Select Date"
                  @input="updateDateTime2"
                ></v-date-picker>
                <v-time-picker
                  v-model="selectedTime2"
                  label="Select Time"
                  @input="updateDateTime2"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" lg="3">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row>
                    <v-subheader>Live Time</v-subheader>
                    <v-text-field
                      v-model="livetime"
                      prepend-icon="mdi-calendar"
                      prepend-inner="Live Time"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </v-row>
                </template>
                <v-date-picker
                  v-model="selectedDate3"
                  label="Select Date"
                  @input="updateDateTime3"
                ></v-date-picker>
                <v-time-picker
                  v-model="selectedTime3"
                  label="Select Time"
                  @input="updateDateTime3"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2">
              <v-text-field
                label="prize*"
                v-model="prize"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="prizeMetaq*"
                v-model="prizeMetaq"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="entry_fee*"
                v-model="entryFee"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="total_participants*"
                v-model="totalParticipants"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="required metaq*"
                v-model="metaqRequired"
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="2">
              <v-text-field
                label="mission type*"
                v-model="missionType"
                type="number"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="2">
              <v-text-field
                label="button count"
                v-model="buttonCount"
                type="number"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <SmartEditor
            :editorProp="oEditors"
            :isModify="modify"
            :contentsProp="contentEdit"
            elPlaceHolder="elPlaceHolder"
          ></SmartEditor>
        </v-col>
      </v-row>
    </div>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import SmartEditor from '@/components/SmartEditor/SmartEditor.vue'
import axiosInstance from '@/utils/axiosInstance'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop } from '@uppy/vue'

function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}

export default {
  name: 'MissionView',
  components: {
    ConfirmDlg,
    SmartEditor,
    DragDrop,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'code', value: 'code', width: '5%' },
        { text: 'description', value: 'description' },
        { text: 'posting_time', value: 'postingtime', width: '10%' },
        { text: 'deadline', value: 'deadline', width: '10%' },
        { text: 'livetime', value: 'livetime', width: '10%' },
        { text: 'mission_type', value: 'mission_type', width: '5%' },
        { text: 'button_count', value: 'button_count', width: '5%' },
        { text: 'prize', value: 'prize', width: '5%' },
        { text: 'prize_metaq', value: 'prize_metaq', width: '5%' },
        { text: 'entry_fee', value: 'entry_fee', width: '5%' },
        {
          text: 'limited',
          value: 'total_participants',
          width: '5%',
        },
        {
          text: 'required metaq',
          value: 'metaq_required',
          width: '5%',
        },
        {
          text: 'engagement',
          value: 'current_engagement',
          width: '7%',
        },
        { text: 'status', value: 'status', width: '50px' },
        { text: '', value: 'editItem', sortable: false, width: '34px' },
        { text: '', value: 'deleteItem', sortable: false, width: '34px' },
      ],
      totalCount: 0,
      list: [],
      code: 0,
      description: '',
      previewImage: '',
      prize: 100,
      prizeMetaq: 0,
      entryFee: 10,
      totalParticipants: 10,
      metaqRequired: 0,
      missionType: 0,
      buttonCount: 0,
      status: '0',
      modify: false,
      oEditors: [],
      contentEdit: '',
      stage: 1,
      menu1: null,
      menu2: null,
      menu3: null,
      selectedDate1: null,
      selectedTime1: null,
      selectedDate2: null,
      selectedTime2: null,
      selectedDate3: null,
      selectedTime3: null,
      postingtime: null,
      deadline: null,
      livetime: null,
    }
  },
  computed: {
    uppy: function () {
      return new Uppy({
        id: 'uppy1',
        autoProceed: true,
        debug: false,
        meta: { address: '0x0000000000000000000000000000000000000000' },
        //        restrictions: {
        //          maxFileSize: 2097152, // 2M
        //          allowedFileTypes: ['image/*'],
        //        },
      })
        .use(Tus, {
          endpoint: '/files/',
        })
        .on('upload-success', (file, response) => {
          // Create a regular expression to match the domain part of the URL
          const domainRegex = /^(https?:\/\/[^/]+)(\/|$)/

          // Use the regular expression to extract the matched domain
          const match = response.uploadURL.match(domainRegex)

          if (match && match[1]) {
            const domain = match[1]

            // Remove the domain from the input string
            this.previewImage = response.uploadURL.replace(domain, '')
          } else {
            this.previewImage = response.uploadURL
          }
        })
        .on('restriction-failed', (_, error) => {
          alert(error.message)
        })
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    contentEdit() {
      return this.contentEdit
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    updateDateTime1() {
      this.postingtime = `${this.selectedDate1} ${this.selectedTime1}`
    },
    updateDateTime2() {
      this.deadline = `${this.selectedDate2} ${this.selectedTime2}`
    },
    updateDateTime3() {
      this.livetime = `${this.selectedDate3} ${this.selectedTime3}`
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/metaplanet_arena/list', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.totalCount
            this.list = r.data.list.map((item) => {
              if (item.postingtime.length > 0) {
                item.postingtime = item.postingtime.substring(0, 16)
              }
              item.deadline = item.deadline.substring(0, 16)
              if (item.livetime.length > 0) {
                item.livetime = item.livetime.substring(0, 16)
              }
              return item
            })
            this.list = r.data.list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    contentInit() {
      this.modify = false
      this.contentEdit = ''
      this.description = ''
      this.previewImage = ''
      this.prize = 100
      this.prizeMetaq = 0
      this.entryFee = 10
      this.totalParticipants = 10
      this.metaqRequired = 0
      this.missionType = 0
      this.buttonCount = 0
      this.status = '0'
      this.menu1 = null
      this.menu2 = null
      this.menu3 = null
      this.selectedDate1 = formatDate(new Date())
      this.selectedTime1 = '00:00'
      this.selectedDate2 = this.selectedDate1
      this.selectedTime2 = '00:00'
      this.selectedDate3 = this.selectedDate1
      this.selectedTime3 = '00:00'
      this.postingtime = `${this.selectedDate1} ${this.selectedTime1}`
      this.deadline = `${this.selectedDate2} ${this.selectedTime2}`
      this.livetime = `${this.selectedDate3} ${this.selectedTime3}`
    },
    doAdd() {
      this.contentInit()

      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])
      this.stage = 2
    },
    async addItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.description == '') {
        alert('Please enter a description.')
      } else if (this.deadline == '') {
        alert('Please enter a deadline.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to add?'
        )
        if (result) {
          axiosInstance
            .post('/metaplanet_arena/add', {
              description: this.description,
              preview_image: this.previewImage,
              content: content,
              postingtime: this.postingtime,
              deadline: this.deadline,
              livetime: this.livetime,
              prize: this.prize.toString(),
              prize_metaq: this.prizeMetaq.toString(),
              entry_fee: this.entryFee.toString(),
              total_participants: this.totalParticipants.toString(),
              metaq_required: this.metaqRequired.toString(),
              mission_type: this.missionType.toString(),
              button_count: this.buttonCount.toString(),
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
    editItem(item) {
      console.log(item)

      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])

      this.code = item.code
      this.description = item.description
      this.previewImage = item.preview_image
      this.postingtime = item.postingtime
      this.deadline = item.deadline
      this.livetime = item.livetime
      this.prize = parseInt(item.prize)
      this.prizeMetaq = parseInt(item.prize_metaq)
      this.entryFee = parseInt(item.entry_fee)
      this.totalParticipants = parseInt(item.total_participants)
      this.metaqRequired = parseInt(item.metaq_required)
      this.missionType = parseInt(item.mission_type)
      this.buttonCount = parseInt(item.button_count)
      this.status = item.status

      axiosInstance
        .get(`/metaplanet_arena/content/${item.code}`)
        .then((r) => {
          this.contentEdit = r.data.content.replaceAll(
            'https://www.metaplanet-nft.com//upload',
            'https://www.metaplanet-nft.com/images'
          )
          this.modify = true
          this.stage = 3
        })
        .catch((err) => {
          alert(err.response.data.message)
          console.error(err)
        })
    },
    async deleteItem(item) {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to delete?'
      )
      if (result) {
        axiosInstance
          .delete(`/metaplanet_arena/${item.code}`)
          .then((r) => {
            if (r.status == 200) {
              this.contentInit()
              this.getList()
            }
          })
          .catch((err) => {
            alert(err.response.data.message)
            console.error(err)
          })
      }
    },
    async modifyItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.title == '') {
        alert('Please enter a title.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to modify?'
        )
        if (result) {
          axiosInstance
            .post('/metaplanet_arena/modify', {
              code: this.code,
              description: this.description,
              preview_image: this.previewImage,
              content: content,
              postingtime: this.postingtime,
              deadline: this.deadline,
              livetime: this.livetime,
              prize: this.prize.toString(),
              prize_metaq: this.prizeMetaq.toString(),
              entry_fee: this.entryFee.toString(),
              total_participants: this.totalParticipants.toString(),
              metaq_required: this.metaqRequired.toString(),
              status: this.status,
              mission_type: this.missionType.toString(),
              button_count: this.buttonCount.toString(),
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
    copyList(item) {
      const path = `/metaplanet_arena/content/${item.code}`
      try {
        axiosInstance
          .get(path)
          .then((result) => {
            let value = ''
            for (
              let idx = 0;
              idx < result.data.participant_list.length;
              idx++
            ) {
              value += `${result.data.participant_list[idx]}, ${result.data.position_list[idx]}\n`
            }

            const url = document.createElement('textarea')
            url.value = value
            url.setAttribute('readonly', '')
            url.style.position = 'absolute'
            url.style.left = '-9999px'
            document.body.appendChild(url)
            const selected =
              document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false
            url.select()
            document.execCommand('copy')
            document.body.removeChild(url)
            if (selected) {
              document.getSelection().removeAllRanges()
              document.getSelection().addRange(selected)
            }
          })
          .catch(() => {
            //console.log('fail');
          })
      } catch (error) {
        console.log(error)
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
    this.selectedDate1 = formatDate(new Date())
    this.selectedTime1 = '00:00'
    this.selectedDate2 = this.selectedDate1
    this.selectedTime2 = '00:00'
    this.selectedDate3 = this.selectedDate1
    this.selectedTime3 = '00:00'
    this.postingtime = `${this.selectedDate1} ${this.selectedTime1}`
    this.deadline = `${this.selectedDate2} ${this.selectedTime2}`
    this.livetime = `${this.selectedDate3} ${this.selectedTime3}`
  },
}
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style src="@uppy/drag-drop/dist/style.css"></style>
<style src="@uppy/progress-bar/dist/style.css"></style>

<style scoped>
.flex-row {
  display: flex;
  gap: 5px;
  /* // margin: 10px 0; */
}
.drag-drop {
  width: 300px;
  height: 300px;
}
.image:hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.previmg {
  margin: 20px;
  width: 300px;
  height: 200px;
  border: 1px solid blue;
}
</style>
