<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="2">
        <v-select :items="searchOptions" v-model="type"></v-select>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field clearable v-model="search" label="Search" />
      </v-col>
      <v-col cols="12" lg="3">
        <div class="btn-row">
          <v-file-input
            id="file-input"
            show-size
            outlined
            label="Choose File"
            style="margin-right: 10px"
          ></v-file-input>
          <v-btn @click="upload" style="margin-right: 10px">
            <v-icon>mdi-upload</v-icon>
          </v-btn>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="1">
        <h3 style="text-align: right">
          Total: {{ totalCount.toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="cell cell_addr hover_area">
            <div class="text_cut">
              <span>{{ item.address }}</span>
            </div>
            <div class="hover_inner">
              <v-btn class="copy" @click="copy(item.address)">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn class="link" @click="move(item.address)">
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.types`]="{ item }">
          <div class="cell cell_type hover_area">
            <div>
              <span>{{ item.types }}</span>
            </div>
            <div class="hover_inner">
              <v-btn class="edit" @click="openModal(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <div class="cell cell_owner hover_area">
            <div>
              <span>{{ item.name || '-' }}</span>
            </div>
            <div class="hover_inner">
              <v-btn class="edit" @click="openModal(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item._value`]="{ item }">
          <div class="cell cell_amount">
            {{ item._value }}
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <pre>
# Creating a List of Holders, Aliases, and Types to Update Holder Information

# Holder Address, Alias, Type
0x0645Af43088813C44399B79FF45896D68eB23b8F, User_1, 11
0x32a4598fCB7272C022557B28dFb8021b6df53F55, User_2, 11
0x266DCC67c8d42fA16C0E3e57744A7FB7288A3100, User_3, 11

# Type Example
    Default      0
    Company      1
    Exchange     2
    Marketing    3
    Sale         4
    Staking      5
    Team         6
    CM liquidity 7
    Advisor      8
    Unlock       9
    Portal      10
    System      11</pre
      >
    </v-row>
    <v-dialog v-model="dialog" max-width="375px">
      <v-card class="modal deal">
        <div class="modal_title">Deal Details</div>
        <div class="pa-3">
          <div class="box">
            <div class="label">Select.</div>
            <div class="modal_input">
              <v-select
                :items="types"
                v-model="selected.type"
                hide-details
              ></v-select>
            </div>
          </div>
          <div class="box">
            <div class="label">From.</div>
            <div class="modal_input">
              <v-text-field
                @keydown="modalKeydown"
                v-model="selected.name"
                hide-details
                placeholder="Owner"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-6">
            <v-btn
              depressed
              block
              color="bk"
              dark
              height="40px"
              @click="updateInfo"
            >
              Submit
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanURL } from '@/utils/network'

export default {
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', sortable: false, width: '17%' },
        { text: 'Type', value: 'types', sortable: false, width: '25%' },
        { text: 'Owner', value: 'name', sortable: false },
        { text: 'Amount', value: '_value', sortable: false, width: '15%' },
      ],
      dialog: false,
      list: [],
      totalCount: 0,
      total: 0,
      types: [
        { text: 'Default', value: '0' },
        { text: 'Company', value: '1' },
        { text: 'Exchange', value: '2' },
        { text: 'Marketing', value: '3' },
        { text: 'Sale', value: '4' },
        { text: 'Staking', value: '5' },
        { text: 'Team', value: '6' },
        { text: 'CM liquidity', value: '7' },
        { text: 'Advisor', value: '8' },
        { text: 'Unlock', value: '9' },
        { text: 'Portal', value: '10' },
        { text: 'System', value: '11' },
      ],
      searchOptions: [
        { text: 'All', value: 'all' },
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'More', value: 'more' },
        { text: 'Less', value: 'less' },
      ],
      search: '',
      type: 'all',
      selected: {},
      uploadList: [],
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    type() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    search() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    options: {
      handler() {
        const { page, itemsPerPage } = this.options

        this.$store.dispatch('pagination/setPagination', {
          page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    modalKeydown(event) {
      if (event.keyCode === 13) {
        this.updateInfo()
      }
    },
    updateInfo() {
      let { name, type, _id, address } = this.selected
      this.selected.load = true

      axiosInstance
        .post('/tokentracker/updateAddressInfo', {
          contractAddress: this.erc20Address,
          _id,
          name,
          type,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            this.dialog = false
            // this.$toast.success('success')

            this.list.forEach((item) => {
              if (item.address === address) {
                item.name = name
                item.type = type

                let types = []
                let map = {
                  1: 'Company',
                  2: 'Exchange',
                  3: 'Marketing',
                  4: 'Sale',
                  5: 'Staking',
                  6: 'Team',
                  7: 'CM liquidity',
                  8: 'Advisor',
                  9: 'Unlock',
                  10: 'Portal',
                  11: 'System',
                }

                if (item.type !== '0') {
                  types.push(map[item.type])
                }

                if (item.isBank) {
                  types.push('bank')
                }

                if (item.hdnOwnr) {
                  types.push('hidden owner')
                }

                if (item.isSprw) {
                  types.push('super owner')
                }

                if (item.isOwnr) {
                  types.push('owner')
                }

                if (item.isWthd) {
                  types.push('withdrawalWallet')
                }

                if (item.isRclr) {
                  types.push('reclaimer')
                }

                if (item.isBlck) {
                  types.push('blacklist')
                }

                if (item.isBrnr) {
                  types.push('burner')
                }

                if (item.isjdgs) {
                  types.push('judge')
                }

                if (item.isMngr) {
                  types.push('manager')
                }

                if (item.isDpst) {
                  types.push('depositWallet')
                }

                item.types = types.join(', ') || '-'
              }
            })

            this.$forceUpdate()
          }
        })
        .catch(console.error)
        .finally(() => {
          this.selected.load = false
        })
    },
    keydown(event) {
      if (event.keyCode === 13) {
        this.page = 1

        this.getList()
      }
    },
    openModal(item) {
      this.selected._id = item._id
      this.selected.name = item.name
      this.selected.type = item.type
      this.selected.address = item.address

      this.dialog = true
    },
    getList(offset, limit) {
      const { page, itemsPerPage } = this.options

      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }

      axiosInstance
        .post('/tokentracker/getHolders', {
          contractAddress: this.erc20Address,
          tokenType: 'erc20',
          offset,
          limit,
          type: this.type,
          search: this.search,
        })
        .then((res) => {
          this.list = res.data.holderlist

          res.data.holderlist.forEach((item) => {
            let types = []

            let map = {
              1: 'Company',
              2: 'Exchange',
              3: 'Marketing',
              4: 'Sale',
              5: 'Staking',
              6: 'Team',
              7: 'CM liquidity',
              8: 'Advisor',
              9: 'Unlock',
              10: 'Portal',
              11: 'System',
            }
            item.type = item.type || '0'

            if (item.type !== '0') {
              types.push(map[item.type])
            }

            item.types = types.join(', ') || '-'
            item._value = parseFloat(item.value || 0).toLocaleString()
          })

          this.totalCount = res.data.count
        })
        .catch(console.error)
    },
    readSingleFile(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      let _list = []
      let _listCount = 0

      const reader = new FileReader()
      reader.onload = (e) => {
        const contents = e.target.result
        const lineArr = contents.split('\n')
        for (let line of lineArr) {
          if (line.length == 0) {
            // last line
            continue
          }
          _listCount++
          const itemArr = line.split(',')
          if (itemArr.length < 2) {
            alert(`invalid line : ${_listCount} - ${line}`)
            _listCount--
            continue
          }
          _list.push({
            address: itemArr[0].trim().replace('\r', ''),
            name: itemArr[1].trim().replace('\r', ''),
            type: itemArr[2].trim().replace('\r', ''),
          })
        }
        this.uploadList = _list
      }

      reader.readAsText(file)
    },
    async download() {
      let totalCount = 0
      let skip = 0

      let processData = '\ufeff' + 'address,name,type,value\n'

      this.progressValue = 0

      try {
        do {
          const { data, status } = await axiosInstance.post(
            '/tokentracker/getHolders',
            {
              contractAddress: this.erc20Address,
              tokenType: 'erc20',
              offset: skip,
              limit: 10000,
              type: this.type,
              search: this.search,
            }
          )
          if (status == 200) {
            totalCount = data.count

            for (let item of data.holderlist) {
              processData += `${item.address},"${item.name}",${item.type},${item.value}\n`
            }
            skip += data.holderlist.length
          } else {
            return
          }

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'erc20_holders.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    upload() {
      if (confirm('Do you want to send?')) {
        axiosInstance
          .post('/tokentracker/uploadHolders', {
            contractAddress: this.erc20Address,
            list: this.uploadList,
          })
          .then(({ status }) => {
            if (status != 200) {
              alert('업로드 실패했습니다.')
            }
          })
      }
    },
    copy(v) {
      let textArea = document.createElement('textarea')
      textArea.value = v
      textArea.style.height = '1px'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)

      textArea.select()

      try {
        document.execCommand('copy')
        // this.$toast.success('copy!')
      } catch (err) {
        // this.$toast.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    move(v) {
      window.open(
        `${scanURL(this.chainID)}/token/${this.erc20Address}?a=${v}`,
        '_blank'
      )
    },
  },
  mounted() {
    document
      .getElementById('file-input')
      .addEventListener('change', this.readSingleFile, false)
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
</style>
