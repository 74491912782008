<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="5">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="3">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          Total: {{ totalCount.toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        item-key="fileHash"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.owner`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.owner }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.owner)"
                class="copy"
                ref="${item.owner}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.owner}`, 'erc721')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.image`]="{ item }">
          <div class="image" @click="itemClick(erc721Address, item)">
            <v-img
              :src="item.image.replace('ipfs://', 'https://ipfs.io/ipfs/')"
              :alt="item.name"
              width="45px"
              class="ml-0"
            ></v-img>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.name }}</span>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.description }}</span>
            </template>
            <span>{{ item.description }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.attributes`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ item.attributes }}</span>
            </template>
            <span>{{ item.attributes }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  data() {
    return {
      options: {},
      query: '',
      symbol: '',
      headers: [
        {
          text: 'Token ID',
          sortable: false,
          value: 'tokenId',
          width: '5%',
        },
        {
          text: 'Image',
          value: 'image',
          sortable: false,
          width: '5%',
          align: 'center',
        },
        { text: 'Owner', value: 'owner', sortable: false, width: '20%' },
        { text: 'Name', value: 'name', sortable: false, width: '10%' },
        {
          text: 'Description',
          value: 'description',
          sortable: false,
          width: '10%',
        },
        { text: 'Attributes', value: 'attributes', sortable: false },
      ],
      totalCount: 0,
      list: [],
      uploadList: [],
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc721Address() {
      this.getList()
    },
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/tokentracker/getInventory', {
          contractAddress: this.erc721Address,
          tokenType: 'erc721',
          offset,
          limit,
          search: this.query,
        })
        .then((result) => {
          if (result.status == 200) {
            this.list = result.data.inventoryList.map((item) => {
              const attributes = item.attributes.reduce((acc, cur) => {
                if (acc.length) return acc + `, ${cur.trait_type}:${cur.value}`
                else return `${cur.trait_type}:${cur.value}`
              }, '')
              return {
                ...item,
                attributes: attributes,
              }
            })
            this.totalCount = result.data.count
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'tokenId,owner,name,description,image,attributes\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/tokentracker/getInventory',
            {
              contractAddress: this.erc721Address,
              tokenType: 'erc721',
              offset: skip,
              limit: 1000,
              search: this.query,
            }
          )
          if (status == 200) {
            totalCount = data.count

            for (let item of data.inventoryList) {
              const attributes = item.attributes
                .reduce((acc, cur) => {
                  if (acc.length)
                    return acc + `, ${cur.trait_type}:${cur.value}`
                  else return `${cur.trait_type}:${cur.value}`
                }, '')
                .replace(/"/g, '""')
              processData += `${item.tokenId},${item.owner},"${item.name}","${item.description}","${item.image}","${attributes}"\n`
            }
          } else {
            return
          }
          skip += data.inventoryList.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'inventory_erc721.csv')
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    upload() {
      if (confirm('Do you want to send?')) {
        axiosInstance
          .post('/nft721/postMetadata', {
            erc721Address: this.erc721Address,
            list: this.uploadList,
          })
          .then(({ status }) => {
            if (status != 200) {
              alert('업로드 실패했습니다.')
            }
          })
      }
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    detailLink(link) {
      window.open(link, '_blank')
    },
    itemClick(contractAddress, item) {
      switch (this.chainID) {
        case 1: {
          const url = `https://etherscan.io/nft/${contractAddress}/${item.tokenId}`
          window.open(url, '_blank')
          break
        }
        default: {
          const imageUrl = item.image.replace(
            'ipfs://',
            'https://ipfs.io/ipfs/'
          )
          window.open(imageUrl, '_blank')
        }
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style lang="scss" scoped>
.v-responsive {
  margin: 0 auto;
}
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
.image:hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
