<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="2">
        <v-select
          :items="this.timesList"
          label="Round"
          v-model="times"
        ></v-select>
      </v-col>
      <v-col cols="12" lg="4">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="4">
        <div class="btn-row">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            :return-value.sync="fromDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="fromDate" no-title scrollable :max="toDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu1 = false">Cancel</v-btn>
              <v-btn text color="primary" @click="fromDate_search(fromDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="toDate"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toDate"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              no-title
              scrollable
              :min="fromDate"
              :max="toDay"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">Cancel</v-btn>
              <v-btn text color="primary" @click="toDate_search(toDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-download</v-icon>
              </template>
              <span>Download Detail</span>
            </v-tooltip>
          </v-btn>
          <v-btn :disabled="progressValue != 100" @click="download_report">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-cloud-download</v-icon>
              </template>
              <span>Download Report</span>
            </v-tooltip>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="2">
        <h3 style="text-align: right">
          Total: {{ totalCount.toLocaleString() }} &nbsp;/&nbsp;{{
            Number(totalAmount).toLocaleString()
          }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.amount`]="{ item }">
          <div
            v-bind:style="
              item.amount > 3000
                ? item.amount > 5000
                  ? item.amount > 10000
                    ? 'color:#d32f2f'
                    : 'color:#f9a825'
                  : 'color:#4caf50'
                : ''
            "
          >
            {{ item.amount.toLocaleString() }}
          </div>
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.name.length > 0 ? item.name : item.address }}
            </div>
            <div class="hover_inner">
              <v-btn class="edit" @click="openModal(item.address)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.transactionHash`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.transactionHash }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.transactionHash)"
                class="copy"
                ref="${item.transactionHash}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`tx/${item.transactionHash}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.description }}
            </div>
            <div class="hover_inner">
              <v-btn @click="editDescription(item)" class="copy">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.memo`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.memo }}
            </div>
            <div class="hover_inner">
              <v-btn @click="editMemo(item)" class="copy">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-row>
    <v-dialog v-model="editMemoDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Memo</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="memo" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="editMemoDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="modifyMemo">
            Modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDescriptionDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Description</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="description" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="editDescriptionDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="modifyDescription"
          >
            Modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="375px">
      <v-card class="modal deal">
        <div class="modal_title">{{ selected.address }}</div>
        <div class="pa-3">
          <div class="box">
            <div class="label">Type</div>
            <div class="modal_input">
              <v-select
                hide-details
                :items="types"
                v-model="selected.type"
              ></v-select>
            </div>
          </div>
          <div class="box">
            <div class="label">Name</div>
            <div class="modal_input">
              <v-text-field
                v-model="selected.name"
                hide-details
                @keydown="modalKeydown"
                placeholder="Name"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-6">
            <v-btn
              depressed
              block
              color="bk"
              dark
              height="40px"
              @click="updateInfo"
              :loading="selected.load"
            >
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'AirdropHistoryView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Round', value: 'times', width: '5%' },
        { text: 'Sequential', value: 'idx', width: '7%' },
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Amount', value: 'amount', width: '7%' },
        { text: 'Description', value: 'description' },
        { text: 'Memo', value: 'memo' },
        { text: 'Transaction Hash', value: 'transactionHash' },
        { text: 'Modified Date', value: 'updatedAt', width: '15%' },
      ],
      types: [
        { text: 'Default', value: '0' },
        { text: 'Company', value: '1' },
        { text: 'Exchange', value: '2' },
        { text: 'Marketing', value: '3' },
        { text: 'Sale', value: '4' },
        { text: 'Staking', value: '5' },
        { text: 'Team', value: '6' },
        { text: 'CM liquidity', value: '7' },
        { text: 'Advisor', value: '8' },
        { text: 'Unlock', value: '9' },
        { text: 'Portal', value: '10' },
        { text: 'System', value: '11' },
      ],
      totalCount: 0,
      totalAmount: 0,
      list: [],
      timesList: [],
      times: 0,
      query: '',
      sort: '',
      disableAirdrop: false,
      interval: null,
      menu1: null,
      menu2: null,
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      id: '',
      description: '',
      memo: '',
      editDescriptionDialog: false,
      editMemoDialog: false,
      selected: {},
      dialog: false,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    fromDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    toDate() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    times() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      if (this.times == 'All' || isNaN(parseInt(this.times))) {
        this.times = 0
      }
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList()
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    modalKeydown(event) {
      if (event.keyCode === 13) {
        this.updateInfo()
      }
    },
    updateInfo() {
      let { _id, name, type, address } = this.selected

      this.selected.load = true

      axiosInstance
        .post('/tokentracker/updateAddressInfo', {
          contractAddress: this.erc20Address,
          _id,
          name,
          type,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            this.dialog = false
            // this.$toast.success('success')

            this.list.forEach((item) => {
              item.name = item.address === address ? name : item.name
            })

            this.$forceUpdate()
          }
        })
        .catch(console.error)
        .finally(() => {
          this.selected.load = false
        })
    },
    openModal(address) {
      axiosInstance
        .post('/tokentracker/getAddressInfo', {
          contractAddress: this.erc20Address,
          address,
        })
        .then((res) => {
          this.selected.type = res.data.type || '0'
          this.selected.name = res.data.name || ''
          this.selected._id = res.data._id
          this.selected.address = address
        })
        .catch(console.error)
        .finally(() => {
          this.dialog = true
        })
    },
    fromDate_search(v) {
      this.fromDate = v
      this.menu1 = false
      this.$refs.menu1.save(v)
    },
    toDate_search(v) {
      this.toDate = v
      this.menu2 = false
      this.$refs.menu2.save(v)
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }

      axiosInstance
        .post('/airdrop/list', {
          erc20Address: this.erc20Address,
          offset,
          limit,
          query: this.query,
          times: this.times,
          sort: this.sort,
          idx: 0,
          amount: 0,
          status: '1',
          fromDate: this.fromDate,
          toDate: this.toDate,
        })
        .then((r) => {
          if (r.status == 200) {
            this.list = r.data.list.map((item) => {
              item.createdAt = item.createdAt.substring(0, 19)
              item.updatedAt = item.updatedAt.substring(0, 19)
              const amount = parseFloat(item.amount)
              if (amount > 0) item.amount = Math.round(amount * 10000) / 10000
              return item
            })
            this.totalCount = r.data.totalCount
            this.totalAmount = r.data.totalAmount
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    setTimes(times) {
      this.times = times
    },
    editDescription(item) {
      this.id = item.id
      this.description = item.description
      this.editDescriptionDialog = true
    },
    editMemo(item) {
      this.id = item.id
      this.memo = item.memo
      this.editMemoDialog = true
    },
    modifyDescription() {
      axiosInstance
        .post('/airdrop/updateDescription', {
          erc20Address: this.erc20Address,
          id: this.id,
          description: this.description,
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to modify.')
          }
          this.editDescriptionDialog = false
        })
    },
    modifyMemo() {
      axiosInstance
        .post('/airdrop/updateMemo', {
          erc20Address: this.erc20Address,
          id: this.id,
          memo: this.memo,
        })
        .then((r) => {
          if (r.status == 200) {
            this.list.forEach((item) => {
              if (item.id === this.id) {
                item.memo = this.memo
              }
            })
          } else {
            alert('Failed to modify.')
          }
          this.editMemoDialog = false
        })
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' +
        'times,description,idx,address,amount,transactionHash,status,updatedAt,createdAt\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post('/airdrop/list', {
            erc20Address: this.erc20Address,
            offset: skip,
            limit: 1000,
            query: this.query,
            times: this.times,
            idx: 0,
            amount: 0,
            status: '1',
            fromDate: this.fromDate,
            toDate: this.toDate,
          })

          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              processData += `${item.times},"${item.description}",${item.idx},${item.address},${item.amount},${item.transactionHash},${item.status},${item.updatedAt},${item.createdAt}\n`
            }
          } else {
            return
          }
          skip += data.list.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'manual_transfer_history.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    async download_report() {
      let processData = '\ufeff' + '회차,설명,Count,METAQ,일자\n'

      let cnt = 0
      for (let time of this.timesList) {
        this.progressValue = Math.round((100 * cnt++) / this.timesList.length)

        if (time == 'All') continue
        const { data, status } = await axiosInstance.post('/airdrop/list', {
          erc20Address: this.erc20Address,
          offset: 0,
          limit: 1000,
          query: this.query,
          times: time,
          idx: 0,
          amount: 0,
          status: '1',
          fromDate: this.fromDate,
          toDate: this.toDate,
        })

        if (status == 200) {
          if (data.list && data.list.length > 0) {
            processData += `${time},"${data.list[0].description}",${
              data.totalCount
            },"${Number(
              data.totalAmount
            ).toLocaleString()}",${data.list[0].updatedAt.substring(0, 10)}\n`
          }
        } else {
          return
        }
      }

      this.progressValue = 100

      const blob = new Blob([processData], { type: 'application/plain' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'manual_transfer_report.csv')
      document.body.appendChild(link)
      link.click()
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  beforeMount() {
    axiosInstance
      .get(`/airdrop/getLastRound?erc20Address=${this.erc20Address}`)
      .then(({ data, status }) => {
        if (status == 200) {
          const _lastRound = parseInt(data.lastRound)
          let _list = ['All']
          for (let idx = _lastRound; idx >= 1; idx--) {
            _list.push(idx)
          }
          this.timesList = _list
        }
      })
      .catch((err) => {
        console.error(err)
      })
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
