import { render, staticRenderFns } from "./ERC20DepositHistoryView.vue?vue&type=template&id=2deccbdf&scoped=true"
import script from "./ERC20DepositHistoryView.vue?vue&type=script&lang=js"
export * from "./ERC20DepositHistoryView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2deccbdf",
  null
  
)

export default component.exports