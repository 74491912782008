<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    clipped
    color="primary"
    @update:mini-variant="$emit('input', value)"
  >
    <v-list nav>
      <v-list-item v-if="navLinksSettings.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleSettings = !toggleSettings"
        >
          Settings
        </v-list-item-content>
        <v-list-item-icon
          class="toggle"
          @click="toggleSettings = !toggleSettings"
        >
          <v-icon color="bright" v-if="toggleSettings">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleSettings"
        class="bright--text ml-3"
        v-for="navLink in navLinksSettings"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="erc20Address.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleErc20 = !toggleErc20"
        >
          ERC20
        </v-list-item-content>
        <v-list-item-icon class="toggle" @click="toggleErc20 = !toggleErc20">
          <v-icon color="bright" v-if="toggleErc20">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleErc20"
        class="bright--text ml-3"
        v-for="navLink in navLinksErc20"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="erc721Address.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleErc721 = !toggleErc721"
        >
          ERC721
        </v-list-item-content>
        <v-list-item-icon class="toggle" @click="toggleErc721 = !toggleErc721">
          <v-icon color="bright" v-if="toggleErc721">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleErc721"
        class="bright--text ml-3"
        v-for="navLink in navLinksErc721"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="erc1155Address.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleErc1155 = !toggleErc1155"
        >
          ERC1155
        </v-list-item-content>
        <v-list-item-icon
          class="toggle"
          @click="toggleErc1155 = !toggleErc1155"
        >
          <v-icon color="bright" v-if="toggleErc1155">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleErc1155"
        class="bright--text ml-3"
        v-for="navLink in navLinksErc1155"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="navLinksArena.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleArena = !toggleArena"
        >
          Arena
        </v-list-item-content>
        <v-list-item-icon @click="toggleArena = !toggleArena">
          <v-icon color="bright" v-if="toggleArena">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleArena"
        class="bright--text ml-3"
        v-for="navLink in navLinksArena"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="navLinksBoard.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleBoard = !toggleBoard"
        >
          Dashboard
        </v-list-item-content>
        <v-list-item-icon @click="toggleBoard = !toggleBoard">
          <v-icon color="bright" v-if="toggleBoard">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleBoard"
        class="bright--text ml-3"
        v-for="navLink in navLinksBoard"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="navLinksMetaqData.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleMetaqData = !toggleMetaqData"
        >
          METAQ
        </v-list-item-content>
        <v-list-item-icon @click="toggleMetaqData = !toggleMetaqData">
          <v-icon color="bright" v-if="toggleMetaqData">
            mdi-chevron-left
          </v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleMetaqData"
        class="bright--text ml-3"
        v-for="navLink in navLinksMetaqData"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="navLinksLogData.length > 0">
        <v-list-item-content
          class="menu_title"
          @click="toggleLogData = !toggleLogData"
        >
          Log Data
        </v-list-item-content>
        <v-list-item-icon @click="toggleLogData = !toggleLogData">
          <v-icon color="bright" v-if="toggleLogData">mdi-chevron-left</v-icon>
          <v-icon color="bright" v-else>mdi-chevron-right</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item
        v-show="toggleLogData"
        class="bright--text ml-3"
        v-for="navLink in navLinksLogData"
        :key="navLink.route"
        :to="navLink.route"
        link
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="bright">{{ navLink.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          {{ navLink.text }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'NavMenu',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    menulist: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    drawer: true,
    toggleSettings: false,
    toggleErc20: false,
    toggleErc721: false,
    toggleErc1155: false,
    toggleArena: false,
    toggleBoard: false,
    toggleMetaqData: false,
    toggleLogData: false,
    navLinks: [],
    navLinksSettings: [],
    navLinksErc20: [],
    navLinksErc721: [],
    navLinksErc1155: [],
    navLinksBoard: [],
    navLinksArena: [],
    navLinksMetaqData: [],
    navLinksLogData: [],
    factory: [
      {
        text: 'Blockchain Network',
        route: '/blockchain_network',
        icon: 'mdi-lan-connect',
        enabled: false,
      },
      {
        text: 'API Keys',
        route: '/api-keys',
        icon: 'mdi-key',
        enabled: false,
      },
      {
        text: 'User Registration',
        route: '/user_registration',
        icon: 'mdi-account-check',
        enabled: false,
      },
      {
        text: 'System Wallet',
        route: '/system_wallet',
        icon: 'mdi-wallet-outline',
        enabled: false,
      },
      {
        text: 'Dashboard',
        route: '/dashboard',
        icon: 'mdi-view-dashboard',
        enabled: false,
      },
      {
        text: 'Holders',
        route: '/holders_erc20',
        icon: 'mdi-account-outline',
        enabled: false,
      },
      {
        text: 'Lockup Schedules',
        route: '/lockup_schedules',
        icon: 'mdi-account-outline',
        enabled: false,
      },
      {
        text: 'Transfers',
        route: '/transfers_erc20',
        icon: 'mdi-truck-delivery-outline',
        enabled: false,
      },
      {
        text: 'Transaction',
        route: '/transaction_erc20',
        icon: 'mdi-tag-check-outline',
        enabled: false,
      },
      {
        text: 'Manual Transfer Request',
        route: '/manual_transfer_request',
        icon: 'mdi-note-edit-outline',
        enabled: false,
      },
      {
        text: 'Manual Transfer Processing',
        route: '/manual_transfer_processing',
        icon: 'mdi-ticket-confirmation-outline',
        enabled: false,
      },
      {
        text: 'Manual Transfer History',
        route: '/manual_transfer_history',
        icon: 'mdi-clipboard-text-clock-outline',
        enabled: false,
      },
      {
        text: 'Withdrawal Request',
        route: '/withdrawal_request',
        icon: 'mdi-ticket-confirmation-outline',
        enabled: false,
      },
      {
        text: 'Withdrawal History',
        route: '/withdrawal_history',
        icon: 'mdi-clipboard-text-clock-outline',
        enabled: false,
      },
      {
        text: 'Pending Withdrawal',
        route: '/pending_withrawal',
        icon: 'mdi-link-lock',
        enabled: false,
      },
      {
        text: 'Transaction Status',
        route: '/transaction_status',
        icon: 'mdi-truck-check-outline',
        enabled: false,
      },
      {
        text: 'Withdrawal Restriction',
        route: '/withdrawal_restriction',
        icon: 'mdi-account-cancel',
        enabled: false,
      },
      {
        text: 'Rejection History',
        route: '/rejection_history',
        icon: 'mdi-undo-variant',
        enabled: false,
      },
      {
        text: 'Managed Wallet',
        route: '/skyplay_wallet',
        icon: 'mdi-wallet-outline',
        enabled: false,
      },
      {
        text: 'Deposit List',
        route: '/skyplay_deposit_list',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'Inventory',
        route: '/inventory_erc721',
        icon: 'mdi-format-list-bulleted',
        enabled: false,
      },
      {
        text: 'Holders',
        route: '/holders_erc721',
        icon: 'mdi-account-outline',
        enabled: false,
      },
      {
        text: 'Transfers',
        route: '/transfers_erc721',
        icon: 'mdi-truck-delivery-outline',
        enabled: false,
      },
      {
        text: 'Transaction',
        route: '/transaction_erc721',
        icon: 'mdi-tag-check-outline',
        enabled: false,
      },
      {
        text: 'Minting Request',
        route: '/minting_request_nft721',
        icon: 'mdi-note-edit-outline',
        enabled: false,
      },
      {
        text: 'Minting Process',
        route: '/minting_process_nft721',
        icon: 'mdi-ticket-confirmation-outline',
        enabled: false,
      },
      {
        text: 'Minting History',
        route: '/minting_history_nft721',
        icon: 'mdi-ticket-confirmation-outline',
        enabled: false,
      },
      {
        text: 'Inventory',
        route: '/inventory_erc1155',
        icon: 'mdi-format-list-bulleted',
        enabled: false,
      },
      {
        text: 'Holders',
        route: '/holders_erc1155',
        icon: 'mdi-account-outline',
        enabled: false,
      },
      {
        text: 'Transfers',
        route: '/transfers_erc1155',
        icon: 'mdi-truck-delivery-outline',
        enabled: false,
      },
      {
        text: 'Transaction',
        route: '/transaction_erc1155',
        icon: 'mdi-tag-check-outline',
        enabled: false,
      },
      {
        text: 'Minting Request',
        route: '/minting_request_nft1155',
        icon: 'mdi-note-edit-outline',
        enabled: false,
      },
      {
        text: 'Minting Process',
        route: '/minting_process_nft1155',
        icon: 'mdi-ticket-confirmation-outline',
        enabled: false,
      },
      {
        text: 'File List',
        route: '/file_list',
        icon: 'mdi-file-cabinet',
        enabled: false,
      },
      {
        text: 'Mission',
        route: '/arena_mission',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Tokens',
        route: '/arena_tokens',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Tokens History',
        route: '/arena_tokens_history',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Board',
        route: '/metaplanet_board',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Probit',
        route: '/metaplanet_probit',
        icon: 'mdi-chart-line',
        enabled: false,
      },
      {
        text: 'LBank',
        route: '/metaplanet_lbank',
        icon: 'mdi-chart-line',
        enabled: false,
      },
      {
        text: 'Probit Balance History',
        route: '/metaplanet_balance_probit_history',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'LBank Balance History',
        route: '/metaplanet_balance_lbank_history',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'Probit Order History',
        route: '/metaplanet_probit_order_history',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'LBank Order History',
        route: '/metaplanet_lbank_order_history',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'Probit Deposit',
        route: '/metaplanet_probit_deposit',
        icon: 'mdi-wallet-plus-outline',
        enabled: false,
      },
      {
        text: 'Presale',
        route: '/metaplanet_presale',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Coin Sale',
        route: '/metaplanet_coinsale',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Token',
        route: '/metaplanet_token',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Old Token',
        route: '/metaplanet_oldtoken',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Deposit',
        route: '/metaplanet_LtbDeposit',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'Swap',
        route: '/metaplanet_LtbSwap',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'Transfer METAQ',
        route: '/metaplanet_LtbTransferMetaq',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'Withdraw',
        route: '/metaplanet_LtbWithdraw',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'METAQ',
        route: '/metaplanet_LtbMetaq',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'NFT Staking',
        route: '/metaplanet_LtbNftStaking',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'AQUA',
        route: '/metaplanet_LtbTokens',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'Transfer NFT',
        route: '/metaplanet_LtbTransferNft',
        icon: 'mdi-history',
        enabled: false,
      },
      {
        text: 'Mintit',
        route: '/metaplanet_mintit',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Newsroom',
        route: '/newsroom',
        icon: 'mdi-newspaper-variant-outline',
        enabled: false,
      },
      {
        text: 'Notice List',
        route: '/notice_list',
        icon: 'mdi-bulletin-board',
        enabled: false,
      },
      {
        text: '자산 전송',
        route: '/mcp_issue_transaction',
        icon: 'mdi-home',
        enabled: false,
      },
      {
        text: '승인 대기',
        route: '/mcp_waiting_approval',
        icon: 'mdi-checkbox-multiple-marked',
        enabled: false,
      },
      {
        text: '전송 내역',
        route: '/mcp_transfer_history',
        icon: 'mdi-database-clock',
        enabled: false,
      },
      {
        text: 'MPC 지갑 생성',
        route: '/mcp_create_wallet',
        icon: 'mdi-credit-card-plus',
        enabled: false,
      },
      {
        text: '사용자 등록',
        route: '/mcp_register_address',
        icon: 'mdi-account-plus',
        enabled: false,
      },
      {
        text: '테스트 뷰페이지',
        route: '/testview',
        icon: 'mdi-wallet-outline',
        enabled: false,
      },
    ],
  }),
  methods: {
    updateParentDrawer() {
      this.$emit('drawerWatch', this.drawer)
    },
  },
  watch: {
    value(newVal) {
      this.drawer = newVal
    },
    drawer(/*newVal, oldVal*/) {
      this.updateParentDrawer()
    },
    async menulist() {
      this.navLinksSettings = this.factory.filter((item) => {
        const settingsMenuList = [
          '/telegram_chatbot',
          '/blockchain_network',
          '/api-keys',
          '/user_registration',
          '/system_wallet',
        ]
        if (
          settingsMenuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      this.navLinksErc20 = this.factory.filter((item) => {
        const erc20MenuList = [
          '/dashboard',
          '/holders_erc20',
          '/transaction_erc20',
          '/transfers_erc20',
          '/manual_transfer_request',
          '/manual_transfer_processing',
          '/manual_transfer_history',
          '/withdrawal_request',
          '/withdrawal_history',
          '/pending_withrawal',
          '/rejection_history',
          '/withdrawal_restriction',
          '/skyplay_wallet',
          '/skyplay_deposit_list',
          '/lockup_schedules',
        ]
        if (
          erc20MenuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      this.navLinksErc721 = this.factory.filter((item) => {
        const erc721MenuList = [
          '/transaction_erc721',
          '/transfers_erc721',
          '/holders_erc721',
          '/inventory_erc721',
          '/minting_request_nft721',
          '/minting_process_nft721',
          '/minting_history_nft721',
        ]
        if (
          erc721MenuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      this.navLinksErc1155 = this.factory.filter((item) => {
        const erc1155MenuList = [
          '/transaction_erc1155',
          '/transfers_erc1155',
          '/holders_erc1155',
          '/inventory_erc1155',
          '/metadata_erc1155',
          '/minting_request_nft1155',
          '/minting_process_nft1155',
        ]
        if (
          erc1155MenuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      this.navLinksBoard = this.factory.filter((item) => {
        const menuList = [
          '/metaplanet_board',
          '/metaplanet_mintit',
          '/newsroom',
          '/notice_list',
          '/file_list',
        ]
        if (
          menuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      this.navLinksArena = this.factory.filter((item) => {
        const menuList = [
          '/arena_mission',
          '/arena_tokens',
          '/arena_tokens_history',
        ]
        if (
          menuList.includes(item.route) &&
          this.menulist.includes(item.route)
        ) {
          return true
        }
        return false
      })
      if (this.menulist.includes('/metaplanet_token_log')) {
        // metaplanet_token_log 대표 메뉴 권한
        this.navLinksLogData = this.factory.filter((item) => {
          const logMenuList = [
            '/metaplanet_LtbDeposit',
            '/metaplanet_LtbSwap',
            '/metaplanet_LtbTransferMetaq',
            '/metaplanet_LtbWithdraw',
            '/metaplanet_LtbMetaq',
            '/metaplanet_LtbNftStaking',
            '/metaplanet_LtbTokens',
            '/metaplanet_LtbTransferNft',
          ]

          if (logMenuList.includes(item.route)) {
            return true
          }
          return false
        })
        this.navLinksMetaqData = this.factory.filter((item) => {
          const logMenuList = [
            '/metaplanet_token',
            '/metaplanet_oldtoken',
            '/metaplanet_presale',
            '/metaplanet_coinsale',
            '/metaplanet_probit',
            '/metaplanet_lbank',
            '/metaplanet_probit_deposit',
            // '/metaplanet_probit_order_history',
            // '/metaplanet_lbank_order_history',
            '/metaplanet_balance_probit_history',
            '/metaplanet_balance_lbank_history',
          ]

          if (logMenuList.includes(item.route)) {
            return true
          }
          return false
        })
      }
    },
  },
  computed: {
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
    erc1155Address() {
      return this.$store.getters['auth/erc1155Address']
    },
  },
}
</script>
<style scoped>
.menu_title {
  font-size: 14pt;
  font-weight: bold;
  color: white;
  user-select: none;
  cursor: pointer;
}
.toggle {
  color: white;
  cursor: pointer;
}
</style>
