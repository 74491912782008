export default {
  namespaced: true,
  state: {
    page: 1,
    firstItem: 0,
    itemsPerPage: 10,
  },
  getters: {
    page(state) {
      return state.page
    },
    firstItem(state) {
      return state.firstItem
    },
    itemsPerPage(state) {
      return state.itemsPerPage
    },
  },
  mutations: {
    updateState(state, { page, firstItem, itemsPerPage }) {
      if (page) state.page = page
      if (firstItem) state.firstItem = firstItem
      if (itemsPerPage) state.itemsPerPage = itemsPerPage
    },
  },
  actions: {
    setPagination(context, { page, firstItem, itemsPerPage }) {
      context.commit('updateState', {
        page,
        firstItem,
        itemsPerPage,
      })
    },
  },
}
