<template>
  <div>
    <v-row class="price">
      <v-col cols="12" lg="2">
        <a
          href="https://www.probit.com/app/exchange/METAQ-USDT"
          target="_blank"
        >
          Probit
        </a>
        :
        {{ probit_metaq_price.toFixed(4).toLocaleString() }}
        ({{ probit_24h_change.toFixed(2).toLocaleString() }}%)
      </v-col>
      <v-col cols="12" lg="2">
        <a href="https://www.lbank.com/trade/metaq_usdt/" target="_blank">
          LBank
        </a>
        :
        {{ lbank_metaq_price.toFixed(4).toLocaleString() }}
        ({{ lbank_24h_change.toFixed(2).toLocaleString() }}%)
      </v-col>
      <v-col cols="12" lg="2">
        Price : {{ (probit_metaq_price * fx_usd2krw).toLocaleString() }} 원
      </v-col>
      <v-col cols="12" lg="2">
        <div>KWR/USD : {{ fx_usd2krw.toLocaleString() }}</div>
      </v-col>
      <v-col cols="12" lg="2">
        <div>Spread : {{ spread }}</div>
      </v-col>
      <v-col cols="12" lg="2">
        <v-row class="align-center justify-center">
          <div>거래량 {{ tradingVolume }}%</div>
        </v-row>
        <v-row class="align-center justify-center">
          <v-slider
            v-model="tradingVolume"
            :min="10"
            :max="100"
            :step="10"
            @change="changeTradingVolume"
          ></v-slider>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-row class="justify-space-around">
          <v-text-field
            label="가격"
            v-model="sellPrice"
            type="text"
            clearable
            required
            class="pl-2 pr-2"
          ></v-text-field>
          <v-text-field
            label="수량"
            v-model="amount"
            type="text"
            required
            class="pl-2 pr-2"
          ></v-text-field>
          <v-btn
            color="primary"
            class="font-weight-bold mt-3 ml-2 mr-2"
            @click="sellOrder"
          >
            매도
          </v-btn>
        </v-row>
        <v-row class="justify-space-around mb-2">
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="sellOrderPlus(0.00033)"
          >
            +33
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="sellOrderPlus(0.00027)"
          >
            +27
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="sellOrderPlus(0.00021)"
          >
            +21
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="sellOrderPlus(0.00015)"
          >
            +15
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="sellOrderPlus(0.00009)"
          >
            +9
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="sellOrderMinus(0.00009)"
          >
            -9
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="sellOrderMinus(0.00015)"
          >
            -15
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="sellOrderMinus(0.00021)"
          >
            -21
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="sellOrderMinus(0.00027)"
          >
            -27
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="sellOrderMinus(0.00033)"
          >
            -33
          </v-btn>
        </v-row>
        <v-row>
          <h4>매도</h4>
        </v-row>
        <v-row>
          <table width="99%">
            <thead>
              <tr>
                <th width="13%">가격</th>
                <th width="13%">수량</th>
                <th width="13%">LP1</th>
                <th width="13%">LP2</th>
                <th width="13%">LP3</th>
                <th width="15%">개미물량</th>
                <th width="15%">비용예측(KWR)</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderBookSell" v-bind:key="item.key">
                <td class="sell">
                  {{ item.key }}
                </td>
                <td>{{ Number(item.value) }}</td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP1Sell,
                      openOrdersLP1OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP2Sell,
                      openOrdersLP2OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP3Sell,
                      openOrdersLP3OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    (
                      Number(item.value) -
                      getValue(openOrdersLP1Sell, item.key) -
                      getValue(openOrdersLP2Sell, item.key) -
                      getValue(openOrdersLP3Sell, item.key)
                    ).toLocaleString()
                  }}
                </td>
                <td>
                  {{
                    Math.floor(
                      (Number(item.value) -
                        getValue(openOrdersLP1Sell, item.key) -
                        getValue(openOrdersLP2Sell, item.key) -
                        getValue(openOrdersLP3Sell, item.key)) *
                        Number(item.key) *
                        fx_usd2krw
                    ).toLocaleString()
                  }}
                </td>
                <td>
                  <button
                    v-if="getValue(openOrdersLP1Sell, item.key) != 0"
                    @click="cancelOrder(item.key)"
                  >
                    cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </v-row>
      </v-col>
      <v-col cols="12" lg="6">
        <v-row class="justify-space-around">
          <v-text-field
            label="가격"
            v-model="buyPrice"
            type="text"
            clearable
            required
            class="pl-2 pr-2"
          ></v-text-field>
          <v-text-field
            label="수량"
            v-model="amount"
            type="text"
            required
            class="pl-2 pr-2"
          ></v-text-field>
          <v-btn
            color="primary"
            class="font-weight-bold mt-3 ml-2 mr-2"
            @click="buyOrder"
          >
            매수
          </v-btn>
        </v-row>
        <v-row class="justify-space-around mb-2">
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="buyOrderPlus(0.00033)"
          >
            +33
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="buyOrderPlus(0.00027)"
          >
            +27
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="buyOrderPlus(0.00021)"
          >
            +21
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="buyOrderPlus(0.00015)"
          >
            +15
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold small-button"
            @click="buyOrderPlus(0.00009)"
          >
            +9
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="buyOrderMinus(0.00009)"
          >
            -9
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="buyOrderMinus(0.00015)"
          >
            -15
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="buyOrderMinus(0.00021)"
          >
            -21
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="buyOrderMinus(0.00027)"
          >
            -27
          </v-btn>
          <v-btn
            color="warning"
            class="font-weight-bold small-button"
            @click="buyOrderMinus(0.00033)"
          >
            -33
          </v-btn>
        </v-row>
        <v-row>
          <h4>매수</h4>
        </v-row>
        <v-row>
          <table width="99%">
            <thead>
              <tr>
                <th width="13%">가격</th>
                <th width="13%">수량</th>
                <th width="13%">LP1</th>
                <th width="13%">LP2</th>
                <th width="13%">LP3</th>
                <th width="15%">개미물량</th>
                <th width="15%">비용예측(KWR)</th>
                <th width="5%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderBookBuy" v-bind:key="item.key">
                <td class="buy">
                  {{ item.key }}
                </td>
                <td>{{ Number(item.value) }}</td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP1Buy,
                      openOrdersLP1OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP2Buy,
                      openOrdersLP2OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    displayValue(
                      openOrdersLP3Buy,
                      openOrdersLP3OrigQty,
                      item.key
                    )
                  }}
                </td>
                <td>
                  {{
                    (
                      Number(item.value) -
                      getValue(openOrdersLP1Buy, item.key) -
                      getValue(openOrdersLP2Buy, item.key) -
                      getValue(openOrdersLP3Buy, item.key)
                    ).toLocaleString()
                  }}
                </td>
                <td>
                  {{
                    Math.floor(
                      (Number(item.value) -
                        getValue(openOrdersLP1Buy, item.key) -
                        getValue(openOrdersLP2Buy, item.key) -
                        getValue(openOrdersLP3Buy, item.key)) *
                        item.key *
                        fx_usd2krw
                    ).toLocaleString()
                  }}
                </td>
                <td>
                  <button
                    v-if="getValue(openOrdersLP1Buy, item.key) != 0"
                    @click="cancelOrder(item.key)"
                  >
                    cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'ProbitView',
  data() {
    return {
      interval: null,
      fx_usd2krw: 0,
      probit_metaq_price: 0,
      probit_24h_change: 0,
      lbank_metaq_price: 0,
      lbank_24h_change: 0,
      priceMetaq: 0,
      spread: 0,
      openorder_list: null,
      orderBookSell: null,
      orderBookBuy: null,
      openOrdersLP1Sell: null,
      openOrdersLP1Buy: null,
      openOrdersLP1OrigQty: null,
      openOrdersLP2Sell: null,
      openOrdersLP2Buy: null,
      openOrdersLP2OrigQty: null,
      openOrdersLP3Sell: null,
      openOrdersLP3Buy: null,
      openOrdersLP3OrigQty: null,
      openOrdersIDs: null,
      sellPrice: 0,
      buyPrice: 0,
      amount: Math.round(Math.random() * 300 + 400),
      tradingVolume: 0,
    }
  },
  computed: {},
  watch: {},
  methods: {
    getProbit() {
      axiosInstance
        .post('/metaplanet_token/probit', {})
        .then((result) => {
          if (result.status == 200) {
            this.fx_usd2krw = Number(result.data.fx_usd2krw)
            this.probit_metaq_price = Number(result.data.probit_metaq_price)
            this.lbank_metaq_price = Number(result.data.lbank_metaq_price)
            this.probit_24h_change = Number(result.data.probit_24h_change)
            this.lbank_24h_change = Number(result.data.lbank_24h_change)

            this.openorder_list = result.data.openorder_list
            this.orderBookSell = this.openorder_list
              .filter((item) => item.key.startsWith('probit_sell'))
              .map((item) => {
                item.key = item.key.replace('probit_sell_', '')
                return item
              })
              .sort((a, b) => Number(a.key) - Number(b.key))
            this.orderBookBuy = this.openorder_list
              .filter((item) => item.key.startsWith('probit_buy'))
              .map((item) => {
                item.key = item.key.replace('probit_buy_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))
            this.openOrdersLP1Sell = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp1_sell'))
              .map((item) => {
                item.key = item.key.replace('probit_lp1_sell_', '')
                return item
              })
              .sort((a, b) => Number(a.key) - Number(b.key))
            this.openOrdersLP1Buy = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp1_buy'))
              .map((item) => {
                item.key = item.key.replace('probit_lp1_buy_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))
            this.openOrdersLP1OrigQty = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp1_origQty'))
              .map((item) => {
                item.key = item.key.replace('probit_lp1_origQty_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))

            this.openOrdersLP2Sell = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp2_sell'))
              .map((item) => {
                item.key = item.key.replace('probit_lp2_sell_', '')
                return item
              })
              .sort((a, b) => Number(a.key) - Number(b.key))
            this.openOrdersLP2Buy = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp2_buy'))
              .map((item) => {
                item.key = item.key.replace('probit_lp2_buy_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))
            this.openOrdersLP2OrigQty = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp2_origQty'))
              .map((item) => {
                item.key = item.key.replace('probit_lp2_origQty_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))

            this.openOrdersLP3Sell = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp3_sell'))
              .map((item) => {
                item.key = item.key.replace('probit_lp3_sell_', '')
                return item
              })
              .sort((a, b) => Number(a.key) - Number(b.key))
            this.openOrdersLP3Buy = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp3_buy'))
              .map((item) => {
                item.key = item.key.replace('probit_lp3_buy_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))
            this.openOrdersLP3OrigQty = this.openorder_list
              .filter((item) => item.key.startsWith('probit_lp3_origQty'))
              .map((item) => {
                item.key = item.key.replace('probit_lp3_origQty_', '')
                return item
              })
              .sort((a, b) => Number(b.key) - Number(a.key))

            this.openOrdersIDs = this.openorder_list
              .filter((item) => item.key.startsWith('probit_order_'))
              .map((item) => {
                item.key = item.key.replace('probit_order_', '')
                return item
              })

            this.spread = (
              Number(this.orderBookSell[0].key) -
              Number(this.orderBookBuy[0].key)
            ).toFixed(5)

            this.tradingVolume = result.data.trading_volume
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getValue(orders, price) {
      const found = orders.find((entry) => {
        return Number(entry.key) == Number(price)
      })
      return found ? Number(found.value) : 0
    },
    displayValue(orderQty, origQty, key) {
      const remain = this.getValue(orderQty, key)
      const origin = this.getValue(origQty, key)
      if (remain == origin) {
        return remain.toLocaleString()
      } else {
        return remain.toLocaleString() + ` (${origin.toLocaleString()})`
      }
    },
    sellOrderPlus(delta) {
      const key = this.openOrdersLP1Sell[0].key
      const found = this.openOrdersIDs.find((entry) => {
        return Number(entry.key) == Number(key)
      })

      if (found) {
        const factor = Math.pow(10, 5)
        const sellPrice = Math.round((Number(key) + delta) * factor) / factor

        axiosInstance
          .post('/metaplanet_token/probit_new_order', {
            side: 'sell',
            limit_price: `${sellPrice}`,
            quantity: `${this.amount}`,
          })
          .then((result) => {
            if (result.status == 200) {
              const cancelList = this.openOrdersLP1Sell.filter((el) => {
                return Number(el.key) < sellPrice
              })

              cancelList.forEach((el) => {
                const found = this.openOrdersIDs.find((entry) => {
                  return Number(entry.key) == Number(el.key)
                })
                axiosInstance
                  .post('/metaplanet_token/probit_cancel_order', {
                    order_id: `${found.value}`,
                  })
                  .then((result) => {
                    if (result.status == 200) {
                      // alert('주문 취소가 되었습니다.')
                    }
                  })
              })

              this.amount = Math.round(Math.random() * 300 + 400)
            }
          })
      }
    },
    sellOrder() {
      if (this.sellPrice > 0) {
        axiosInstance
          .post('/metaplanet_token/probit_new_order', {
            side: 'sell',
            limit_price: `${this.sellPrice}`,
            quantity: `${this.amount}`,
          })
          .then((result) => {
            if (result.status == 200) {
              this.sellPrice = 0
            }
          })
      }
    },
    sellOrderMinus(delta) {
      const factor = Math.pow(10, 5)
      const sellPrice =
        Math.round((Number(this.openOrdersLP1Sell[0].key) - delta) * factor) /
        factor

      axiosInstance
        .post('/metaplanet_token/probit_new_order', {
          side: 'sell',
          limit_price: `${sellPrice}`,
          quantity: `${this.amount}`,
        })
        .then((result) => {
          if (result.status == 200) {
            const key = this.openOrdersLP1Sell[0].key
            const found = this.openOrdersIDs.find((entry) => {
              return Number(entry.key) == Number(key)
            })
            if (found) {
              axiosInstance
                .post('/metaplanet_token/probit_cancel_order', {
                  order_id: `${found.value}`,
                })
                .then((result) => {
                  if (result.status == 200) {
                    this.amount = Math.round(Math.random() * 300 + 400)
                    // alert('주문 취소가 되었습니다.')
                  }
                })
            }
          }
        })
    },
    buyOrder() {
      if (this.buyPrice > 0) {
        axiosInstance
          .post('/metaplanet_token/probit_new_order', {
            side: 'buy',
            limit_price: `${this.buyPrice}`,
            quantity: `${this.amount}`,
          })
          .then((result) => {
            if (result.status == 200) {
              this.buyPrice = 0
            }
          })
      }
    },
    buyOrderPlus(delta) {
      const factor = Math.pow(10, 5)
      const buyPrice =
        Math.round((Number(this.openOrdersLP1Buy[0].key) + delta) * factor) /
        factor

      axiosInstance
        .post('/metaplanet_token/probit_new_order', {
          side: 'buy',
          limit_price: `${buyPrice}`,
          quantity: `${this.amount}`,
        })
        .then((result) => {
          if (result.status == 200) {
            const key = this.openOrdersLP1Buy[0].key
            const found = this.openOrdersIDs.find((entry) => {
              return Number(entry.key) == Number(key)
            })
            if (found) {
              axiosInstance
                .post('/metaplanet_token/probit_cancel_order', {
                  order_id: `${found.value}`,
                })
                .then((result) => {
                  if (result.status == 200) {
                    this.amount = Math.round(Math.random() * 300 + 400)
                    // alert('주문 취소가 되었습니다.')
                  }
                })
            }
          }
        })
    },
    buyOrderMinus(delta) {
      const key = this.openOrdersLP1Buy[0].key
      const found = this.openOrdersIDs.find((entry) => {
        return Number(entry.key) == Number(key)
      })
      if (found) {
        const factor = Math.pow(10, 5)
        const buyPrice = Math.round((Number(key) - delta) * factor) / factor
        axiosInstance
          .post('/metaplanet_token/probit_new_order', {
            side: 'buy',
            limit_price: `${buyPrice}`,
            quantity: `${this.amount}`,
          })
          .then((result) => {
            if (result.status == 200) {
              const cancelList = this.openOrdersLP1Buy.filter((el) => {
                return Number(el.key) > buyPrice
              })

              cancelList.forEach((el) => {
                const found = this.openOrdersIDs.find((entry) => {
                  return Number(entry.key) == Number(el.key)
                })
                axiosInstance
                  .post('/metaplanet_token/probit_cancel_order', {
                    order_id: `${found.value}`,
                  })
                  .then((result) => {
                    if (result.status == 200) {
                      // alert('주문 취소가 되었습니다.')
                    }
                  })
              })

              this.amount = Math.round(Math.random() * 300 + 900)
            }
          })
      }
    },
    cancelOrder(key) {
      const found = this.openOrdersIDs.find((entry) => {
        return Number(entry.key) == Number(key)
      })

      if (found) {
        axiosInstance
          .post('/metaplanet_token/probit_cancel_order', {
            order_id: `${found.value}`,
          })
          .then((result) => {
            if (result.status == 200) {
              // alert('주문 취소가 되었습니다.')
            }
          })
      }
    },
    changeTradingVolume() {
      axiosInstance
        .post('/metaplanet_token/probit_set_trading_volume', {
          trading_volume: this.tradingVolume.toString(),
        })
        .then((result) => {
          if (result.status == 200) {
            //
          }
        })
    },
  },
  async beforeMount() {
    this.interval = setInterval(() => {
      this.getProbit()
    }, 1000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style scoped>
a:visited {
  color: #9933cc; /* 방문한 링크의 색상을 변경하세요 */
}
.theme--dark table {
  background-color: rgb(30, 30, 30);
}
table {
  border: 1px solid grey;
  padding: 5px;
  font-size: 11px;
}
th {
  text-align: left;
}
.price {
  font-size: 24px;
  font-weight: bold;
}
.sell {
  color: rgb(242, 93, 78);
}
.buy {
  color: rgb(39, 211, 139);
}
.small-button {
  min-width: 48px !important;
  font-size: 80%; /* Reduce font size */
  padding: 80%; /* Adjust padding */
  /* Optionally adjust height and width */
}
button {
  border: 1px dotted grey;
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
}
button:active {
  transform: translateY(2px);
}
</style>
