import Vue from 'vue'
import VueRouter from 'vue-router'

import AccountView from '@/views/Settings/AccountView.vue'
import AirdropAddView from '@/views/ERC20/AirdropAddView.vue'
import AirdropHistoryView from '@/views/ERC20/AirdropHistoryView.vue'
import AirdropView from '@/views/ERC20/AirdropView.vue'
import APIKeysView from '@/views/Settings/APIKeysView.vue'
import BalanceHistoryLBankView from '@/views/MetaplanetToken/BalanceHistoryLBankView.vue'
import BalanceHistoryProbitView from '@/views/MetaplanetToken/BalanceHistoryProbitView.vue'
import BoardView from '@/views/Metaplanet/BoardView.vue'
import CoinsaleView from '@/views/MetaplanetToken/CoinsaleView.vue'
import CreateWalletView from '@/views/MPC/CreateWalletView.vue'
import DashboardView from '@/views/ERC20/DashboardView.vue'
import ERC1155HolderView from '@/views/ERC1155/ERC1155HolderView.vue'
import ERC1155InventoryView from '@/views/ERC1155/ERC1155InventoryView.vue'
import ERC1155TransactionView from '@/views/ERC1155/ERC1155TransactionView.vue'
import ERC1155TransferView from '@/views/ERC1155/ERC1155TransferView.vue'
import ERC20DepositHistoryView from '@/views/ERC20/ERC20DepositHistoryView.vue'
import ERC20HolderView from '@/views/ERC20/ERC20HolderView.vue'
import ERC20LockupSchedules from '@/views/ERC20/ERC20LockupSchedules.vue'
import ERC20TransactionStatusView from '@/views/ERC20/ERC20TransactionStatusView.vue'
import ERC20TransactionView from '@/views/ERC20/ERC20TransactionView.vue'
import ERC20TransferView from '@/views/ERC20/ERC20TransferView.vue'
import ERC20WithdrawBlacklistView from '@/views/ERC20/ERC20WithdrawBlacklistView.vue'
import ERC20WithdrawHistoryView from '@/views/ERC20/ERC20WithdrawHistoryView.vue'
import ERC20WithdrawHoldView from '@/views/ERC20/ERC20WithdrawHoldView.vue'
import ERC20WithdrawRefusalView from '@/views/ERC20/ERC20WithdrawRefusalView.vue'
import ERC20WithdrawView from '@/views/ERC20/ERC20WithdrawView.vue'
import ERC721HolderView from '@/views/ERC721/ERC721HolderView.vue'
import ERC721InventoryView from '@/views/ERC721/ERC721InventoryView.vue'
import ERC721TransactionView from '@/views/ERC721/ERC721TransactionView.vue'
import ERC721TransferView from '@/views/ERC721/ERC721TransferView.vue'
import GameNoticeView from '@/views/SKYPlay/GameNoticeView.vue'
import IssueTransactionView from '@/views/MPC/IssueTransactionView.vue'
import LBankOrderHistoryView from '@/views/MetaplanetToken/LBankOrderHistoryView.vue'
import LBankView from '@/views/MetaplanetToken/LBankView.vue'
import LtbDepositView from '@/views/MetaplanetLog/LtbDepositView.vue'
import LtbMetaqView from '@/views/MetaplanetLog/LtbMetaqView.vue'
import LtbNftStakingView from '@/views/MetaplanetLog/LtbNftStakingView.vue'
import LtbSwapView from '@/views/MetaplanetLog/LtbSwapView.vue'
import LtbTokensView from '@/views/MetaplanetLog/LtbTokensView.vue'
import LtbTransferMetaqView from '@/views/MetaplanetLog/LtbTransferMetaqView.vue'
import LtbTransferNftView from '@/views/MetaplanetLog/LtbTransferNftView.vue'
import LtbWithdrawView from '@/views/MetaplanetLog/LtbWithdrawView.vue'
import MintitView from '@/views/Metaplanet/MintitView.vue'
import MissionView from '@/views/Arena/MissionView.vue'
import NetworkView from '@/views/Settings/NetworkView.vue'
import NFT1155AddView from '@/views/ERC1155/NFT1155AddView.vue'
import NFT1155MintView from '@/views/ERC1155/NFT1155MintView.vue'
import NFT721AddView from '@/views/ERC721/NFT721AddView.vue'
import NFT721MintHistoryView from '@/views/ERC721/NFT721MintHistoryView.vue'
import NFT721MintView from '@/views/ERC721/NFT721MintView.vue'
import OldTokenView from '@/views/MetaplanetToken/OldTokenView.vue'
import PresaleView from '@/views/MetaplanetToken/PresaleView.vue'
import PressReleaseView from '@/views/SKYPlay/PressReleaseView.vue'
import ProbitDepositView from '@/views/MetaplanetToken/ProbitDepositView.vue'
import ProbitOrderHistoryView from '@/views/MetaplanetToken/ProbitOrderHistoryView.vue'
import ProbitView from '@/views/MetaplanetToken/ProbitView.vue'
import RegisterAddressView from '@/views/MPC/RegisterAddressView.vue'
import RulesView from '@/views/ERC20/RulesView.vue'
import SKYPlayWalletView from '@/views/ERC20/SKYPlayWalletView.vue'
import SystemWalletView from '@/views/Settings/SystemWalletView.vue'
import TestView from '@/views/Test/TestView.vue'
import TokensHistoryView from '@/views/Arena/TokensHistoryView.vue'
import TokensView from '@/views/Arena/TokensView.vue'
import TokenView from '@/views/MetaplanetToken/TokenView.vue'
import TransferHistoryView from '@/views/MPC/TransferHistoryView.vue'
import TUSUploadListView from '@/views/SKYPlay/TUSUploadListView.vue'
import WaitingApprovalView from '@/views/MPC/WaitingApprovalView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/mcp_create_wallet',
    name: 'CreateWalletView',
    component: CreateWalletView,
  },
  {
    path: '/mcp_issue_transaction',
    name: 'IssueTransactionView',
    component: IssueTransactionView,
  },
  {
    path: '/mcp_waiting_approval',
    name: 'WaitingApprovalView',
    component: WaitingApprovalView,
  },
  {
    path: '/mcp_transfer_history',
    name: 'TransferHistoryView',
    component: TransferHistoryView,
  },
  {
    path: '/mcp_register_address',
    name: 'RegisterAddressView',
    component: RegisterAddressView,
  },
  {
    path: '/newsroom',
    name: 'PressReleaseView',
    component: PressReleaseView,
  },
  {
    path: '/notice_list',
    name: 'GameNoticeView',
    component: GameNoticeView,
  },
  {
    path: '/blockchain_network',
    name: 'NetworkView',
    component: NetworkView,
  },
  {
    path: '/user_registration',
    name: 'AccountView',
    component: AccountView,
  },
  {
    path: '/manual_transfer_request',
    name: 'AirdropAddView',
    component: AirdropAddView,
  },
  {
    path: '/manual_transfer_processing',
    name: 'AirdropView',
    component: AirdropView,
  },
  {
    path: '/manual_transfer_history',
    name: 'AirdropHistoryView',
    component: AirdropHistoryView,
  },
  {
    path: '/api-keys',
    name: 'APIKeysView',
    component: APIKeysView,
  },
  {
    path: '/system_wallet',
    name: 'SystemWalletView',
    component: SystemWalletView,
  },
  {
    path: '/withdrawal_request',
    name: 'ERC20WithdrawView',
    component: ERC20WithdrawView,
  },
  {
    path: '/withdrawal_restriction',
    name: 'ERC20WithdrawBlacklistView',
    component: ERC20WithdrawBlacklistView,
  },
  {
    path: '/withdrawal_history',
    name: 'ERC20WithdrawHistoryView',
    component: ERC20WithdrawHistoryView,
  },
  {
    path: '/pending_withrawal',
    name: 'ERC20WithdrawHoldView',
    component: ERC20WithdrawHoldView,
  },
  {
    path: '/transaction_status',
    name: 'ERC20TransactionStatusView',
    component: ERC20TransactionStatusView,
  },
  {
    path: '/rejection_history',
    name: 'ERC20WithdrawRefusalView',
    component: ERC20WithdrawRefusalView,
  },
  {
    path: '/skyplay_wallet',
    name: 'SKYPlayWalletView',
    component: SKYPlayWalletView,
  },
  {
    path: '/skyplay_deposit_list',
    name: 'ERC20DepositHistoryView',
    component: ERC20DepositHistoryView,
  },
  {
    path: '/minting_request_nft1155',
    name: 'NFT1155AddView',
    component: NFT1155AddView,
  },
  {
    path: '/minting_process_nft1155',
    name: 'NFT1155MintView',
    component: NFT1155MintView,
  },
  {
    path: '/minting_request_nft721',
    name: 'NFT721AddView',
    component: NFT721AddView,
  },
  {
    path: '/minting_process_nft721',
    name: 'NFT721MintView',
    component: NFT721MintView,
  },
  {
    path: '/minting_history_nft721',
    name: 'NFT721MintHistoryView',
    component: NFT721MintHistoryView,
  },
  {
    path: '/file_list',
    name: 'TUSUploadListView',
    component: TUSUploadListView,
  },
  {
    path: '/dashboard',
    name: 'DashboardView',
    component: DashboardView,
  },
  {
    path: '/transfers_erc20',
    name: 'ERC20TransferView',
    component: ERC20TransferView,
  },
  {
    path: '/holders_erc20',
    name: 'ERC20HolderView',
    component: ERC20HolderView,
  },
  {
    path: '/lockup_schedules',
    name: 'ERC20LockupSchedules',
    component: ERC20LockupSchedules,
  },
  {
    path: '/transaction_erc20',
    name: 'ERC20TransactionView',
    component: ERC20TransactionView,
  },
  {
    path: '/transfers_erc721',
    name: 'ERC721TransferView',
    component: ERC721TransferView,
  },
  {
    path: '/holders_erc721',
    name: 'ERC721HolderView',
    component: ERC721HolderView,
  },
  {
    path: '/inventory_erc721',
    name: 'ERC721InventoryView',
    component: ERC721InventoryView,
  },
  {
    path: '/transaction_erc721',
    name: 'ERC721TransactionView',
    component: ERC721TransactionView,
  },
  {
    path: '/transfers_erc1155',
    name: 'ERC1155TransferView',
    component: ERC1155TransferView,
  },
  {
    path: '/holders_erc1155',
    name: 'ERC1155HolderView',
    component: ERC1155HolderView,
  },
  {
    path: '/inventory_erc1155',
    name: 'ERC1155InventoryView',
    component: ERC1155InventoryView,
  },
  {
    path: '/transaction_erc1155',
    name: 'ERC1155TransactionView',
    component: ERC1155TransactionView,
  },
  {
    path: '/token_tracker_rules',
    name: 'RulesView',
    component: RulesView,
  },
  {
    path: '/arena_mission',
    name: 'MissionView',
    component: MissionView,
  },
  {
    path: '/arena_tokens',
    name: 'TokensView',
    component: TokensView,
  },
  {
    path: '/arena_tokens_history',
    name: 'TokensHistoryView',
    component: TokensHistoryView,
  },
  {
    path: '/metaplanet_board',
    name: 'BoardView',
    component: BoardView,
  },
  {
    path: '/metaplanet_presale',
    name: 'PresaleView',
    component: PresaleView,
  },
  {
    path: '/metaplanet_coinsale',
    name: 'CoinsaleView',
    component: CoinsaleView,
  },
  {
    path: '/metaplanet_token',
    name: 'TokenView',
    component: TokenView,
  },
  {
    path: '/metaplanet_oldtoken',
    name: 'OldTokenView',
    component: OldTokenView,
  },
  {
    path: '/metaplanet_probit',
    name: 'ProbitView',
    component: ProbitView,
  },
  {
    path: '/metaplanet_probit_deposit',
    name: 'ProbitDepositView',
    component: ProbitDepositView,
  },
  {
    path: '/metaplanet_probit_order_history',
    name: 'ProbitOrderHistoryView',
    component: ProbitOrderHistoryView,
  },
  {
    path: '/metaplanet_lbank',
    name: 'LBankView',
    component: LBankView,
  },
  {
    path: '/metaplanet_lbank_order_history',
    name: 'LBankOrderHistoryView',
    component: LBankOrderHistoryView,
  },
  {
    path: '/metaplanet_balance_lbank_history',
    name: 'BalanceHistoryLBankView',
    component: BalanceHistoryLBankView,
  },
  {
    path: '/metaplanet_balance_probit_history',
    name: 'BalanceHistoryProbitView',
    component: BalanceHistoryProbitView,
  },
  {
    path: '/metaplanet_mintit',
    name: 'MintitView',
    component: MintitView,
  },
  {
    path: '/metaplanet_LtbDeposit',
    name: 'LtbDepositView',
    component: LtbDepositView,
  },
  {
    path: '/metaplanet_LtbSwap',
    name: 'LtbSwapView',
    component: LtbSwapView,
  },
  {
    path: '/metaplanet_LtbTransferMetaq',
    name: 'LtbTransferMetaqView',
    component: LtbTransferMetaqView,
  },
  {
    path: '/metaplanet_LtbWithdraw',
    name: 'LtbWithdrawView',
    component: LtbWithdrawView,
  },
  {
    path: '/metaplanet_LtbMetaq',
    name: 'LtbMetaqView',
    component: LtbMetaqView,
  },
  {
    path: '/metaplanet_LtbNftStaking',
    name: 'LtbNftStakingView',
    component: LtbNftStakingView,
  },
  {
    path: '/metaplanet_LtbTokens',
    name: 'LtbTokensView',
    component: LtbTokensView,
  },
  {
    path: '/metaplanet_LtbTransferNft',
    name: 'LtbTransferNftView',
    component: LtbTransferNftView,
  },
  {
    path: '/testview',
    name: 'TestView',
    component: TestView,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
