<template>
  <div>
    <v-row>
      <v-col cols="12" lg="6">
        <div class="btn-row">
          <v-file-input
            id="file-input"
            show-size
            outlined
            label="Choose File"
            @click:clear="clearList"
          ></v-file-input>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="btn-row"
                @click="dialog = true"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </template>
            <span>Upload list</span>
          </v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" lg="6">
        <div class="btn-row">
          <v-text-field
            label="Address*"
            v-model="address"
            single-line
            required
          ></v-text-field>
          <v-text-field
            label="Amount*"
            v-model="amount"
            single-line
            type="number"
            required
          ></v-text-field>
          <v-btn class="btn-row" @click="addItem">Add</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="count"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="idx"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.address }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <div>{{ Number(item.amount).toLocaleString() }}</div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <pre>
# Creating a List of Receiving Addresses and Amounts to Update Manual Transfer Information

# Receiving Address, Amount
0x0645Af43088813C44399B79FF45896D68eB23b8F, 100
0x32a4598fCB7272C022557B28dFb8021b6df53F55, 100
0x266DCC67c8d42fA16C0E3e57744A7FB7288A3100, 100
0x5c40f86456fcde03f984a7bae86de7fb9eb7cae5, 100</pre
      >
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5">등록</span>
        </v-card-title>
        <v-card-text>
          <div>
            <v-row cols="12">
              <v-col cols="12">
                <v-text-field
                  label="Round*"
                  v-model="times"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Description"
                  v-model="description"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  class="system-wallet"
                  :items="selectWalletList"
                  v-model="systemWallet"
                  label="System Wallet"
                ></v-select>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="postList">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Web3 from 'web3'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'AirdropAddView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Sequential', value: 'idx', sortable: false, width: '15%' },
        { text: 'Address', value: 'address', sortable: false, width: '17%' },
        { text: 'Amount', value: 'amount', sortable: false, width: '15%' },
      ],
      firstItem: 0,
      page: 1,
      lastRound: 0,
      displist: [],
      count: 0,
      totalList: [],
      description: '',
      times: 0,
      dialog: false,
      address: null,
      amount: null,
      selectWalletList: [],
      systemWalletList: [],
      systemWallet: '',
    }
  },
  computed: {
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
    contractAddress() {
      return this.$store.getters['auth/contractAddress']
    },
  },
  watch: {
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          itemsPerPage,
        })

        this.page = page
        this.firstItem = (page - 1) * itemsPerPage
        this.getList()
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    getList() {
      this.displist = this.totalList.slice(
        this.firstItem,
        this.firstItem + this.itemsPerPage
      )
    },
    addItem() {
      this.times = this.lastRound + 1

      if (Web3.utils.isAddress(this.address.trim()) == false) {
        alert(`invalid address : ${this.address}`)
        return
      }

      if (isNaN(this.amount.trim())) {
        alert(`invalid amount : ${this.amount}`)
        return
      }

      const idx = this.totalList.length + 1
      this.totalList.push({
        idx: idx,
        address: this.address,
        amount: this.amount,
      })
      this.count++
      this.getList()
    },
    readSingleFile(e) {
      const file = e.target.files[0]
      if (!file) {
        return
      }

      this.times = this.lastRound + 1

      let _list = []
      let _index = 1
      let _totalCount = 0

      const reader = new FileReader()
      reader.onload = (e) => {
        const contents = e.target.result
        const lineArr = contents.split('\n')
        for (let line of lineArr) {
          if (line.length == 0) {
            // last line
            continue
          }
          const itemArr = line.split(',')
          if (itemArr.length < 2) {
            alert(`invalid line : ${_index} - ${line}`)
            _index++
            continue
          }
          if (Web3.utils.isAddress(itemArr[0].trim()) == false) {
            alert(`invalid address : line ${_index} - ${line}`)
            _index++
            continue
          }
          if (isNaN(itemArr[1].trim())) {
            alert(`invalid amount : line ${_index} - ${line}`)
            _index++
            continue
          }
          _list.push({
            idx: _totalCount + 1,
            address: itemArr[0].trim().replace('\r', ''),
            amount: itemArr[1].trim().replace('\r', ''),
          })
          _index++
          _totalCount++
        }
        this.totalList = _list
        this.count = _totalCount

        this.getList()
      }

      reader.readAsText(file)
    },
    clearList() {
      this.displist = []
      this.count = 0
    },
    postList() {
      let systemWallet = ''
      if (this.systemWallet.length == 0) {
        return alert('A system wallet is required')
      } else {
        const result = this.systemWalletList.find(
          (it) =>
            it.description == this.systemWallet ||
            it.address == this.systemWallet
        )
        if (result != undefined) {
          systemWallet = result.address
        }
      }

      this.dialog = false

      axiosInstance
        .post('/airdrop/postList', {
          erc20Address: this.erc20Address,
          times: this.times, // 입력 대화상자
          description: this.description, // 입력 대화상자
          systemWallet: systemWallet,
          count: parseInt(this.count),
          list: this.totalList,
        })
        .then((r) => {
          if (r.status != 200) {
            return alert('업로드 실패했습니다.')
          }
          this.clearList()
        })
        .catch((err) => {
          console.error(err)
        })
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  mounted() {
    axiosInstance
      .get(`/airdrop/getLastRound?erc20Address=${this.erc20Address}`)
      .then((r) => {
        if (r.status == 200) {
          this.lastRound = r.data.lastRound
        }
      })
      .catch((err) => {
        console.error(err)
      })
    document
      .getElementById('file-input')
      .addEventListener('change', this.readSingleFile, false)
  },
  async beforeMount() {
    try {
      const result = await axiosInstance.post('/wallet/getSystemWalletList', {
        contractAddress: this.contractAddress,
        offset: 0,
        sort: 'description_true',
      })
      if (result.status == 200) {
        this.systemWalletList = result.data.hotWalletList.filter(
          (item) =>
            item.tokenType == 'erc20' &&
            item.contractAddress == this.erc20Address &&
            item.description.length > 0
        )

        this.selectWalletList = this.systemWalletList.map(
          (item) => item.description
        )
      }
    } catch (err) {
      console.error(err)
    }
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
</style>
