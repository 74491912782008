<template>
  <div>
    <form id="smarteditor" action="postPressRelease" method="post">
      <div style="min-height: 450px">
        <textarea
          :name="elPlaceHolder"
          :id="elPlaceHolder"
          rows="20"
          cols="10"
          placeholder="준비중입니다."
          style="
            width: 100%;
            background: #000;
            height: 1000px;
            visibility: hidden;
          "
        ></textarea>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'SmartEditor',
  props: {
    editorProp: {
      type: Array,
      required: true,
    },
    contentsProp: {
      type: String,
      required: false,
    },
    isModify: {
      type: Boolean,
      required: false,
    },
    elPlaceHolder: {
      type: String,
      required: true,
    },
  },
  watch: {
    contentsProp() {
      if (this.isModify == false) return
      setTimeout(() => this.setContents(), 1000)
    },
  },
  beforeCreate() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', '/smarteditor/js/HuskyEZCreator.js')
    document.head.appendChild(recaptchaScript)
  },
  mounted() {
    setTimeout(() => this.setContents(), 1000)
    if (window.nhn) {
      this.initializeEditor()
    } else {
      setTimeout(() => this.initializeEditor(), 500)
    }
  },
  methods: {
    initializeEditor() {
      window.nhn.husky.EZCreator.createInIFrame({
        oAppRef: this.editorProp,
        elPlaceHolder: this.elPlaceHolder,
        sSkinURI: '/smarteditor/SmartEditor2Skin.html',
        fCreator: 'createSEditor2',
        htParams: {
          aAdditionalFontList: [['Noto Sans KR', 'Noto Sans']],
        },
      })
    },
    setContents() {
      // if (this.isModify == false) return
      if (!this.contentsProp) return
      this.editorProp?.getById[this.elPlaceHolder].exec('SET_IR', [''])
      this.editorProp?.getById[this.elPlaceHolder].exec('PASTE_HTML', [
        this.contentsProp,
      ])
    },
  },
}
</script>

<style>
#smarteditor iframe {
  background: #fff !important;
}
</style>
