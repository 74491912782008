import { render, staticRenderFns } from "./ERC1155InventoryView.vue?vue&type=template&id=4a73e874&scoped=true"
import script from "./ERC1155InventoryView.vue?vue&type=script&lang=js"
export * from "./ERC1155InventoryView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a73e874",
  null
  
)

export default component.exports