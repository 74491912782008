import axios from 'axios'
import jwt_decode from 'jwt-decode'
import dayjs from 'dayjs'
import config from '@/config'
import { store } from '@/store'

const baseURL = config.baseURL

const axiosInstance = axios.create({
  baseURL,
})

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    // Do something before request is sent

    const authTokens = localStorage.getItem('authTokens')
    if (authTokens == null) return config

    const user = jwt_decode(authTokens)
    const diffInMinute = dayjs.unix(user.exp).diff(dayjs(), 'minute')
    if (diffInMinute > 0) {
      config['headers'] = {
        Authorization: `Bearer ${authTokens}`,
      }
      return config
    }

    const response = await axios.get('/api/auth/refresh-token', {
      headers: {
        Authorization: `Bearer ${authTokens}`,
      },
    })
    localStorage.setItem('authTokens', response.data.token)
    config['headers'] = {
      Authorization: `Bearer ${response.data.token}`,
    }

    localStorage.removeItem('walletconnect')
    localStorage.removeItem('authTokens')
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    store.commit('error/setValidationError', {})
    return response
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 422) {
      store.commit('error/setValidationError', error.response.data.data)
    } else {
      return Promise.reject(error)
    }
  }
)

export default axiosInstance
