<template>
  <div class="wrapper">
    <h2 class="page_title">Rules</h2>
    <div class="content_body">
      <div class="option_wrap mb-5">
        <v-btn depressed dark @click="createForm">Add Rules</v-btn>
      </div>
      <div class="list rules">
        <div class="list_header">
          <div class="cell cell_name">규칙명</div>
        </div>
        <div class="list_body">
          <div
            class="list_row"
            v-for="(item, index) in list"
            :key="`rule${index}`"
          >
            <div class="cell cell_name">{{ item.name }}</div>
            <div class="cell cell_option">
              <v-btn
                depressed
                small
                color="success"
                @click="updateForm(item._id)"
              >
                수정
              </v-btn>
              <v-btn
                depressed
                small
                color="error"
                class="ml-3"
                @click="remove(item)"
              >
                삭제
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" max-width="375px">
        <v-card class="modal deal">
          <div class="modal_title">Deal Details</div>
          <div class="pa-3">
            <div class="box">
              <div class="label">규칙.</div>
              <div class="modal_input">
                <v-select
                  :items="options"
                  v-model="selected.type"
                  solo
                  hide-details
                  label="Select"
                ></v-select>
              </div>
            </div>
            <!-- 갯수 -->
            <div :class="{ 'd-none': selected.type !== '0' }">
              <div class="box">
                <div class="label">규칙명.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.name"
                    solo
                    hide-details
                    label="Enter Alias"
                  ></v-text-field>
                </div>
              </div>
              <div class="box">
                <div class="label">갯수.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.data.v"
                    solo
                    hide-details
                    label="Enter Alias"
                    type="number"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- 횟수 -->
            <div :class="{ 'd-none': selected.type !== '1' }">
              <div class="box">
                <div class="label">규칙명.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.name"
                    solo
                    hide-details
                    label="Enter Alias"
                  ></v-text-field>
                </div>
              </div>
              <div class="box">
                <div class="label">기간 (초단위).</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.data.s"
                    solo
                    hide-details
                    label="Enter Alias"
                    type="number"
                  ></v-text-field>
                </div>
              </div>
              <div class="box">
                <div class="label">횟수.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.data.v"
                    solo
                    hide-details
                    label="Enter Alias"
                    type="number"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <!-- 지갑주소 -->
            <div :class="{ 'd-none': selected.type !== '2' }">
              <div class="box">
                <div class="label">규칙명.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.name"
                    solo
                    hide-details
                    label="Enter Alias"
                  ></v-text-field>
                </div>
              </div>
              <div class="box">
                <div class="label">지갑주소.</div>
                <div class="modal_input">
                  <v-text-field
                    v-model="selected.data.v"
                    solo
                    hide-details
                    label="Enter Alias"
                  ></v-text-field>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <v-btn @click="submit" block color="bk" dark height="40px">
                Submit
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  data() {
    return {
      options: [
        { text: '갯수', value: '0' },
        { text: '횟수', value: '1' },
        { text: '지갑주소', value: '2' },
      ],
      dialog: false,
      list: [],
      selected: {
        name: '',
        type: '',
        data: {},
      },
    }
  },
  methods: {
    getList() {
      axiosInstance
        .get(`/tokentracker/ruleList?contractAddress=${this.erc20Address}`)
        .then((res) => {
          this.list = res.data.list
        })
        .catch(console.error)
    },
    createForm() {
      this.selected = {
        name: `규칙 ${this.list.length + 1}`,
        type: '0',
        data: {},
      }

      this.dialog = true
    },
    updateForm(_id) {
      axiosInstance
        .get('/tokentracker/ruleView', {
          params: { contractAddress: this.erc20Address, id: _id },
        })
        .then((res) => {
          this.selected = {
            _id,
            name: res.data.name,
            type: res.data.type,
            data: res.data.data,
          }
          this.dialog = true
        })
        .catch(console.error)
    },
    remove(item) {
      if (!confirm(`${item.name} 을 삭제 하시겠습니까?`)) {
        return
      }

      axiosInstance
        .post('/tokentracker/removeRule', {
          contractAddress: this.erc20Address,
          id: item._id,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            // this.$toast.success('success')
            this.getList()
          }
        })
        .catch(console.erroe)
    },
    submit() {
      let { _id, data, name, type } = this.selected

      axiosInstance
        .post(`/tokentracker/${_id ? 'updateRule' : 'createRule'}`, {
          contractAddress: this.erc20Address,
          id: _id,
          data,
          name,
          type,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            // this.$toast.success('success')
            this.getList()
          }
        })
        .catch(console.erroe)
        .finally(() => {
          this.dialog = false
        })
    },
  },
  mounted() {
    this.getList()
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
<style scoped></style>
