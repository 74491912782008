<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="tusList"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="tusCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div
            class="image"
            @click="detailLink(`${fileServer}/${item.id}`, '_blank')"
          >
            <v-img
              :src="item.image"
              :alt="item.fileName"
              width="45px"
              class="ml-0"
            ></v-img>
          </div>
        </template>
        <template v-slot:[`item.id`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">{{ item.id }}</div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(`${item.id}`)"
                class="copy"
                ref="${item.id}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <button v-on:click="detailLink(`${fileServer}/${item.id}`, '_blank')">
            <v-icon>mdi-link</v-icon>
          </button>
        </template>
      </v-data-table>
    </v-row>
    <drag-drop
      class="drag-drop"
      :uppy="uppy"
      :props="{
        locale: {
          strings: {
            chooseFile: 'Boop a file',
            orDragDrop: 'or yoink it here',
          },
        },
      }"
    />
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { DragDrop } from '@uppy/vue'
import config from '@/config'

export default {
  name: 'TUSUploadListView',
  components: {
    DragDrop,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Seq', value: 'seq', width: '8%' },
        {
          text: 'Image',
          value: 'image',
          sortable: false,
          width: '5%',
        },
        { text: 'MIME Type', value: 'fileType', width: '15%' },
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'fileName' },
        { text: 'Size', value: 'fileSize', width: '15%' },
        { text: 'Created Date', value: 'dateCreated', width: '13%' },
        { text: '', value: 'link', sortable: false, width: '34px' },
      ],
      query: '',
      sort: '',
      fileServer: config.fileServer,
      tusCount: 0,
      tusList: [],
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  watch: {
    query() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  computed: {
    uppy: function () {
      return new Uppy({
        id: 'uppy1',
        autoProceed: true,
        debug: false,
        meta: { address: this.address },
        //        restrictions: {
        //          maxFileSize: 2097152, // 2M
        //          allowedFileTypes: ['image/*'],
        //        },
      })
        .use(Tus, {
          endpoint: '/files/',
        })
        .on('upload-success', () => {
          this.getList()
        })
        .on('restriction-failed', (_, error) => {
          alert(error.message)
        })
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
    address() {
      return this.$store.getters['auth/address']
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }

      axiosInstance
        .post('/tus/tusList', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((result) => {
          if (result.status == 200) {
            const tusList = result.data.tusList.map((item) => {
              if (item.fileType.startsWith('image/'))
                item.image = `/files/${item.id}`
              return item
            })
            this.tusCount = result.data.totalCount
            this.tusList = tusList
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'seq,id,fileName,fileType,fileSize,type,dateCreated\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post('/tus/tusList', {
            offset: skip,
            limit: 1000,
            query: this.query,
            sort: this.sort,
          })
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.tusList) {
              processData += `${item.seq},${this.fileServer}/${item.id},"${item.fileName}",${item.fileType},${item.fileSize},${item.type},${item.dateCreated}\n`
            }
          } else {
            return
          }
          skip += data.tusList.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'tus_list.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    detailLink(link) {
      window.open(link, '_blank')
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
  },
  mounted() {
    this.getList()
  },
  beforeMount() {
    this.interval = setInterval(() => {
      this.getList()
    }, 3000)
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style src="@uppy/drag-drop/dist/style.css"></style>
<style src="@uppy/progress-bar/dist/style.css"></style>

<style scoped>
.drag-drop {
  margin: 20px;
  width: 300px;
  height: 100px;
}
.image:hover {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
</style>
