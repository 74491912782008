<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="6">
        <v-text-field clearable v-model="keyword" label="Search" class="mr-4" />
      </v-col>
      <v-col cols="12" lg="2">
        <div class="btn-row">
          <v-btn @click="createWallet()">Create Wallet</v-btn>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          Sum: {{ Number(totalAmount).toLocaleString() }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="address"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <div class="hover_address" style="color: #4caf50">
              {{ item.address }}
            </div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.description`]="{ item }">
          <div class="hover_area">
            <div class="hover_address">
              {{ item.description }}
            </div>
            <div class="hover_inner">
              <v-btn @click="editContract(item)" class="copy">
                <v-icon>mdi-lead-pencil</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.contractAddress`]="{ item }">
          <div class="hover_area">
            <div class="hover_address" style="color: #4caf50">
              {{ item.contractAddress }}
            </div>
            <div class="hover_inner">
              <v-btn
                @click="addrCopy(item.contractAddress)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="
                  networkExplorer(`address/${item.contractAddress}`, 'erc20')
                "
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
          <v-checkbox
            class="enabled-check"
            :input-value="item.enabled"
            color="icon"
            @change="check(item, $event)"
          ></v-checkbox>
        </template>
        <template v-slot:[`item.skp`]="{ item }">
          <div>{{ Number(item.skp).toLocaleString() }}</div>
        </template>
      </v-data-table>
    </v-row>
    <v-row>
      <pre>
Create Wallet : Create Hot Wallet Account
Enabled : Activate</pre
      >
    </v-row>
    <v-dialog v-model="createWalletDialog" persistent max-width="450px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Create Wallet</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Description"
                v-model="description"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="createWalletDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="generateSystemWallet"
          >
            Generate
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editContractDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">Description</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="description" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="editContractDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="modifyContract"
          >
            Modify
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import Web3 from 'web3'
import { scanExplorer, endpointURL } from '@/utils/network'

export default {
  name: 'SystemWalletView',
  data() {
    return {
      options: {},
      headers: [
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Description', value: 'description' },
        { text: 'Contract Address', value: 'contractAddress', width: '17%' },
        { text: 'Token Type', value: 'tokenType', width: '10%' },
        { text: 'Balance', value: 'matic', sortable: false, width: '5%' },
        { text: 'Quantity', value: 'skp', sortable: false, width: '15%' },
        { text: '', value: 'enabled', sortable: false, width: '34px' },
      ],
      totalCount: 0,
      displist: [],
      keyword: '',
      sort: '',
      address: '',
      description: '',
      editContractDialog: false,
      createWalletDialog: false,
      chainId: 137,
      web3: null,
      contract: null,
      tokenType: '',
      totalAmount: 0,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
    contractList() {
      return this.$store.getters['auth/contractList']
    },
    contractAddress() {
      return this.$store.getters['auth/contractAddress']
    },
  },
  watch: {
    keyword() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList(page, itemsPerPage)
      },
      deep: true,
    },
    contractAddress() {
      this.getList()
    },
  },
  methods: {
    getList(page, per_page) {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (page == undefined) {
        page = this.options.page
      }
      if (per_page == undefined) {
        per_page = itemsPerPage
      }
      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }
      parameters = parameters + `&contractAddress=${this.contractAddress}`

      axiosInstance
        .get(`/system_wallets?${parameters}`)
        .then((result) => {
          if (result.status == 200) {
            console.log(result.data)
            // this.totalCount = result.data.totalCount
            // this.totalAmount = result.data.totalAmount
            // this.displist = result.data.hotWalletList.map((item) => {
            //   this.getBalance(item.address)
            //   return item
            // })
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    getBalance(address) {
      this.web3 &&
        this.web3.eth &&
        this.web3.eth.getBalance(address).then((balance) => {
          const item = this.displist.find((item) => item.address == address)
          if (item) {
            item.matic = this.convertValue(balance)
            this.$forceUpdate()
          }
        })
    },
    convertValue(value) {
      const result = BigInt(value) / BigInt('100000000000000')
      const val = Number(result) / 10000
      return val.toLocaleString()
    },
    check(item, checked) {
      axiosInstance
        .post('/wallet/enableSystemWallet', {
          address: item.address,
          enabled: checked,
        })
        .then(() => {})
    },
    editContract(item) {
      this.address = item.address
      this.tokenType = item.tokenType
      this.description = item.description
      this.editContractDialog = true
    },
    generateSystemWallet() {
      if (confirm('Would you like to generate an address?')) {
        axiosInstance
          .post('/system_wallets', {
            description: this.description,
            token_type: this.tokenType,
            contract_address: this.contractAddress,
            chain_id: this.chainID ? this.chainID : 56,
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            } else {
              alert('Failed to generate.')
            }
          })
          .catch(() => {
            alert('Failed to generate.')
          })
      }
      this.createWalletDialog = false
    },
    modifyContract() {
      axiosInstance
        .post('/wallet/modifySystemWallet', {
          address: this.address,
          tokenType: this.tokenType,
          description: this.description,
          contractAddress: this.contractAddress,
        })
        .then((r) => {
          if (r.status == 200) {
            this.displist.forEach((item) => {
              if (item.address === this.address) {
                item.description = this.description
                item.contractAddress = this.contractAddress
              }
            })
          } else {
            alert('Failed to modify.')
          }
        })
        .catch(() => {
          alert('Failed to modify.')
        })
      this.editContractDialog = false
    },
    createWallet() {
      this.createWalletDialog = true
      this.address = ''
      const found = this.contractList.find(
        (el) => el.address === this.contractAddress
      )

      if (found) {
        this.tokenType = found.tokenType
        this.chainId = found.chainID
      }
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'address,tokenType,description,updatedAt,createdAt,enabled\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post(
            '/wallet/getSystemWalletList',
            {
              contractAddress: this.contractAddress,
              offset: skip,
              limit: 1000,
              keyword: this.keyword,
              sort: this.sort,
            }
          )
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.hotWalletList) {
              processData += `${item.address},${item.tokenType},"${item.description}",${item.updatedAt},${item.createdAt},${item.enabled}\n`
            }
          } else {
            return
          }
          skip += data.hotWalletList.length

          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'system_wallet.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
  },
  async beforeMount() {
    this.web3 = new Web3(endpointURL(this.chainID))

    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped>
pre {
  width: 100%;
  margin: 1px;
  padding: 20px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: green;
}
.enabled-check div.v-messages.theme--light {
  display: none;
}
.enabled-check div.v-messages.theme--dark {
  display: none;
}
.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 12px;
}
</style>
