<template>
  <div>
    <v-text-field clearable v-model="keyword" label="Search" class="mr-4" />
    <v-data-table
      :headers="headers"
      :items="contractList"
      :items-per-page="itemsPerPage"
      :server-items-length="totalCount"
      :footer-props="{
        'items-per-page-options': [5, 10, 20, 50, 100],
      }"
      :options.sync="options"
      class="elevation-1 outlined permit-list"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.address">
            <td>
              <v-checkbox
                :input-value="isEnabled(item)"
                @change="check(item, $event)"
                color="icon"
              ></v-checkbox>
            </td>
            <td>{{ item.tokenSymbol }}</td>
            <td>{{ item.tokenType }}</td>
            <td>{{ item.address }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'ContractEdit',
  props: {
    address: String,
  },
  data() {
    return {
      headers: [
        { text: 'enabled', value: 'seq', sortable: false, width: '10%' },
        { text: 'symbol', value: 'tokenSymbol', sortable: false, width: '25%' },
        {
          text: 'tokenType',
          value: 'tokenType',
          sortable: false,
          width: '15%',
        },
        {
          text: 'address',
          value: 'address',
          sortable: false,
        },
      ],
      options: {},
      keyword: '',
      sort: '',
      contractList: [],
      myList: [],
      totalCount: 0,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return 5
    },
  },
  watch: {
    keyword() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)
    },
    address() {
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(1, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.getList(page, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(page, per_page) {
      const { sortBy, sortDesc, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (page == undefined) {
        page = this.options.page
      }
      if (per_page == undefined) {
        per_page = itemsPerPage
      }
      let parameters = `page=${page}&per_page=${per_page}`
      if (this?.keyword.length > 0) {
        parameters = parameters + `&keyword=${this.keyword}`
      }
      if (this?.sort.length > 0) {
        parameters = parameters + `&sort=${this.sort}`
      }

      axiosInstance
        .get(`/contract/mylist`)
        .then((r) => {
          if (r.status == 200) {
            this.myList = r.data.mylist
          }
        })
        .catch((err) => {
          console.error(err)
        })

      axiosInstance
        .get(`/contract/list?${parameters}`)
        .then((r) => {
          if (r.status == 200) {
            this.contractList = r.data.data
            this.totalCount = r.data.meta.total
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    check: function (item, checked) {
      if (checked) {
        axiosInstance
          .post('/contract/modify', {
            address: this.address,
            contract_address: item.address,
            action: 'insert',
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      } else {
        axiosInstance
          .post('/contract/modify', {
            address: this.address,
            contract_address: item.address,
            action: 'delete',
          })
          .then((r) => {
            if (r.status == 200) {
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    isEnabled(item) {
      return this.myList.includes(item.address)
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>
