<template>
  <div>
    <v-row class="search_row">
      <v-col cols="12" lg="4">
        <v-text-field clearable v-model="query" label="Search" />
      </v-col>
      <v-col cols="12" lg="4">
        <div class="btn-row">
          <v-btn :disabled="disableWithdraw" @click="startWithdraw">
            Start
          </v-btn>
          <v-btn :disabled="!disableWithdraw" @click="stopWithdraw">Stop</v-btn>
          <v-btn @click="setEnabledAll">Select All</v-btn>
          <v-btn :disabled="progressValue != 100" @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <progress-bar
            v-show="progressValue != 100"
            :options="progressOptions"
            :value="progressValue"
          />
        </div>
      </v-col>
      <v-col cols="12" lg="4">
        <h3 style="text-align: right">
          Selected: {{ enabledCount.toLocaleString() }}&nbsp;/&nbsp;
          {{ Number(enabledAmount).toLocaleString() }}
        </h3>
        <h3 style="text-align: right">
          Total: {{ Number(totalCount).toLocaleString() }}&nbsp;/&nbsp;{{
            Number(totalAmount).toLocaleString()
          }}
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="displist"
        :items-per-page="itemsPerPage"
        :page="page"
        :server-items-length="totalCount"
        :options.sync="options"
        :footer-props="{
          'items-per-page-options': [5, 10, 20, 50, 100],
        }"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      >
        <template v-slot:[`item.address`]="{ item }">
          <div class="hover_area">
            <p
              class="hover_address"
              v-bind:style="isDuplicated(item.address) ? 'color:#f9a825' : ''"
            >
              {{ item.name.length > 0 ? item.name : item.address }}
            </p>
            <div class="hover_inner">
              <v-btn class="edit" @click="openModal(item.address)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="addrCopy(item.address)"
                class="copy"
                ref="${item.address}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`address/${item.address}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="addBlacklistReason(item.address)" class="link">
                <v-icon>mdi-block-helper</v-icon>
              </v-btn>
              <v-btn @click="setAddress(item.address)" class="link">
                <v-icon>mdi-magnify-expand</v-icon>
              </v-btn>
              <v-btn @click="query = item.address" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.amountf`]="{ item }">
          <div class="hover_area">
            <p
              v-bind:style="
                item.amountf > 3000
                  ? item.amountf > 5000
                    ? item.amountf > 10000
                      ? 'color:#d32f2f'
                      : 'color:#f9a825'
                    : 'color:#4caf50'
                  : ''
              "
            >
              {{ item.amountf.toLocaleString() }}
            </p>
          </div>
        </template>
        <template v-slot:[`item.transactionHash`]="{ item }">
          <div v-if="item.transactionHash.length > 0" class="hover_area">
            <div class="hover_address">{{ item.transactionHash }}</div>
            <div
              v-if="item.transactionHash && item.transactionHash.length > 0"
              class="hover_inner"
            >
              <v-btn
                @click="addrCopy(item.transactionHash)"
                class="copy"
                ref="${item.transactionHash}"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                @click="networkExplorer(`tx/${item.transactionHash}`, 'erc20')"
                class="link"
              >
                <v-icon>mdi-link</v-icon>
              </v-btn>
              <v-btn @click="query = item.transactionHash" class="copy">
                <v-icon>mdi-filter-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.systemWallet`]="{ item }">
          <v-select
            class="system-wallet"
            :items="selectWalletList"
            v-model="item.systemWallet"
            @change="handleChange(item)"
          ></v-select>
        </template>
        <template v-slot:[`item.enabled`]="{ item }">
          <v-checkbox
            class="enabled-check"
            :input-value="item.enabled"
            color="icon"
            @change="check(item, $event)"
            :disabled="item.transactionHash != ''"
          ></v-checkbox>
        </template>
      </v-data-table>
    </v-row>
    <v-row v-show="address.length > 0" class="mt-12 ml-1 mr-1">
      <!-- 같은 주소를 사용한 사용자 목록 -->
      <ERC20WithdrawAddress
        :address="address"
        :withdrawStatus="withdrawStatus"
      ></ERC20WithdrawAddress>
    </v-row>
    <v-row class="mt-10">
      <pre>
Request Date - 05:00 UTC (14:00 UTC)
      </pre>
    </v-row>
    <confirm-dlg ref="confirm"></confirm-dlg>
    <v-dialog v-model="refusalDialog" persistent max-width="500px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h5">Rejection</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row cols="12">
              <v-col cols="12">
                <v-text-field
                  label="Reason"
                  v-model="reasonRefusal"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="refusalDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn color="primary" class="font-weight-bold" @click="refusal">
            Reject
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReason" persistent max-width="1000px">
      <v-card>
        <v-card-title class="primary">
          <span class="text-h7">blacklist 등록 사유</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="" v-model="reason" required></v-text-field>
        </v-card-text>
        <v-card-actions class="py-4 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            w100
            class="font-weight-bold"
            @click="closeDialog"
          >
            취소
          </v-btn>
          <v-btn
            color="primary"
            class="font-weight-bold"
            @click="registerBlacklist"
          >
            등록
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="375px">
      <v-card class="modal deal">
        <div class="modal_title">{{ selected.address }}</div>
        <div class="pa-3">
          <div class="box">
            <div class="label">Type</div>
            <div class="modal_input">
              <v-select
                hide-details
                :items="types"
                v-model="selected.type"
              ></v-select>
            </div>
          </div>
          <div class="box">
            <div class="label">Name</div>
            <div class="modal_input">
              <v-text-field
                v-model="selected.name"
                hide-details
                @keydown="modalKeydown"
                placeholder="Name"
              ></v-text-field>
            </div>
          </div>
          <div class="mt-6">
            <v-btn
              depressed
              block
              color="bk"
              dark
              height="40px"
              @click="updateInfo"
              :loading="selected.load"
            >
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axiosInstance from '@/utils/axiosInstance'
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import ERC20WithdrawAddress from '@/components/ERC20WithdrawAddress/ERC20WithdrawAddress.vue'
import { scanExplorer } from '@/utils/network'

export default {
  name: 'ERC20WithdrawView',
  components: {
    ConfirmDlg,
    ERC20WithdrawAddress,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Issuer', value: 'issuer', width: '7%' },
        { text: 'Address', value: 'address', width: '17%' },
        { text: 'Amount', value: 'amountf', width: '8%' },
        { text: 'Transaction Hash', value: 'transactionHash' },
        {
          text: 'Request Date',
          value: 'registerDatetime',
          width: '12%',
        },
        {
          text: 'System Wallet',
          value: 'systemWallet',
          sortable: false,
          width: '150px',
        },
        { text: '', value: 'enabled', sortable: false, width: '34px' },
      ],
      types: [
        { text: 'Default', value: '0' },
        { text: 'Company', value: '1' },
        { text: 'Exchange', value: '2' },
        { text: 'Marketing', value: '3' },
        { text: 'Sale', value: '4' },
        { text: 'Staking', value: '5' },
        { text: 'Team', value: '6' },
        { text: 'CM liquidity', value: '7' },
        { text: 'Advisor', value: '8' },
        { text: 'Unlock', value: '9' },
        { text: 'Portal', value: '10' },
        { text: 'System', value: '11' },
      ],
      totalCount: 0,
      totalAmount: 0,
      enabledCount: 0,
      enabledAmount: 0,
      listInfo: [],
      displist: [],
      query: '',
      sort: '',
      address: '',
      status: '0',
      disableWithdraw: false,
      interval: null,
      enabled: false,
      transactionId: null,
      refusalDialog: false,
      reasonRefusal: '',
      reason: '',
      dialogReason: false,
      fromDate: '2022-01-01',
      toDate: new Date().toISOString().substr(0, 10),
      toDay: new Date().toISOString().substr(0, 10),
      withdrawStatus: '0',
      selectWalletList: [],
      systemWalletList: [],
      selected: {},
      dialog: false,
      progressOptions: {
        text: {
          color: '#FFFFFF',
          shadowEnable: true,
          shadowColor: '#000000',
          fontSize: 14,
          fontFamily: 'Helvetica',
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: '#2dbd2d',
          backgroundColor: '#333333',
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: 'line',
        },
      },
      progressValue: 100,
    }
  },
  computed: {
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    chainID() {
      return this.$store.getters['auth/chainID']
    },
  },
  watch: {
    query() {
      this.address = ''
      this.$store.dispatch('pagination/setPagination', {
        page: 1,
        firstItem: 0,
      })
      this.getList(0, this.itemsPerPage)
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
    erc20Address() {
      this.getList()
    },
  },
  methods: {
    modalKeydown(event) {
      if (event.keyCode === 13) {
        this.updateInfo()
      }
    },
    updateInfo() {
      let { _id, name, type, address } = this.selected

      this.selected.load = true

      axiosInstance
        .post('/tokentracker/updateAddressInfo', {
          contractAddress: this.erc20Address,
          _id,
          name,
          type,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            this.dialog = false
            // this.$toast.success('success')

            this.list.forEach((item) => {
              item.name = item.address === address ? name : item.name
            })

            this.$forceUpdate()
          }
        })
        .catch(console.error)
        .finally(() => {
          this.selected.load = false
        })
    },
    openModal(address) {
      axiosInstance
        .post('/tokentracker/getAddressInfo', {
          contractAddress: this.erc20Address,
          address,
        })
        .then((res) => {
          this.selected.type = res.data.type || '0'
          this.selected.name = res.data.name || ''
          this.selected._id = res.data._id
          this.selected.address = address
        })
        .catch(console.error)
        .finally(() => {
          this.dialog = true
        })
    },
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/withdraw/list', {
          erc20Address: this.erc20Address,
          offset,
          limit,
          query: this.query,
          sort: this.sort,
          status: '0',
        })
        .then((result) => {
          if (result.status == 200) {
            this.totalCount = result.data.totalCount
            this.totalAmount = result.data.totalAmount
            this.enabledCount = result.data.enabledCount
            this.enabledAmount = result.data.enabledAmount
            this.listInfo = result.data.listInfo

            const list = result.data.list
            this.displist = list.map((item) => {
              item.createdAt = item.createdAt.substring(0, 19)
              item.updatedAt = item.updatedAt.substring(0, 19)
              item.registerDatetime = item.registerDatetime.substring(0, 16)
              const amountf = parseFloat(item.amountf)
              if (amountf > 0)
                item.amountf = Math.round(amountf * 10000) / 10000

              const r = this.systemWalletList.find(
                (it) => it.address == item.systemWallet
              )
              if (r != undefined && r.description.length > 0) {
                item.systemWallet = r.description
              }
              return item
            })
          }
        })
    },
    isDuplicated(address) {
      const result = this.listInfo.find((item) => item.address == address)
      return result ? true : false
    },
    handleChange(item) {
      let systemWallet = ''

      if (item.systemWallet != '') {
        const result = this.systemWalletList.find(
          (it) =>
            it.description == item.systemWallet ||
            it.address == item.systemWallet
        )
        if (result != undefined) {
          systemWallet = result.address
        }
      }

      axiosInstance
        .post('/withdraw/setSystemWallet', {
          erc20Address: this.erc20Address,
          id: item.id,
          systemWallet,
        })
        .then(() => {
          this.getList()
        })
    },
    check(item, checked) {
      axiosInstance
        .post('/withdraw/setEnabled', {
          erc20Address: this.erc20Address,
          id: item.id,
          enabled: checked,
        })
        .then(() => {
          this.getList()
        })
    },
    setEnabledAll() {
      this.enabled = !this.enabled
      axiosInstance
        .post('/withdraw/setEnabledAll', {
          erc20Address: this.erc20Address,
          enabled: this.enabled,
        })
        .then(() => {
          this.getList()
        })
    },
    async startWithdraw() {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to proceed with the withdrawal?'
      )
      if (result) {
        axiosInstance
          .post('/withdraw/setProcessStatus', {
            erc20Address: this.erc20Address,
            withdraw: 'start',
          })
          .then(() => {})
      }
    },
    stopWithdraw() {
      axiosInstance
        .post('/withdraw/setProcessStatus', {
          erc20Address: this.erc20Address,
          withdraw: 'stop',
        })
        .then(() => {})
    },
    async download() {
      let totalCount = 0
      let skip = 0
      let processData =
        '\ufeff' + 'address,amountf,transactionHash,registerDatetime,enabled\n'

      try {
        this.progressValue = 0

        do {
          const { data, status } = await axiosInstance.post('/withdraw/list', {
            erc20Address: this.erc20Address,
            offset: skip,
            limit: 1000,
            query: this.query,
            status: '0',
          })
          if (status == 200) {
            totalCount = data.totalCount

            for (let item of data.list) {
              const amountf = parseFloat(item.amountf)
              if (amountf > 0)
                item.amountf = Math.round(amountf * 10000) / 10000

              processData += `${item.address},${item.amountf},${item.transactionHash},${item.registerDatetime},${item.enabled}\n`
            }
          } else {
            return
          }
          skip += data.list.length
          this.progressValue = Math.round((100 * skip) / totalCount)
        } while (skip < totalCount)

        this.progressValue = 100

        const blob = new Blob([processData], { type: 'application/plain' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'withdraw_ready.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (err) {
        console.error(err)
      }
    },
    networkExplorer(path, tokenType) {
      scanExplorer(this.chainID, path, tokenType)
    },
    addrCopy(addr) {
      const url = document.createElement('textarea')
      url.value = addr
      url.setAttribute('readonly', '')
      url.style.position = 'absolute'
      url.style.left = '-9999px'
      document.body.appendChild(url)
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false
      url.select()
      document.execCommand('copy')
      document.body.removeChild(url)
      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }
    },
    setQuery(query) {
      this.address = ''
      this.query = query
    },
    setAddress(address) {
      this.address = address
    },
    refusalItem(itemId) {
      this.transactionId = itemId
      this.reasonRefusal = ''
      this.refusalDialog = true
    },
    addBlacklistReason(address) {
      this.address = address
      this.reason = ''
      this.dialogReason = true
    },
    closeDialog() {
      this.dialogReason = false
    },
    refusal() {
      axiosInstance
        .post(`/withdraw/refusal`, {
          erc20Address: this.erc20Address,
          transactionId: this.transactionId,
          reason: this.reasonRefusal,
        })
        .then((result) => {
          if (result.status == 200) {
            this.getList()
          }
        })
        .catch(() => {
          alert('승인거절 사유를 입력하세요.')
        })
      this.refusalDialog = false
    },
    registerBlacklist() {
      this.dialogReason = false
      axiosInstance
        .post('/withdraw/addBlacklist', {
          erc20Address: this.erc20Address,
          address: this.address,
          memberId: this.memberId,
          reason: this.reason,
        })
        .then((r) => {
          if (r.status == 200) {
            this.getList()
          } else {
            alert('Failed to add to the blacklist.')
          }
        })
        .catch(() => {
          alert('Failed to add to the blacklist.')
        })
    },
  },
  async beforeMount() {
    try {
      const result = await axiosInstance.post('/wallet/getSystemWalletList', {
        contractAddress: this.erc20Address,
        offset: 0,
        sort: 'description_true',
      })
      if (result.status == 200) {
        this.systemWalletList = result.data.hotWalletList.filter(
          (item) => item.tokenType == 'erc20' && item.description.length > 0
        )
        this.selectWalletList = [
          '',
          ...this.systemWalletList.map((item) => {
            if (item.description.length > 0) {
              return item.description
            } else {
              return item.address
            }
          }),
        ]
        this.getList()
      }
    } catch (err) {
      console.error(err)
    }
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
    this.interval = setInterval(() => {
      axiosInstance
        .get(`/withdraw/getProcessStatus?erc20Address=${this.erc20Address}`)
        .then(({ data, status }) => {
          if (status == 200) {
            if (data.withdraw == 'start') {
              this.disableWithdraw = true
            } else if (data.withdraw == 'stop') {
              this.disableWithdraw = false
            }
          }
        })
      this.getList()
    }, 3000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.interval)
    next()
  },
}
</script>

<style>
.system-wallet .v-input__slot:before {
  display: none;
}
.system-wallet .v-input__slot:after {
  display: none;
}
.system-wallet .v-text-field__details {
  display: none;
}
.system-wallet .v-input__slot {
  margin-bottom: 0px;
}
.system-wallet.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.enabled-check div.v-messages.theme--light {
  display: none;
}
.enabled-check div.v-messages.theme--dark {
  display: none;
}
.enabled-check.v-input--selection-controls {
  padding-top: 0px;
  margin-top: 10px;
}
</style>
