<template>
  <div>
    <v-progress-linear
      fixed
      v-if="loading"
      color="white"
      indeterminate
      class="elevation-25"
      style="z-index: 6"
    />

    <v-app-bar app clipped-left color="primary" class="bright--text">
      <v-btn icon @click="$emit('toggle-drawer')">
        <v-icon class="bright--text">mdi-menu</v-icon>
      </v-btn>
      <div class="d-flex align-center mr-15">
        <div style="justify-content: center">
          <img
            src="/metaq-bi.png"
            style="
              max-width: 165px;
              min-width: 165px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            "
            onclick="window.open('https://www.metaplanet.tech', '_blank');"
          />
        </div>
      </div>

      <ButtonERC20></ButtonERC20>
      <ButtonERC721></ButtonERC721>
      <ButtonERC1155></ButtonERC1155>

      <v-spacer></v-spacer>

      <div class="clock hidden-sm-only">
        <div class="utc">{{ currentTime }} UTC</div>
        <div class="local">{{ localTime }}</div>
      </div>
      <div class="address hidden-sm-only">{{ address }}</div>
      <v-btn class="bright--text" @click="toggleTheme" icon depressed>
        <v-icon>
          {{
            !$vuetify.theme.dark
              ? 'mdi-weather-sunny'
              : 'mdi-moon-waxing-crescent'
          }}
        </v-icon>
      </v-btn>
      <slot name="walletConnect"></slot>
      <slot name="loginButton"></slot>
    </v-app-bar>
  </div>
</template>

<script>
import ButtonERC20 from '@/components/Contract/ButtonERC20.vue'
import ButtonERC721 from '@/components/Contract/ButtonERC721.vue'
import ButtonERC1155 from '@/components/Contract/ButtonERC1155.vue'

export default {
  name: 'AppBar',
  components: { ButtonERC20, ButtonERC721, ButtonERC1155 },

  data() {
    return {
      currentTime: new Date().toISOString().replace('T', ' ').slice(0, -5),
      localTime: new Date().toLocaleString(),
    }
  },
  computed: {
    loading() {
      return this.$store.getters.loading
    },
    address() {
      return this.$store.getters['auth/address']
    },
    erc20Address() {
      return this.$store.getters['auth/erc20Address']
    },
    erc721Address() {
      return this.$store.getters['auth/erc721Address']
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem(
        'vuetifyTheme',
        JSON.stringify({ dark: this.$vuetify.theme.dark })
      )
    },
  },
  mounted() {
    setInterval(() => {
      this.currentTime = new Date().toISOString().replace('T', ' ').slice(0, -5) // 1초마다 현재 시간 업데이트
      this.localTime = new Date().toLocaleString()
    }, 1000)
  },
}
</script>
<style scoped>
.address {
  user-select: none;
  font-size: 15pt;
  width: 150px; /* 텍스트가 표시될 영역의 너비 지정 */
  white-space: nowrap; /* 텍스트가 줄 바꿈되지 않도록 설정 */
  overflow: hidden; /* 너비를 벗어나는 텍스트 숨기기 */
  text-overflow: ellipsis; /* 너비를 벗어나는 부분을 ...으로 대체 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}
.clock {
  width: 260px;
  margin: 30px;
  user-select: none;
  text-align: right;
  color: #00ff7f;
  white-space: nowrap;
}
.clock .utc {
  font-weight: bold;
  font-size: 15pt;
}
.clock .local {
  font-size: 13pt;
}
.contract {
  width: 400px;
  margin: 30px;
  user-select: none;
  text-align: right;
  color: grey;
  white-space: nowrap;
}
</style>
