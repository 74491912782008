<template>
  <div>
    <v-row>
      <v-col cols="12" lg="6">
        <h3>ORDER HISTORY LP1 (seri4617@gmail.com)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="orderHeaders"
        :items="order_list_lp1"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      ></v-data-table>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <h3>ORDER HISTORY LP2 (dkssud4617)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="orderHeaders"
        :items="order_list_lp2"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      ></v-data-table>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <h3>ORDER HISTORY LP3 (allcon.qr@gmail.com)</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="orderHeaders"
        :items="order_list_lp3"
        item-key="id"
        class="elevation-1 mt-4 outlined"
      ></v-data-table>
    </v-row>
  </div>
</template>
<script>
import axiosInstance from '@/utils/axiosInstance'

export default {
  name: 'LBankOrderHistoryView',
  data() {
    return {
      orderHeaders: [
        { text: 'time', value: 'time', sortable: false },
        {
          text: 'limit_price',
          value: 'limit_price',
          sortable: false,
        },
        { text: 'status', value: 'status', sortable: false },
        { text: 'side', value: 'side', sortable: false },
        { text: 'quantity', value: 'quantity', sortable: false },
        {
          text: 'cancelled_quantity',
          value: 'cancelled_quantity',
          sortable: false,
        },
        {
          text: 'filled_quantity',
          value: 'filled_quantity',
          sortable: false,
        },
      ],
      order_list_lp1: [],
      order_list_lp2: [],
      order_list_lp3: [],
    }
  },
  methods: {
    getList() {
      axiosInstance
        .post('/metaplanet_token/lbank_order_history', { limit: 300 })
        .then((res) => {
          this.order_list_lp1 = res.data.order_list_lp1.sort((a, b) =>
            b.time.localeCompare(a.time)
          )
          this.order_list_lp2 = res.data.order_list_lp2.sort((a, b) =>
            b.time.localeCompare(a.time)
          )
          this.order_list_lp3 = res.data.order_list_lp3.sort((a, b) =>
            b.time.localeCompare(a.time)
          )
        })
        .catch(console.error)
    },
  },
  beforeMount() {
    this.getList()
  },
}
</script>
<style scoped></style>
