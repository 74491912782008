<template>
  <div>
    <div v-show="stage == 1">
      <v-row>
        <v-col cols="12" lg="2">
          <div class="btn-row">
            <v-btn @click="doAdd()">
              <v-icon dark>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="itemsPerPage"
          :page="page"
          :server-items-length="totalCount"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 50, 100],
          }"
          class="elevation-1 mt-4 outlined"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.seq">
                <td>{{ item.seq }}</td>
                <td>{{ item.gamename }}</td>
                <td>{{ item.category }}</td>
                <td class="left">{{ item.subject }}</td>
                <td>{{ item.language }}</td>
                <td>{{ item.state }}</td>
                <td>{{ item.dateUpdated }}</td>
                <td>{{ item.dateCreated }}</td>
                <td>
                  <button v-on:click="doContentGo(item)">
                    <v-icon>mdi-link</v-icon>
                  </button>
                </td>
                <td>
                  <button v-on:click="editItem(item)">
                    <v-icon>mdi-note-edit-outline</v-icon>
                  </button>
                </td>
                <td>
                  <button v-on:click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
    </div>
    <div :style="{ width: stage == 1 ? '0%' : '100%' }">
      <v-row>
        <v-col cols="12" lg="2">
          <v-btn
            v-if="stage != 1"
            class="mr-2"
            color="secondary"
            @click="stage = 1"
          >
            <v-icon>mdi-arrow-left-thin</v-icon>
          </v-btn>
          <v-btn
            v-if="stage == 2"
            class="mr-2"
            color="primary"
            @click="addItem"
          >
            Save
          </v-btn>
          <v-btn
            v-if="stage == 3"
            class="mr-2"
            color="primary"
            @click="modifyItem"
          >
            Modify
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="stage != 1">
        <v-col cols="12" lg="1">
          <v-radio-group v-model="gamename" row>
            <v-radio label="clashrow" value="clashrow" color="icon"></v-radio>
            <v-radio label="coingrid" value="coingrid" color="icon"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" lg="2">
          <v-radio-group v-model="state" row>
            <v-radio label="New" value="0" color="icon"></v-radio>
            <v-radio label="Old" value="1" color="icon"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" lg="7">
          <v-radio-group v-model="category" row>
            <v-radio label="Public" value="Public" color="icon"></v-radio>
            <v-radio label="Update" value="Update" color="icon"></v-radio>
            <v-radio
              label="Maintenance"
              value="Maintenance"
              color="icon"
            ></v-radio>
            <v-radio label="Event" value="Event" color="icon"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" lg="2">
          <v-radio-group v-model="language" row>
            <v-radio label="English" value="en" color="icon"></v-radio>
            <v-radio label="Korean" value="ko" color="icon"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <v-text-field
            label="Subject*"
            v-model="subject"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <SmartEditor
            :editorProp="oEditors"
            :isModify="modify"
            :contentsProp="contentEdit"
            elPlaceHolder="elPlaceHolder"
          ></SmartEditor>
        </v-col>
      </v-row>
    </div>
    <confirm-dlg ref="confirm"></confirm-dlg>
  </div>
</template>

<script>
import ConfirmDlg from '@/components/Modals/ConfirmDlg.vue'
import SmartEditor from '@/components/SmartEditor/SmartEditor.vue'
import axiosInstance from '@/utils/axiosInstance'

const SKYPLAY_HOME = 'https://notice.skyplay.io'
export default {
  name: 'GameNoticeView',
  components: {
    ConfirmDlg,
    SmartEditor,
  },
  data() {
    return {
      options: {},
      headers: [
        { text: 'Seq', value: 'seq', width: '34px' },
        { text: 'Gamename', value: 'gamename', width: '10%' },
        { text: 'Category', value: 'category', width: '10%' },
        { text: 'Subject', value: 'subject' },
        { text: 'Language', value: 'language', width: '5%' },
        { text: 'State', value: 'state', width: '5%' },
        { text: 'Modified Date', value: 'dateUpdated', width: '13%' },
        { text: 'Created Date', value: 'dateCreated', width: '13%' },
        { text: '', value: 'link', sortable: false, width: '34px' },
        { text: '', value: 'editItem', width: '34px' },
        { text: '', value: 'deleteItem', width: '34px' },
      ],
      totalCount: 0,
      list: [],
      dialog: false,
      dialog2: false,
      gamename: '',
      category: '',
      subject: '',
      language: '',
      state: '',
      seq: 0,
      modify: false,
      oEditors: [],
      contentEdit: '',
      seqList: [],
      stage: 1,
    }
  },
  computed: {
    strSeqList() {
      return String(
        this.list
          .filter((item) => item.language.match('ko'))
          .map((item) => item.seq)
      )
    },
    page() {
      return this.$store.getters['pagination/page']
    },
    firstItem() {
      return this.$store.getters['pagination/firstItem']
    },
    itemsPerPage() {
      return this.$store.getters['pagination/itemsPerPage']
    },
  },
  watch: {
    contentEdit() {
      return this.contentEdit
    },
    options: {
      handler() {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        if (sortBy && sortBy[0]) {
          this.sort = `${sortBy[0]}_${sortDesc[0]}`
        }
        this.$store.dispatch('pagination/setPagination', {
          page: page,
          firstItem: (page - 1) * itemsPerPage,
          itemsPerPage,
        })

        this.getList((page - 1) * itemsPerPage, itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    getList(offset, limit) {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options
      if (sortBy && sortBy[0]) {
        this.sort = `${sortBy[0]}_${sortDesc[0]}`
      }
      if (offset == undefined) {
        offset = (page - 1) * itemsPerPage
      }
      if (limit == undefined) {
        limit = itemsPerPage
      }
      axiosInstance
        .post('/gamenotice/list', {
          offset,
          limit,
          query: this.query,
          sort: this.sort,
        })
        .then((r) => {
          if (r.status == 200) {
            this.totalCount = r.data.totalCount
            this.list = r.data.list
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    contentInit() {
      this.gamename = 'clashrow'
      this.category = 'Public'
      this.subject = ''
      this.language = 'en'
      this.state = '0'
      this.modify = false
      this.contentEdit = ''
    },
    doAdd() {
      this.contentInit()

      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])
      this.stage = 2
    },
    doContentGo(item) {
      window.open(`${SKYPLAY_HOME}/listView.html?num=${item.seq}`, '_blank')
    },
    async addItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.subject == '') {
        alert('Please enter a title.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to add?'
        )
        if (result) {
          axiosInstance
            .post('/gamenotice/add', {
              gamename: this.gamename,
              category: this.category,
              subject: this.subject,
              language: this.language,
              state: this.state,
              contents: content,
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
    editItem(item) {
      document.getElementById('elPlaceHolder').value = ''
      this.oEditors?.getById['elPlaceHolder'].exec('SET_IR', [''])
      this.oEditors?.getById['elPlaceHolder'].exec('PASTE_HTML', [''])

      this.seq = item.seq
      this.gamename = item.gamename
      this.category = item.category
      this.subject = item.subject
      this.language = item.language
      this.state = item.state

      axiosInstance
        .get(`/gamenotice/contents/${item.seq}`)
        .then((r) => {
          this.contentEdit = r.data.contents
          this.modify = true
          this.stage = 3
        })
        .catch((err) => console.error(err))
    },
    async deleteItem(item) {
      const result = await this.$refs.confirm.open(
        'Confirm',
        'Would you like to delete?'
      )
      if (result) {
        axiosInstance
          .delete(`/gamenotice/${item.seq}`)
          .then((r) => {
            if (r.status == 200) {
              this.contentInit()
              this.getList()
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    async modifyItem() {
      this.oEditors.getById['elPlaceHolder'].exec('UPDATE_CONTENTS_FIELD', [])
      let content = document.getElementById('elPlaceHolder').value

      if (this.subject == '') {
        alert('Please enter a title.')
      } else if (content == '') {
        alert('Please enter the content.')
        this.oEditors.getById['elPlaceHolder'].exec('FOCUS')
      } else {
        const result = await this.$refs.confirm.open(
          'Confirm',
          'Would you like to modify?'
        )
        if (result) {
          axiosInstance
            .post('/gamenotice/modify', {
              seq: this.seq,
              gamename: this.gamename,
              category: this.category,
              subject: this.subject,
              contents: content,
              language: this.language,
              state: this.state,
            })
            .then((r) => {
              if (r.status == 200) {
                this.contentInit()
                this.getList()
                this.stage = 1
              }
            })
            .catch((err) => {
              console.error(err)
            })
        }
      }
    },
  },
  beforeMount() {
    this.$store.dispatch('pagination/setPagination', {
      page: 1,
      firstItem: 0,
    })
  },
}
</script>

<style scoped></style>
